export const usageTrackingHeader = [
  {
    headerName: 'User Name',
    field: 'fullname',
  },
  {
    headerName: 'User ID',
    field: 'userid',
  },
  {
    headerName: 'Entry Date',
    field: 'insert_date',
  },
  {
    headerName: 'From URL',
    field: 'from_url',
  },
  {
    headerName: 'Time Spent',
    field: 'timespent',
  },
];
