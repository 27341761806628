import React, { ReactNode } from 'react';
import { useState, useEffect, useMemo } from 'react';
import 'react-date-range/dist/styles.css'; // main css file
import 'react-date-range/dist/theme/default.css'; // theme css file
import '../index.scss';
import { unique_notification } from './usageTrackingTable';
import { apiResponse } from '../../../utility/commonMethods';
import { toast } from 'react-toastify';
import { Loader } from '@gitlab-rtsensing/component-library';
import { MetricProps } from '../usage-metric-interface';
import AgGridTable from '../../../utility/agGridTable';
import { FileDownloadIcon } from '@opsdti-global-component-library/amgen-design-system';
import { ExpandIcon, CloseIcon } from '@opsdti-global-component-library/amgen-design-system';

const NotificationsCount = (props: MetricProps) => {
  const count_columns = useMemo(() => unique_notification, []);
  const [isLoading, setLoading] = useState(true);
  const [tableMsg, setTableMsg] = useState('No Data Available');
  const [notificationCountData, setNotificationCountData] = useState([]);
  const [notificationCountDataOriginal, setNotificationCountDataOriginal] = useState([]);
  const [showExpandOrClose, setShowExpandOrClose] = useState(false);
  const [triggerDownload, setTriggerDownload] = useState(false);

  useEffect(() => {
    if (props.clearData) {
      setNotificationCountData([]);
      getnotificationCountData();
    }
  }, [props.clearData]);

  const getnotificationCountData = async () => {
    setLoading(true);
    const payload = {
      metric_id: '17',
      date_type: props.date.type,
      to_date: props.date.toDate,
      fr_date: props.date.fromDate,
    };
    try {
      const res = await apiResponse('post', 'get-usage-metric-data', [], payload);
      if (res?.data?.status === 'SUCCESS') {
        if (res?.data?.data?.user_metric) {
          if (res.data.data.user_metric.length === 0) {
            setTableMsg('No Data Available');
            // toast.error('Notifications Count: Data not available!!');
          } else {
            const countData = res.data.data.user_metric;
            setNotificationCountDataOriginal(countData);
            setNotificationCountData(countData);
          }
        }
      } else {
        console.log(res);
        toast.error('Notifications: Failed to load!!');
      }
    } catch (error) {
      console.log(error);
      toast.error('Notifications: Something Went Wrong!!');
    }
    setLoading(false);
  };

  // useEffect(() => {
  //   if (!showExpandOrClose) {
  //     document.getElementById('notifications')?.classList.add('container-width-100');
  //   } else {
  //     document.getElementById('notifications')?.classList.remove('container-width-100');
  //   }
  // }, [showExpandOrClose]);

  return (
    <div className="kpi-metric">
      <div className="metric-header">
        <div>
          <label className="form-label">{props.date.displayValue}</label>
        </div>
        <div className="filters ">
        <FileDownloadIcon height={28} width={28} onClick={() => {
          setTriggerDownload(true);
        }} />
          {!showExpandOrClose ? (
            <span
              onClick={() => {
                setShowExpandOrClose(true);
              }}
              className="expand-collapse-icon"
            >
              <ExpandIcon className="expand-collapse-icon" /> {/*This classname somehow not rendering*/}
            </span>
          ) : (
            <span
              onClick={() => {
                setShowExpandOrClose(false);
              }}
            >
              <CloseIcon className="expand-collapse-icon" />
            </span>
          )}
        </div>
      </div>
      {isLoading ? (
        <div className="adm-loader-container">
          <Loader />
        </div>
      ) : (
        <div className="table-container usage-metric-container">
          {notificationCountData.length === 0 ? (
            <p className="table-msg">{tableMsg}</p>
          ) : (
            <AgGridTable 
            rowData={notificationCountData} 
            columnDefs={count_columns} 
            matamoDate={props.date.matamoDate} 
            matamoType={props.date.matamoType}
            triggerdownload={triggerDownload}
            setTriggerDownload={setTriggerDownload}
            onExpandableClosed={() => setShowExpandOrClose(false)}
            expandable={showExpandOrClose}
            tableName="Notifications Count"
            />
          )}
        </div>
      )}
    </div>
  );
};

export default NotificationsCount;
