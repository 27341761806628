import { Tabs } from "@opsdti-global-component-library/amgen-design-system";
import React, { useContext } from 'react';
import ViewVPMapping from "./viewVPMapping";
import EditPeopleMapping from "./editVPMapping";
import { AppContext } from '../../contexts/app-context';
import { adminConstants } from '../../utility/constants';

const PeopleMapping = () => {
  const { constInfo } = useContext(AppContext);
  const isViewMode = constInfo.get(adminConstants.ADMIN_VP_MAPPING_VIEW_MODE) === 'Y'; //to check if the user can view or edit the data

  return (
    <>
      { !isViewMode ? 
        <Tabs
          items={[
            {
              label: 'View VP Mapping',
              children: <ViewVPMapping />,
              key: 'view-vp-mapping'
            },
            {
              label: 'Edit VP Mapping',
              children: <EditPeopleMapping />,
              key: "edit-vp-mapping"
            }
          ]}
        /> :
        <ViewVPMapping />
      }
    </>
  );
};

export default PeopleMapping;