import { OpsTypography } from '@gitlab-rtsensing/component-library';
import React from 'react';
import './index.scss';

interface ErrorDisplayProps {
  message: string;
}

const ErrorDisplay: React.FC<ErrorDisplayProps> = ({ message }) => {
  return <OpsTypography variant={'p1'} variantWeight={700} elementTag={'div'} children={message} className="error-display" />;
};

export default ErrorDisplay;
