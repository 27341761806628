import React, { ReactNode } from 'react';
import { useState, useEffect, useMemo } from 'react';
import './index.scss';
import { apiResponse, rowSpanning } from '../../utility/commonMethods';
import { toast } from 'react-toastify';
import { Loader } from '@gitlab-rtsensing/component-library';
import { Button } from '@opsdti-global-component-library/amgen-design-system';
import { add_user_logs_table } from './accessLogTables';
import { RowSpanType } from '../usage-metric-charts/usage-metric-interface';
import { MultiSelect } from 'react-multi-select-component';
import { DateRangePicker } from 'react-date-range';
import { addDays, format, differenceInDays, endOfToday } from 'date-fns';

type DropDownValType = {
  id: number | string;
  value: string;
  label: string;
};

const AddUserLogs = () => {
  const pageDropDown = ['Home', 'Pipeline', 'Supply', 'External', 'Finance', 'Brand', 'People', 'Prioritized Agenda', 'Admin'];
  const dateFormat = 'yyyy-MM-dd';
  const [dateVisibility, setdateVisibility] = useState(false);
  const addUserLogsColumns = useMemo(() => add_user_logs_table, []);
  const [addUserLogsData, setAddUserLogsData] = useState(Array<RowSpanType>);
  const [isLoading, setLoading] = useState(true);
  const [userID, setuserID] = useState('');
  const minDate = '2022-12-15'; //This is as per discussion on 20Apr23, for the last date range
  const [allRange, setAllRange] = useState(false);
  const tableMsg = 'No Data Available';
  const [pageName, setPageName] = useState<DropDownValType[]>(
    pageDropDown.map((item: any) => {
      return {
        id: item,
        label: item,
        value: item,
      };
    }),
  );
  const fromDate = format(addDays(new Date(), -30), dateFormat);
  const toDate = format(endOfToday(), dateFormat);
  const [dateValue, setDateValue] = useState({
    type: 'range',
    fromDate: fromDate,
    toDate: toDate,
    displayValue: 'From: ' + format(addDays(new Date(), -30), 'MM-dd-yyyy') + ' To: ' + format(endOfToday(), 'MM-dd-yyyy'),
  });
  const [state, setState] = useState({
    selection: {
      startDate: new Date(),
      endDate: addDays(new Date(), allRange ? differenceInDays(new Date(minDate), endOfToday()) : -30),
      key: 'selection',
    },
    compare: {
      startDate: new Date(),
      endDate: addDays(new Date(), 0),
      key: 'compare',
    },
  });

  const onDataInputClick = () => {
    setdateVisibility(!dateVisibility);
  };

  useEffect(() => {
    getAddUserLogsData();
  }, []);

  useEffect(() => {
    window.addEventListener('click', onWindowClick);
    return () => {
      window.removeEventListener('click', onWindowClick);
    };
  }, []);

  const onWindowClick = (e: any) => {
    if (e.target?.classList.contains('search-input') || e.target?.closest('.date-container')) {
    } else {
      setdateVisibility(false);
    }
  };

  const getAddUserLogsData = async () => {
    setLoading(true);
    let pageList = pageName.map(obj => obj.id).join(',');

    const payload = {
      userid: userID,
      page: pageList,
      dateType: dateValue.type,
      dateValue: dateValue.type === 'range' ? dateValue.fromDate + ',' + dateValue.toDate : dateValue.toDate,
    };
    try {
      const res = await apiResponse('post', 'get-add-user-logs', [], payload);
      if (res?.data?.status === 'SUCCESS') {
        if (res.data.data.length === 0) {
          toast.error('Access Logs: Data not available!!');
          setAddUserLogsData([]);
        } else {
          //setAddUserLogsOriginal(res.data.data);
          setAddUserLogsData(rowSpanning(res.data.data));
        }
      } else {
        console.log(res);
        toast.error('Access Logs: Failed to load!!');
      }
    } catch (error) {
      console.log(error);
      toast.error('Access Logs: Something Went Wrong!!');
    }
    setLoading(false);
  };

  const onDateChange = (item: any) => {
    let dateObj: any = {};
    if (item.selection === undefined) return;

    if (differenceInDays(item.selection.startDate, item.selection.endDate) === 0) {
      dateObj.displayValue = format(item.selection.startDate, 'MM-dd-yyyy');
      dateObj.fromDate = '';
      dateObj.type = 'singleDay';
      dateObj.toDate = format(item.selection.startDate, dateFormat);
    } else {
      dateObj.type = 'range';
      dateObj.displayValue = 'From: ' + format(item.selection.startDate, 'MM-dd-yyyy') + ' To: ' + format(item.selection.endDate, 'MM-dd-yyyy');
      dateObj.fromDate = format(item.selection.startDate, dateFormat);
      dateObj.toDate = format(item.selection.endDate, dateFormat);
    }
    setDateValue(dateObj);
    setState({ ...state, ...item });
  };

  const applyFilters = () => {
    setdateVisibility(false);
    getAddUserLogsData();
  };

  const onCheckAllRange = (e: React.ChangeEvent<HTMLInputElement>) => {
    let item = {
      selection: {
        startDate: new Date(),
        endDate: addDays(new Date(), differenceInDays(new Date(minDate), endOfToday())),
        key: 'selection',
      },
      compare: {
        startDate: new Date(),
        endDate: addDays(new Date(), 0),
        key: 'compare',
      },
    };

    setDateValue({
      type: 'range',
      fromDate: format(new Date(minDate), dateFormat),
      toDate: toDate,
      displayValue: 'From: ' + format(addDays(new Date(minDate), 0), 'MM-dd-yyyy') + ' To: ' + format(endOfToday(), 'MM-dd-yyyy'),
    });
    setAllRange(!allRange);

    setState({ ...state, ...item });
  };

  var currentUserId = '';
  let columnChecks = ['fullname', 'userid', 'position'];

  return (
    <div>
      <div className="access-logs-container adduserlogs">
        <div>
          <div className="access-logs-header access-logs-filters">
            <div className="filters page-user-filter">
              <MultiSelect
                options={pageDropDown.map(item => {
                  return {
                    id: item,
                    label: item,
                    value: item,
                  };
                })}
                className="multiselect search-input"
                value={pageName}
                onChange={setPageName}
                labelledBy="Select"
                disableSearch={true}
                ClearSelectedIcon={null}
              />
            </div>

            <div className="filters">
              <input type="text" onClick={onDataInputClick} value={dateValue.displayValue} className="search-input date-filter" readOnly></input>
              <span className="access-logs-checkbox-option">
                <input type="checkbox" id="allRange" checked={allRange} onChange={e => onCheckAllRange(e)} className="checkbox-field"></input>
                <label className="checkbox-label  ops-fs-5" htmlFor="allRange">
                  All Range
                </label>
              </span>
              {dateVisibility && (
                <>
                  <DateRangePicker
                    className="date-container"
                    maxDate={addDays(new Date(), 0)}
                    direction="vertical"
                    showMonthAndYearPickers={true}
                    showDateDisplay={false}
                    showPreview={true}
                    onChange={(item: any) => onDateChange(item)}
                    onShownDateChange={(item: any) => onDateChange(item)}
                    scroll={{ enabled: false }}
                    editableDateInputs={true}
                    ranges={[state.selection]}
                  />
                </>
              )}
            </div>
            <div className="filters">
              <input
                type="text"
                className="search-input"
                value={userID}
                onChange={evt => {
                  setuserID(evt.target.value);
                }}
                placeholder={'Search by User ID'}
              ></input>
              <Button text="Apply" className="apply-btn" onClick={() => applyFilters()} type="secondary"></Button>
            </div>
          </div>
          <div className="access-logs-table-container">
            {isLoading ? (
              <div className="adm-loader-container">
                <Loader />
              </div>
            ) : (
              <>
                {addUserLogsData.length === 0 ? (
                  <p className="table-msg">{tableMsg}</p>
                ) : (
                  <table className="access-logs-table add-user-logs-table">
                    <thead>
                      <tr>
                        {addUserLogsColumns.map((data: any, indx: any) => {
                          return <th key={indx}>{data.Header}</th>;
                        })}
                      </tr>
                    </thead>
                    <tbody>
                      {addUserLogsData.map((data: any, indx: any) => {
                        return (
                          <tr key={indx}>
                            {addUserLogsColumns.map((colData: any, colIndx: any) => {
                              if (columnChecks.indexOf(colData.accessor) > -1) {
                                if (data.row_span_count > 1) {
                                  currentUserId = data.userid;
                                  return (
                                    <td key={indx + colIndx} rowSpan={data.row_span_count}>
                                      {data[colData.accessor]}
                                    </td>
                                  );
                                } else if (data.row_span_count === 1 && currentUserId === data.userid) {
                                  return <React.Fragment key={indx + colIndx}></React.Fragment>;
                                } else {
                                  return <td key={indx + colIndx}>{data[colData.accessor]}</td>;
                                }
                              } else {
                                return <td key={indx + colIndx}>{data[colData.accessor]}</td>;
                              }
                            })}
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                )}
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddUserLogs;
