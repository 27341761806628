import { ReleaseNotesContextProvider } from '../../contexts/release-note-context';
import './index.scss';
import ReleaseVersionsContainer from '../../components/release-notes/release-versions-container';
import ReleaseNotesContainer from '../../components/release-notes/release-notes-container';

export function ReleaseNotesPage(): JSX.Element {
  return (
    <ReleaseNotesContextProvider>
      <div className="release-notes-page">
        <div className="release-versions">
          <ReleaseVersionsContainer />
        </div>
        <div className="release-notes">
          <ReleaseNotesContainer />
        </div>
      </div>
    </ReleaseNotesContextProvider>
  );
}

export default ReleaseNotesPage;
