import { Loader } from '@gitlab-rtsensing/component-library';
import { Button } from '@opsdti-global-component-library/amgen-design-system';
import { useState, useEffect, useMemo } from 'react';
import { toast } from 'react-toastify';
import '../index.scss';
import { DropDownValType } from '../../user-audit/user-audit-interface';

interface TableProps {
  workstream: { label: string; value: string } | undefined;
  categoryList: DropDownValType[];
  saveConstant: (obj: any) => void;
  loader: boolean;
  rowsAdded: object[];
  addrows: (obj: any) => void;
}

const AddConstant = (props: TableProps) => {
  const [selectedCategory, setSelectedCategory] = useState<{ label: string; value: string }>();
  const [addedRows, setAddedRows] = useState<any>(props.rowsAdded);
  const [constantKey, setConstantKey] = useState('');
  const [constantValue, setConstantValue] = useState('');

  const columns = useMemo(
    () => [
      {
        Header: 'Category',
        accessor: 'category',
      },
      {
        Header: 'Constant Key',
        accessor: 'constant_key',
      },
      {
        Header: 'Constant Value',
        accessor: 'constant_value',
      },
      {
        Header: 'Action',
        accessor: 'action',
      },
    ],
    [],
  );
  const AddConstantRow = () => {
    let exitFunc;
    addedRows.map((row: any) => {
      if (row.constant_key === props.workstream?.label.toLowerCase() + '__' + selectedCategory?.label.toLowerCase() + '__' + constantKey.toLowerCase()) {
        toast.warning('Constant key already added');
        exitFunc = true;
        return;
      }
    });
    if (exitFunc === true) {
      return;
    }
    const newRow = {
      categoryID: selectedCategory?.value,
      category: selectedCategory?.label.toLowerCase(),
      constant_key: props.workstream?.label.toLowerCase() + '__' + selectedCategory?.label.toLowerCase() + '__' + constantKey.toLowerCase(),
      constant_value: constantValue,
    };
    props.addrows([...addedRows, newRow]);

    setConstantKey('');
    setConstantValue('');
  };

  useEffect(() => {
    setSelectedCategory({ label: '', value: '' });
  }, [props.categoryList]);

  useEffect(() => {
    setAddedRows(props.rowsAdded);
  }, [props.rowsAdded]);

  const deleteRow = (index: number) => {
    props.addrows(
      addedRows.filter((row: any, idx: number) => {
        return idx !== index;
      }),
    );
  };
  const handleChange = (e: any) => {
    let index = e.nativeEvent.target.selectedIndex;
    let label = e.nativeEvent.target[index].text;
    let value = e.target.value;
    setSelectedCategory({ label: label, value: value });
    props.addrows([]);
  };

  const handleKeyDown = (e: any) => {
    if (e.key === '_') {
      e.preventDefault();
    }
  };

  return (
    <div className="add-constant-container">
      <div className="constant-note">
        <p>* Multiple constant can be add only for single category at a time</p>
        <p>* New Constants need to configure in UI for Use</p>
      </div>
      <div className="d-flex justify-content-between constant-container">
        <div>
          <label className="form-label">
            Category <sup className="sup-star">*</sup>
          </label>
          <select
            className="select-filter-data-elements"
            value={selectedCategory?.value}
            onChange={e => {
              handleChange(e);
            }}
          >
            <option value="">Select</option>
            {props.categoryList.map(option => (
              <option className="option-style" key={option.id} value={option.id}>
                {option.label}
              </option>
            ))}
          </select>
        </div>

        <div className="d-flex">
          <label className="form-label constant-label">
            Constant Key <sup className="sup-star">*</sup>
          </label>
          <input
            type="text"
            className="search-input constant-input"
            value={constantKey}
            onChange={evt => {
              setConstantKey(evt.target.value);
            }}
            placeholder={'Type'}
            onKeyDown={handleKeyDown}
          ></input>
        </div>
        <div className="d-flex">
          <label className="form-label constant-label">
            Constant Value <sup className="sup-star">*</sup>
          </label>
          <input
            type="text"
            className="search-input constant-input"
            value={constantValue}
            onChange={evt => {
              setConstantValue(evt.target.value);
            }}
            placeholder={'Type'}
          ></input>
        </div>
        <div className="mt-1">
          <Button
            text="Add Constant"
            type="secondary"
            className="constant-update-btn"
            disabled={constantKey === '' || constantValue === '' || selectedCategory?.label === ''}
            onClick={() => AddConstantRow()}
          ></Button>
        </div>
      </div>
      {props.loader ? (
        <div className="adm-loader-container">
          <Loader />
        </div>
      ) : (
        <div className="table-container config-api-container">
          <table className="table-bordered config-api-table">
            <thead>
              <tr>
                {columns.map((data: any, indx: any) => {
                  if (data.accessor === 'action') {
                    return (
                      <th key={indx} className="action-header">
                        {data.Header}
                      </th>
                    );
                  } else {
                    return <th key={indx}>{data.Header}</th>;
                  }
                })}
              </tr>
            </thead>
            <tbody>
              {addedRows.map((data: any, indx: any) => {
                return (
                  <tr key={indx}>
                    {columns.map((colData: any, colIndx: any) => {
                      if (colData.accessor === 'action') {
                        return (
                          <td key={indx + colIndx}>
                            <div className="d-flex justify-content-center">
                              <Button text="Delete" type="secondary" className="constant-update-btn" onClick={() => deleteRow(indx)}></Button>
                            </div>
                          </td>
                        );
                      } else {
                        return <td key={indx + colIndx}>{data[colData.accessor]}</td>;
                      }
                    })}
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      )}
      <div className="d-flex justify-content-end">
        <Button text="Save" type="primary" className="config-btn" onClick={() => props.saveConstant(addedRows)} disabled={addedRows.length === 0}></Button>
      </div>
    </div>
  );
};

export default AddConstant;
