import { Modal } from '@opsdti-global-component-library/amgen-design-system';
import SearchByUser from '../search-by-user';
import AmgenNetworks from '../../../assets/icons/amgen-networks';
import Logo from '../../../assets/images/Amgen-Sensing.png';
import './index.scss';

interface QuickAddModalProps {
  opened: boolean;
  quickAddModalClosed: () => void;
}

export default function QuickAddModal(props: QuickAddModalProps): JSX.Element {
  const { opened, quickAddModalClosed } = props;
  return (
    <Modal
      className="quick-add-access-modal"
      open={opened}
      onCancel={quickAddModalClosed}
      maskCloseable={true}
      title={
        <div className="quick-add-modal-header d-flex align-items-center">
          <AmgenNetworks fill="#000" classname="amgen-networks" />
          <img src={Logo} alt="amgen logo"></img>
          <p className="quick-add-modal-title">Quick Add or Remove User</p>
        </div>
      }
      width={1200}
      height={500}
    >
      <SearchByUser searchModalClosed={quickAddModalClosed} userID={''} isQuickAdd={true} />
    </Modal>
  );
}
