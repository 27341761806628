interface Props {
  fill: string;
  classname?: string;
}

const AccessRequestLogo = ({ fill, classname }: Props) => {
  return (
    <svg width="18" height="18" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M6.56376 12.8458C6.70213 12.9265 6.77131 12.9669 6.86895 12.9878C6.94472 13.0041 7.05528 13.0041 7.13105 12.9878C7.22869 12.9669 7.29787 12.9265 7.43624 12.8458C8.65377 12.1355 12 9.90427 12 6.8365V3.8475C12 3.3478 12 3.09796 11.9183 2.88319C11.8461 2.69346 11.7288 2.52417 11.5765 2.38995C11.4041 2.23802 11.1701 2.15029 10.7022 1.97484L7.35112 0.718169C7.22119 0.669443 7.15622 0.645081 7.08938 0.635423C7.0301 0.626856 6.9699 0.626856 6.91061 0.635423C6.84378 0.645081 6.77881 0.669443 6.64888 0.718169L3.29775 1.97484C2.82987 2.15029 2.59593 2.23802 2.42354 2.38995C2.27124 2.52417 2.15392 2.69346 2.08173 2.88319C2 3.09796 2 3.3478 2 3.8475V6.8365C2 9.90427 5.34623 12.1355 6.56376 12.8458Z"
        stroke={fill}
        strokeWidth="0.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M7 7.4615C6.0517 7.4615 5.20839 7.93983 4.67149 8.68212C4.55593 8.84188 4.49815 8.92176 4.50004 9.02972C4.50151 9.11312 4.55164 9.21834 4.61446 9.26984C4.69578 9.3365 4.80846 9.3365 5.03382 9.3365H8.96618C9.19154 9.3365 9.30422 9.3365 9.38554 9.26984C9.44836 9.21834 9.49849 9.11312 9.49995 9.02972C9.50185 8.92176 9.44407 8.84188 9.32851 8.68212C8.79161 7.93983 7.9483 7.4615 7 7.4615Z"
        stroke={fill}
        strokeWidth="0.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M7 6.524C7.74345 6.524 8.34613 5.8944 8.34613 5.11775C8.34613 4.3411 7.74345 3.7115 7 3.7115C6.25655 3.7115 5.65387 4.3411 5.65387 5.11775C5.65387 5.8944 6.25655 6.524 7 6.524Z"
        stroke={fill}
        strokeWidth="0.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default AccessRequestLogo;
