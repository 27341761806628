import React, { useContext, useState } from 'react';
import { useReleaseNotesContext } from '../../../contexts/release-note-context';
import { IReleaseVersionInitialFormData } from '../release-note-interfaces';
import { OpsButton, OpsTypography } from '@gitlab-rtsensing/component-library';
import './index.scss';
import QuarterDropdown from '../quarter-filter-select';
import { DateRange, filterReleaseVersionsByDateRanges } from '../utils';
import { AppContext } from '../../../contexts/app-context';
import { formatMonthDayYearToMMDDYY } from '../../../utility/commonMethods';

interface ReleaseVersionListInterface {
  editVersion: (initialFormData: IReleaseVersionInitialFormData) => void;
}

const ReleaseVersionsList: React.FC<ReleaseVersionListInterface> = ({ editVersion }) => {
  const { releaseVersions, setFilters, filters, isPublicView } = useReleaseNotesContext();

  const { authData } = useContext(AppContext);
  const userIsPM = authData?.adminAuthorization.releaseNotesPm;

  const [hoveredVersionId, setHoveredVersionId] = useState<number | null>(null);

  // sort versions by version number and then by date, descending order
  const sortedVersions = filterReleaseVersionsByDateRanges(
    [...releaseVersions].sort((a, b) => {
      const [ax, ay, az] = a.releaseVersionNumber.split('.');
      const [bx, by, bz] = b.releaseVersionNumber.split('.');
      const aNum = parseInt(ax) * 1000000 + parseInt(ay) * 1000 + parseInt(az);
      const bNum = parseInt(bx) * 1000000 + parseInt(by) * 1000 + parseInt(bz);

      if (aNum !== bNum) return bNum - aNum;
      return new Date(b.releaseVersionDate).getTime() - new Date(a.releaseVersionDate).getTime();
    }),
    filters.dateRanges,
  );

  const handleDateRangeChange = (selectedRanges: DateRange[]) => {
    setFilters({ ...filters, dateRanges: selectedRanges });
  };

  return (
    <ul className="release-version-list">
      <OpsTypography variant={'h3'} elementTag={'div'} children={'Releases'} variantWeight={700} />
      <>
        <QuarterDropdown releaseVersions={releaseVersions} onSelectedItemsChange={handleDateRangeChange} />
        {sortedVersions.map(version => (
          <li key={version.releaseVersionId} onMouseEnter={() => setHoveredVersionId(version.releaseVersionId)} onMouseLeave={() => setHoveredVersionId(null)}>
            <a href={`#${formatMonthDayYearToMMDDYY(version.releaseVersionDate).split('/').join('')}`}>
              <span className="release-version-list__details">
                <OpsTypography variant="p1" elementTag="span" variantWeight={700}>{`v${version.releaseVersionNumber}`}</OpsTypography>{' '}
                <OpsTypography variant="p1" elementTag="span">{`(Released ${formatMonthDayYearToMMDDYY(version.releaseVersionDate)})`}</OpsTypography>
              </span>
            </a>
            {userIsPM && hoveredVersionId === version.releaseVersionId && !isPublicView && (
              <OpsButton
                label="EDIT"
                type="secondary"
                onClick={e => {
                  e.stopPropagation();
                  editVersion(version);
                }}
              />
            )}
          </li>
        ))}
      </>
    </ul>
  );
};

export default ReleaseVersionsList;
