import { Button } from '@opsdti-global-component-library/amgen-design-system';
import './index.scss';

interface CnfBoxPropsType {
  userInfo: string;
  groupName: string;
  onConfirm: () => void;
  onCancel: (value: Boolean) => void;
}

const ConfirmationBox = (props: CnfBoxPropsType) => {
  return (
    <div className="rmv-modal">
      <div className="modal-body">
        <p>
          Are you sure to remove <span style={{ color: 'blue' }}>{props.userInfo}</span> from <span style={{ color: 'blue' }}>{props.groupName}</span> AD-Group?
        </p>
      </div>
      <div className="modal-footer">
        <Button type="primary" className="cnf-button" text="Confirm" onClick={props.onConfirm} />
        <Button type="secondary" className="cnf-button" text="Cancel" onClick={() => props.onCancel(false)} />
      </div>
    </div>
  );
};

export default ConfirmationBox;
