import React, { useEffect, useRef } from 'react';
import { IReleaseNote } from '../release-note-interfaces';
import { OpsButton, OpsTypography } from '@gitlab-rtsensing/component-library';
import './index.scss';
import DeleteIcon from '../../../assets/icons/delete-icon';
import { DEFERRED, DRAFT, PENDING } from '../constants';

interface ReleaseNoteInputProps {
  note: IReleaseNote;
  index: number;
  disabled?: boolean;
  handleReleaseNoteChange: (event: React.ChangeEvent<HTMLTextAreaElement>, index: number) => void;
  handleRemoveReleaseNote: (event: React.MouseEvent<HTMLButtonElement>, index: number) => void;
  handlePrivateNoteChange: (event: React.ChangeEvent<HTMLInputElement>, index: number) => void;
  handleRapidReleaseChange: (event: React.ChangeEvent<HTMLInputElement>, index: number) => void;
  releaseNoteStatus: string;
  handleGoChange: (event: React.ChangeEvent<HTMLSelectElement>, index: number) => void;
}

const ReleaseNoteInput: React.FC<ReleaseNoteInputProps> = ({
  note,
  index,
  disabled,
  handleReleaseNoteChange,
  handleRemoveReleaseNote,
  handlePrivateNoteChange,
  handleRapidReleaseChange,
  releaseNoteStatus,
  handleGoChange
}) => {
  const textAreaRef = useRef<HTMLTextAreaElement>(null);

  useEffect(() => {
    if (textAreaRef.current) {
      textAreaRef.current.style.height = '24px';
      textAreaRef.current.style.height = `${textAreaRef.current.scrollHeight + 2}px`;
    }
  }, [note.content]);

  return (
    <div className="release-note-input">
      <textarea
        ref={textAreaRef}
        className="release-note-input__textarea ops-fs-5"
        disabled={disabled}
        value={note.content}
        onChange={e => handleReleaseNoteChange(e, index)}
      />
      {/* <div className="release-note-input__buttons-container"> */}
      <input
        className="release-note-input__internal-checkbox"
        type="checkbox"
        disabled={disabled}
        checked={note.isPrivate}
        onChange={e => handlePrivateNoteChange(e, index)}
      />
      <input
        className="release-note-input__internal-checkbox"
        type="checkbox"
        disabled={disabled}
        checked={note.isRapidrelease}
        onChange={e => handleRapidReleaseChange(e, index)}
      />
      {([PENDING, DEFERRED, DRAFT].includes(releaseNoteStatus) && (
        <select
          className="release-note-input__internal-select"
          disabled={disabled}
          onChange={e => handleGoChange(e, index)}
          value={note.isGoNogo}
        >
          <option value="">Select</option>
          <option value='Yes'>Yes</option>
          <option value='No'>No</option>
        </select>
      )
      )}
      <OpsButton
        className="release-note-input__delete-button"
        type="secondary"
        disabled={disabled}
        onClick={e => handleRemoveReleaseNote(e, index)}
        leftIcon={<DeleteIcon sizeInPx={14} fill={disabled ? 'var(--ops-gray-500) ' : 'var(--ops-sem-red-primary)'} />}
        label=""
      />
      {/* </div> */}
    </div>
  );
};

export default ReleaseNoteInput;
