import { Tabs } from '@gitlab-rtsensing/component-library';
import AddUserLogs from '../../components/access-logs/addUserLogs';
import RemoveUserLogs from '../../components/access-logs/removeUserLogs';
//import ExternalAccessLog from '../../components/access-logs/externalAccessLog';

const AccessLogs = () => {
  return (
    <>
      <Tabs
        className=""
        tabs={[
          {
            content: <AddUserLogs />,
            header: {
              label: 'User Addition Log',
            },
          },
          {
            content: <RemoveUserLogs />,
            header: {
              label: 'User Removal Log',
            },
          },
          /*  {
             content: <ExternalAccessLog />,
             header: {
               label: 'External Access Request Logs',
             },
           }, */
        ]}
      />
    </>
  );
};

export default AccessLogs;
