import React, { useMemo, useState, useEffect } from 'react';
import DataTable from '../../data-table';
import { Level2_Table_Header } from './Level2TableHeader';
import { Button } from '@opsdti-global-component-library/amgen-design-system';
import { Loader } from '@gitlab-rtsensing/component-library';
import { apiResponse } from '../../../utility/commonMethods';
import { toast } from 'react-toastify';

const LevelTwoApprover: React.FC = () => {
  const columns = useMemo(() => Level2_Table_Header, []);
  const [data, setData] = useState([]);
  const [isLoading, setLoading] = useState(true);
  const [level2ReqList, setLevel2ReqList] = useState([{}]);
  const [isDisabled, setDisabled] = useState(true);

  useEffect(() => {
    getLevelTwoRequest();
  }, []);

  const getLevelTwoRequest = async () => {
    const payload = {
      approval_level: 'level_2',
    };
    try {
      setLoading(true);
      const res = await apiResponse('post', 'get-access-request-list', [], payload);
      if (res?.data.data) {
        let tableData = res?.data.data.request_details;
        setData(tableData);
        setLoading(false);
      } else {
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
  };
  const submitLevel2Req = async () => {
    const payload = {
      approval_request_list: level2ReqList,
    };
    try {
      setDisabled(true);
      const res = await apiResponse('post', 'submit-requests', [], payload);
      if (res?.data.status === 'SUCCESS') {
        toast.success('Level two approval request has been acted upon');
        getLevelTwoRequest();
      } else {
        toast.error('Failed to Submit Request');
      }
      setDisabled(false);
    } catch (error) {
      console.log(error);
      toast.error('Failed to Submit Request');
      setDisabled(false);
    }
  };

  useEffect(() => {
    if (level2ReqList.length > 0) {
      setDisabled(false);
    } else setDisabled(true);
  }, [level2ReqList]);

  return (
    <>
      <div className="table-container">
        {isLoading ? (
          <div className="adm-loader-container">
            <Loader />
          </div>
        ) : (
          <div>
            <DataTable columns={columns} data={data} handleTaskClick={setLevel2ReqList} component="level_2" />
            <div className="d-flex justify-content-end ">
              <Button text="Submit" type="primary" onClick={() => submitLevel2Req()} className="admin-btn" disabled={isDisabled}></Button>
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default LevelTwoApprover;
