import { useState, useEffect } from 'react';
import 'react-date-range/dist/styles.css'; // main css file
import 'react-date-range/dist/theme/default.css'; // theme css file
import '../index.scss';
import { apiResponse } from '../../../../utility/commonMethods';
import { toast } from 'react-toastify';
import { Loader } from '@gitlab-rtsensing/component-library';
import BarGraph from '../../../bar-graph';
import { MultiSelect } from 'react-multi-select-component';
import { TopTenUserProps } from '../../usage-metric-interface';

const KPI = (props: TopTenUserProps) => {
  const [isLoading, setLoading] = useState(true);
  const [getUsersList, setUsersList] = useState([]);
  const [getUsersListOriginal, setUsersListOriginal] = useState([]);

  const [gcfLevel, setGcfLevel] = useState([{ id: 0, value: 'NA', label: 'NA' }]);

  const [gcfOptions, setGcfOptions] = useState([]);
  useEffect(() => {
    if (props.clearData) {
      setUsersList([]);
      setUsersListOriginal([]);
      getUsageTrackingData();
    }
  }, [props.clearData]);

  useEffect(() => {
    if (getUsersListOriginal.length === 0) return;

    setUsersList(filterTopTenUser([]));
  }, [gcfLevel]);

  const filterTopTenUser = (userList: []) => {
    let userListOriginal: any = [];
    if (userList.length > 0) {
      userListOriginal = userList;
    } else {
      userListOriginal = getUsersListOriginal.filter((obj: any) => {
        if (props.userGroupValue.toLowerCase() === 'all sensing users' && props.includeCoreMember === 'N') {
          return obj.group_name.toLowerCase() === props.userGroupValue.toLowerCase() && obj.core_member_flag === props.includeCoreMember;
        } else {
          return obj.group_name.toLowerCase() === props.userGroupValue.toLowerCase();
        }
      });
    }

    let gcfFilteredList = userListOriginal.filter((val: any) => gcfLevel.map((levelInfo: any) => levelInfo.value).indexOf(val.gcf_level) > -1);
    gcfFilteredList.sort((a: any, b: any) => {
      if (a.visitcount > b.visitcount) {
        return -1;
      } else if (a.visitcount < b.visitcount) {
        return 1;
      } else {
        return 0;
      }
    });

    if (gcfFilteredList.length > 10) {
      gcfFilteredList = gcfFilteredList.slice(0, 10);
    }

    return gcfFilteredList;
  };

  useEffect(() => {
    if (getUsersListOriginal.length === 0) return;

    filterGroupValue([]);
  }, [props.userGroupValue, props.includeCoreMember]);

  const filterGroupValue = (dataArray: any) => {
    if (dataArray.length === 0) {
      dataArray = getUsersListOriginal;
    }
    const filteredData = dataArray.filter(
      (obj: any) => obj.group_name.toLowerCase() === props.userGroupValue.toLowerCase() && obj.core_member_flag === props.includeCoreMember,
    );

    if (filteredData.length > 0) {
      setUsersList(filterTopTenUser(filteredData));
    } else {
      setUsersList(filteredData);
    }
    // for getting GCF Level option list from user data
    let gcfoptionList: any = [];
    filteredData.map((value: any, index: any, self: any) => {
      if (index === self.findIndex((t: any) => t.gcf_level === value.gcf_level)) {
        gcfoptionList.push(value.gcf_level);
      }
    });
    setGcfOptions(gcfoptionList);
    let optionList: Array<{ id: number; label: string; value: string }> = [];

    for (let i = 0; i < gcfoptionList.length; i++) {
      optionList.push({
        id: i,
        value: gcfoptionList[i],
        label: gcfoptionList[i],
      });
    }
    setGcfLevel(optionList);
  };
  const handleGCFValueChange = (data: any) => {
    setGcfLevel(data);
  };

  const getUsageTrackingData = async () => {
    setLoading(true);

    const payload = {
      metric_id: '7',
      date_type: props.date.type,
      to_date: props.date.toDate,
      fr_date: props.date.fromDate,
    };
    try {
      const res = await apiResponse('post', 'get-usage-metric-data', [], payload);
      if (res?.data?.status === 'SUCCESS') {
        if (res?.data?.data?.user_metric) {
          if (res.data.data.user_metric.length > 0) {
            const userList = res.data.data.user_metric;
            filterGroupValue(userList);
            setUsersListOriginal(userList);
            // setUsersList(filterTopTenUser(userList));
          } else {
            toast.error('Data not available!!');
          }
        }
        props.setClearData(false);
      } else {
        console.log(res);
        toast.error('Failed to load!!');
      }
    } catch (error) {
      console.log(error);
      toast.error('Something Went Wrong!!');
    }
    setLoading(false);
  };

  return (
    <div className="usage-metric kpi-metric">
      <div className="metric-header no-left-field">
        {/* <div className="non-filter-note">
          <p>* Only beside/ below metrics can be filter by User</p>
        </div> */}
        <div className="filters">
          <div className="gcf-multiselect">
            <span className="ops-text ops-text-dark ops-fw-bold ops-fs-4 gcf-text">GCF Level</span>
            <MultiSelect
              options={gcfOptions.map((option: any, indx: number) => {
                return {
                  id: indx,
                  value: option,
                  label: option,
                };
              })}
              value={gcfLevel}
              className="dropdown-container"
              onChange={(selected: any) => {
                handleGCFValueChange(selected);
              }}
              labelledBy="GCF Level"
              disableSearch={true}
              hasSelectAll={true}
              ClearSelectedIcon={null}
            />
          </div>
        </div>
      </div>
      {isLoading && getUsersList.length === 0 ? (
        <div className="adm-loader-container">
          <Loader />
        </div>
      ) : (
        <div className="kpi-metric-container">
          <BarGraph
            chartType="kpi"
            data={getUsersList}
            top={0}
            bottom={0}
            left={20}
            right={20}
            clearFilter={props.resetFilter}
            primaryColor={props.primaryColor}
            highlightedColor={props.highlightedColor}
          />
        </div>
      )}
    </div>
  );
};
export default KPI;
