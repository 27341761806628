import { OpsTypography } from '@gitlab-rtsensing/component-library';
import { ICommentHistory, IReleaseNotesInitialFormData } from '../release-note-interfaces';
import './index.scss';
import React from 'react';
import { formatMonthDayYearToMMDDYY } from '../../../utility/commonMethods';

interface CommentSectionPropsInterface {
  note: IReleaseNotesInitialFormData;
  comments: string;
  commentHistory: ICommentHistory[] | null;
  disabled?: boolean;
  handleCommentSectionChange: (event: React.ChangeEvent<HTMLTextAreaElement>) => void;
}

export default function CommentSection({ note, comments, commentHistory, disabled, handleCommentSectionChange }: CommentSectionPropsInterface) {
  return (
    <div className="comment-section">
      <div className="comment-section__header">
        {!disabled && <OpsTypography variant="label" elementTag="div" variantWeight={700} className="comment-section__title" children="COMMENTS" />}
      </div>
      <div className="comment-section__main-content">
        <div className="comment-section__primary-content">
          {!disabled && (
            <textarea
              className="comment-section__textarea ops-fs-5"
              placeholder="Deferral justification..."
              disabled={disabled}
              value={comments}
              onChange={e => handleCommentSectionChange(e)}
            />
          )}
          <div className="comment_section__metadata">
            <div>
              <OpsTypography variantWeight={700} variant="label" elementTag="div" children={'CREATED'} />
              <OpsTypography variant="p1" elementTag="div" children={`${note?.createdBy || ''} ${formatMonthDayYearToMMDDYY(note?.createdDate || '') || ''}`} />
            </div>
            <div>
              <OpsTypography variantWeight={700} variant="label" elementTag="div" children={'UPDATED'} />
              <OpsTypography
                variant="p1"
                elementTag="div"
                children={`${note?.lastUpdatedBy || ''} ${formatMonthDayYearToMMDDYY(note?.lastUpdateDate || '') || ''}`}
              />
            </div>
          </div>
        </div>
        {!!commentHistory && (
          <div className={`comment-section__comment-history`}>
            <OpsTypography variant="label" variantWeight={700} elementTag="div" children="COMMENT HISTORY" />
            <div className="comment-section-comment__history-item-container">
              {commentHistory.map(comment => {
                return (
                  <div className="comment-section__comment-history-item">
                    <OpsTypography variant={'p1'} elementTag={'div'} children={comment.comment} />
                    <OpsTypography variant={'p1'} elementTag={'div'} children={`${comment.updatedBy} ${formatMonthDayYearToMMDDYY(comment.updatedDate)}`} />
                  </div>
                );
              })}
            </div>
          </div>
        )}
      </div>
    </div>
  );
}
