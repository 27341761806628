import { useState, useEffect, useMemo, useContext } from 'react';
import 'react-date-range/dist/styles.css'; // main css file
import 'react-date-range/dist/theme/default.css'; // theme css file
import '../index.scss';
import { usageTrackingHeader } from './usageTrackingTable';
import { apiResponse, filterMembers } from '../../../utility/commonMethods';
import { toast } from 'react-toastify';
import { Loader } from '@gitlab-rtsensing/component-library';
import { AppContext } from '../../../contexts/app-context';
import { MetricProps, RowSpanType } from '../usage-metric-interface';
import { FileDownloadIcon, ExpandIcon, CloseIcon } from '@opsdti-global-component-library/amgen-design-system';
import AgGridTable from '../../../utility/agGridTable';

const UserLoginHistory = (props: MetricProps) => {
  const columns = useMemo(() => usageTrackingHeader, []);
  const [usageTrackingData, setUsageTrackingData] = useState(Array<RowSpanType>);
  const [usageTrackingOriginal, setUsageTrackingOriginal] = useState<[]>([]);
  const [isLoading, setLoading] = useState(true);
  const [filterVal, setFilterVal] = useState('');
  const { userFilter } = useContext(AppContext);
  const [tableMsg, setTableMsg] = useState('No Data Available');

  const [totalvisits, setTotalvisits] = useState(0);
  const [showExpandOrClose, setShowExpandOrClose] = useState(false);
  const [triggerDownload, setTriggerDownload] = useState(false);

  useEffect(() => {
    if (props.clearData) {
      setFilterVal('');
      setUsageTrackingData([]);
      setUsageTrackingOriginal([]);
      getUsageTrackingData();
    }
  }, [props.clearData]);

  useEffect(() => {
    if (userFilter.filter) {
      filterGroupValue([], false, userFilter);
    } else {
      filterGroupValue(usageTrackingOriginal, false, { userId: '', fullname: '', filter: false });
    }
  }, [userFilter, props.includeCoreMember, props.userGroupValue]);

  useEffect(() => {
    if (usageTrackingOriginal.length === 0) return;
    setFilterVal('');
    filterGroupValue([], false, { userId: '', fullname: '', filter: false });
  }, [props.userGroupValue, props.includeCoreMember, usageTrackingOriginal]);

  // useEffect(() => {
  //   if (!showExpandOrClose) {
  //     document.getElementById('user-login-history')?.classList.add('container-width-100');
  //   } else {
  //     document.getElementById('user-login-history')?.classList.remove('container-width-100');
  //   }
  // }, [showExpandOrClose]);

  const filterGroupValue = (dataArray: [], localSearch: Boolean, userFilter: { userId: string; fullname: string; filter: Boolean }) => {
    let filteredData = [];
    if (dataArray.length === 0 && !localSearch) {
      filteredData = filterMembers(usageTrackingOriginal, props.userGroupValue, props.includeCoreMember);
    } else {
      filteredData = filterMembers(dataArray, props.userGroupValue, props.includeCoreMember);
    }

    if (userFilter.userId !== '') {
      //In case of Bar Click
      filteredData = filteredData.filter((obj: { userid: string }) => obj.userid === userFilter.userId);
      if (filteredData.length === 0) {
        setTableMsg(`No Data Available for  ${userFilter.fullname}`);
      }
    } else if (localSearch && dataArray.length === 0) {
      //Incase of local Search
      setTableMsg(`No Data Available`);
    } else if (filteredData.length === 0) {
      //Incase of GroupValue search
      setTableMsg(`No Data Available for  ${props.userGroupValue}`);
    }
    setUsageTrackingData(filteredData);
    let totalCount = 0;
    filteredData.map((count: any) => {
      totalCount += count.visitcount;
      return totalCount;
    });
    getTotalVisit(filteredData);
  };

  const getUsageTrackingData = async () => {
    setLoading(true);

    const payload = {
      metric_id: '1',
      date_type: props.date.type,
      to_date: props.date.toDate,
      fr_date: props.date.fromDate,
    };
    try {
      const res = await apiResponse('post', 'get-usage-metric-data', [], payload);
      if (res?.data?.status === 'SUCCESS') {
        if (res?.data?.data?.user_metric) {
          if (res.data.data.user_metric.length === 0) {
            toast.error('Login History: Data not available!!');
          } else {
            res.data.data.user_metric.sort((a: { visitcount: number }, b: { visitcount: number }) => {
              if (a.visitcount > b.visitcount) {
                return -1;
              } else if (a.visitcount < b.visitcount) {
                return 1;
              } else {
                return 0;
              }
            });
            filterGroupValue(res.data.data.user_metric, false, { userId: '', fullname: '', filter: false }); //Filter By User Group & SetUsageTrackingData
            setUsageTrackingOriginal(res.data.data.user_metric);
            let totalCount = 0;
            res.data.data.user_metric.map((count: any) => {
              totalCount += count.visitcount;
              return totalCount;
            });
            //getTotalVisit(totalCount);
            props.setClearData(false);
          }
        }
      } else {
        console.log(res);
        toast.error('Login History: Failed to load!!');
      }
    } catch (error) {
      console.log(error);
      toast.error('Login History: Something Went Wrong!!');
    }
    setLoading(false);
  };

  const getTotalVisit = (data: { visitcount: number }[]) => {
    let totalCount = 0;
    data.map((count: { visitcount: number }) => {
      totalCount += count.visitcount;
      return totalCount;
    });
    setTotalvisits(totalCount);
  };

  return (
    <div className="usage-metric login-history">
      <div className="metric-header">
        <div>
          <label className="form-label">{props.date.displayValue}</label>
        </div>
        <div className="filters ">
        <FileDownloadIcon height={28} width={28} onClick={() => {
          setTriggerDownload(true);
        }} />
          {!showExpandOrClose ? (
            <span
              onClick={() => {
                setShowExpandOrClose(true);
              }}
              className="expand-collapse-icon"
            >
              <ExpandIcon className="expand-collapse-icon" /> {/*This classname somehow not rendering*/}
            </span>
          ) : (
            <span
              onClick={() => {
                setShowExpandOrClose(false);
              }}
            >
              <CloseIcon className="expand-collapse-icon" />
            </span>
          )}
        </div>
      </div>

      <div className="metric-header no-left-field ">
        <div className="color-theme">{usageTrackingData.length > 0 ? <label> Total Visits : {totalvisits} </label> : null}</div>
      </div>
      {isLoading ? (
        <div className="adm-loader-container">
          <Loader />
        </div>
      ) : (
        <div className="table-container usage-metric-container">
          <AgGridTable
            rowData={usageTrackingData}
            columnDefs={columns}
            matamoDate={props.date.matamoDate}
            matamoType={props.date.matamoType}
            triggerdownload={triggerDownload}
            setTriggerDownload={setTriggerDownload}
            tableName="User Login History"
            onExpandableClosed={() => setShowExpandOrClose(false)}
            expandable={showExpandOrClose}
          />
        </div>
      )}
    </div>
  );
};
export default UserLoginHistory;
