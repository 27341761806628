import React, { useState, useEffect } from 'react';
import { Select } from '@opsdti-global-component-library/amgen-design-system';
import { Table, Button } from '@opsdti-global-component-library/amgen-design-system';
import ExternalLinkLogo from '../../assets/icons/external-link';
import { workDayRedirect } from '../../utility/commonMethods';
import { apiResponse } from '../../utility/commonMethods';
import { toast } from 'react-toastify';

interface FeedbackDetailTableProps {
  feedbackDetail: any[];
}

const FeebackDetailTable: React.FC<FeedbackDetailTableProps> = ({ feedbackDetail }) => {
  const [actionTaken, setActionTaken] = useState([]);
  const [actionTakenData, setActionTakenData] = useState<{ id: string; action: string }[]>([]);

  const feedbackRecordColumn = [
    {
      dataIndex: 'primary_domain',
      key: 'primary_domain',
      title: 'Domain',
      width: '5%',
      sorter: (a: any, b: any) => a.primary_domain.localeCompare(b.primary_domain),
    },
    {
      dataIndex: 'fullname',
      key: 'fullname',
      title: 'User Name',
      width: '7%',
      render: (data: any) => (
        <div className="external-link" onClick={() => workDayRedirect(data[1])}>
          {data[0]}
          <ExternalLinkLogo />
        </div>
      ),
    },
    {
      dataIndex: 'question_date',
      key: 'question_date',
      title: 'Question Date',
      width: '7%',
      sorter: (a: any, b: any) => new Date(a.question_date).getTime() - new Date(b.question_date).getTime(),
    },
    {
      dataIndex: 'question_text',
      key: 'question_text',
      title: 'Question',
      width: '10%',
    },
    {
      dataIndex: 'answer_text',
      key: 'answer_text',
      title: 'Answer',
      width: '38%',
    },
    {
      dataIndex: 'user_feedback_rating',
      key: 'user_feedback_rating',
      title: 'Feedback',
      width: '5%',
      sorter: (a: any, b: any) => a.user_feedback_rating.localeCompare(b.user_feedback_rating),
    },
    {
      dataIndex: 'user_feedback_comment',
      key: 'user_feedback_comment',
      title: 'Comment',
      width: '10%',
    },
    {
      dataIndex: 'execution_time',
      key: 'execution_time',
      title: 'Execution Time (s)',
      width: '5%',
    },
    {
      dataIndex: 'action_taken',
      key: 'action_taken',
      title: 'Action Taken',
      render: (data: string, row: any, index: number) =>
        data === null ? (
          <Select
            className="action-taken-select"
            options={[
              {
                value: 'Yes',
                label: 'Yes',
              },
              {
                value: 'No',
                label: 'No',
              },
              {
                value: 'N/A- No Action Needed',
                label: 'N/A- No Action Needed',
              },
            ]}
            onChange={(selected: any) => {
              handleActionChange(selected, index, row);
            }}
            value={actionTaken[index]}
            placeholder="Select"
          ></Select>
        ) : (
          data
        ),
      width: '7%',
    },
  ];

  const handleActionChange = (actionValue: any, indx: number, row: any) => {
    const newAction: any = [...actionTaken];
    newAction[indx] = actionValue;
    setActionTaken(newAction);
    if (actionTakenData.length > 0) {
      const index = actionTakenData.findIndex((item: any) => item.id === row.id);
      if (index !== -1) {
        actionTakenData[index].action = actionValue;
      } else {
        actionTakenData.push({ id: row.id, action: actionValue });
      }
    } else {
      actionTakenData.push({ id: row.id, action: actionValue });
    }
  };

  const submitActionTaken = async () => {
    const payload = {
      action_list: actionTakenData,
    };
    console.log(payload);
    if (actionTakenData.length === 0) {
      toast.error('Please select action for at least one record.');
      return;
    }
    try {
      const res = await apiResponse('post', 'submit-feedback-action', [], payload);
      if (res?.data?.status === 'SUCCESS') {
        toast.success('Action Taken Successfully!!');
      } else {
        console.log(res);
        toast.error('Failed to submit action.');
      }
    } catch (error) {
      console.log(error);
      toast.error('Something Went Wrong!!');
    }
  };

  return (
    <>
      <div className="feedback-record-table-container">
        <div className="detail-container">
          <Table
            columns={feedbackRecordColumn}
            dataSource={feedbackDetail}
            scroll={{
              x: 600,
              y: 650,
            }}
            style={{
              width: '100%',
            }}
            tableLayout="auto"
          />
        </div>
        <Button text="Submit" type="primary" className="submit-btn" onClick={submitActionTaken}></Button>
      </div>
    </>
  );
};

export default FeebackDetailTable;
