import { FC, useState, useEffect, forwardRef } from 'react';
import DatePicker from 'react-datepicker';

import { OpsTypography } from '@gitlab-rtsensing/component-library';

import './index.scss';
import 'react-datepicker/dist/react-datepicker.css';

interface DateInputProps {
  value?: string;
  onClick?: () => void;
}

// This is our custom input field component
const DateInput = forwardRef<HTMLInputElement, DateInputProps>(({ value, onClick }, ref) => (
  <div className="date-input" onClick={onClick}>
    <OpsTypography variant={'p1'} elementTag={'span'} children={`${value}`} />
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M13.25 2H11.75V1.25C11.75 1.05109 11.671 0.860322 11.5303 0.71967C11.3897 0.579018 11.1989 0.5 11 0.5C10.8011 0.5 10.6103 0.579018 10.4697 0.71967C10.329 0.860322 10.25 1.05109 10.25 1.25V2H5.75V1.25C5.75 1.05109 5.67098 0.860322 5.53033 0.71967C5.38968 0.579018 5.19891 0.5 5 0.5C4.80109 0.5 4.61032 0.579018 4.46967 0.71967C4.32902 0.860322 4.25 1.05109 4.25 1.25V2H2.75C2.15326 2 1.58097 2.23705 1.15901 2.65901C0.737053 3.08097 0.5 3.65326 0.5 4.25V13.25C0.5 13.8467 0.737053 14.419 1.15901 14.841C1.58097 15.2629 2.15326 15.5 2.75 15.5H13.25C13.8467 15.5 14.419 15.2629 14.841 14.841C15.2629 14.419 15.5 13.8467 15.5 13.25V4.25C15.5 3.65326 15.2629 3.08097 14.841 2.65901C14.419 2.23705 13.8467 2 13.25 2ZM14 13.25C14 13.4489 13.921 13.6397 13.7803 13.7803C13.6397 13.921 13.4489 14 13.25 14H2.75C2.55109 14 2.36032 13.921 2.21967 13.7803C2.07902 13.6397 2 13.4489 2 13.25V8H14V13.25ZM14 6.5H2V4.25C2 4.05109 2.07902 3.86032 2.21967 3.71967C2.36032 3.57902 2.55109 3.5 2.75 3.5H4.25V4.25C4.25 4.44891 4.32902 4.63968 4.46967 4.78033C4.61032 4.92098 4.80109 5 5 5C5.19891 5 5.38968 4.92098 5.53033 4.78033C5.67098 4.63968 5.75 4.44891 5.75 4.25V3.5H10.25V4.25C10.25 4.44891 10.329 4.63968 10.4697 4.78033C10.6103 4.92098 10.8011 5 11 5C11.1989 5 11.3897 4.92098 11.5303 4.78033C11.671 4.63968 11.75 4.44891 11.75 4.25V3.5H13.25C13.4489 3.5 13.6397 3.57902 13.7803 3.71967C13.921 3.86032 14 4.05109 14 4.25V6.5Z"
        fill="#9C9C9C"
      />
    </svg>
  </div>
));

interface DatePickerProps {
  dateString?: string;
  onChange: (date: string) => void;
}

const DatePickerComponent: FC<DatePickerProps> = ({ dateString = '', onChange }) => {
  const initialDate = dateString ? new Date(dateString) : new Date();
  const [selectedDate, setSelectedDate] = useState(initialDate);

  const formatDate = (date: Date) =>
    date.toLocaleDateString('en-US', {
      year: 'numeric',
      month: 'long',
      day: 'numeric',
    });

  useEffect(() => {
    if (dateString) {
      setSelectedDate(new Date(dateString));
    }
  }, [dateString]);

  useEffect(() => {
    onChange(formatDate(initialDate));
  }, []);

  const handleDateChange = (date: Date | null) => {
    if (date) {
      setSelectedDate(date);
      onChange(formatDate(date));
    }
  };

  return (
    <DatePicker
      customInput={<DateInput />}
      selected={selectedDate}
      onChange={handleDateChange}
      popperPlacement="top-end"
      popperModifiers={[
        {
          name: 'flip',
          options: {
            fallbackPlacements: ['top'],
          },
        },
        {
          name: 'preventOverflow',
          options: {
            rootBoundary: 'viewport',
            tether: false,
            altAxis: true,
          },
        },
      ]}
    />
  );
};

export default DatePickerComponent;
