import React, { useMemo, useState, useRef, useEffect } from 'react';
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
import { ColDef } from 'ag-grid-community';
import { workDayRedirect, profileMatamo } from './commonMethods';
import ExternalLinkLogo from '../assets/icons/external-link';
import Profile from '../assets/images/Profile.png';
import {Modal} from '@opsdti-global-component-library/amgen-design-system';

interface AgGridTableProps {
  rowData: any[];
  columnDefs: any[];
  matamoType: string;
  matamoDate: string;
  triggerdownload?: boolean;
  setTriggerDownload?: (val: boolean) => void;
  tableName?: string;
  expandable?: boolean;
  onExpandableClosed?: () => void;
}

const AgGridTable: React.FC<AgGridTableProps> = ({ rowData, columnDefs, matamoDate, matamoType, triggerdownload, setTriggerDownload, tableName, expandable, onExpandableClosed }) => {
  const gridRef = useRef<AgGridReact>(null);
  const [showExpandOrClose, setShowExpandOrClose] = useState(true);

  useEffect(() => {
    if (triggerdownload) {
      gridRef.current?.api?.exportDataAsCsv({
        fileName: `${tableName === '' ? 'export' : tableName}-${matamoDate}.csv`,
        allColumns: true,
      });

      if (setTriggerDownload) setTriggerDownload(false);
    }
  }, [triggerdownload]);

  const defaultColDef: any = useMemo<ColDef>(() => {
    return {
      flex: 1,
      wrapHeaderText: true,
      autoHeaderHeight: true,
      sortable: true,
      wrapText: false,
      filter: true,
      filterParams: { maxNumConditions: 1 },
      resizable: true,
      rowSpan: (params: any) => {
        return params.data.rowSpan;
      },
      cellRenderer: (params: any) => {
        if (params.colDef.field === 'userid') {
          return (
            <span className="faux-link" onClick={() => workDayRedirect(params.data.profiledeeplink)}>
              {params.value}
              <span className="external-link">
                <ExternalLinkLogo />
              </span>
            </span>
          );
        } else if (params.colDef.field === 'fullname') {
          return (
            <span className="faux-link" onClick={() => profileMatamo(params.data.visitorid, matamoType, matamoDate)}>
              {params.value}
              <span className="external-link">
                <img src={Profile} alt="profile-link" className="profile" />
              </span>
            </span>
          );
        } else {
          return params.value;
        }
      },
    };
  }, []);

  return (
    <div>
    { expandable ? 
     <Modal open={expandable} onCancel={onExpandableClosed} title={tableName}  width={1500}>
     <div className="ag-theme-alpine" style={{ height: 650, width: '100%' }}>
      <AgGridReact
        ref={gridRef}
        rowData={rowData}
        columnDefs={columnDefs}
        sortingOrder={['asc', 'desc']}
        defaultColDef={defaultColDef}
        suppressExcelExport={true}
      />
    </div>
   </Modal>
  
    : <div className="ag-theme-alpine" style={{ height: 350, width: '100%' }}>
      <AgGridReact
        ref={gridRef}
        rowData={rowData}
        columnDefs={columnDefs}
        sortingOrder={['asc', 'desc']}
        defaultColDef={defaultColDef}
        suppressExcelExport={true}
      />
    </div>
  }
  </div>
  );
};

export default AgGridTable;
