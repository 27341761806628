import React from 'react';
import { useReleaseNotesContext } from '../../../contexts/release-note-context';
import './index.scss';
import { OpsTypography } from '@gitlab-rtsensing/component-library';

interface StatusTagInterface {
  status: string;
  teams?: string;
  className?: string | null;
}

export default function StatusTag({ status, className = null }: StatusTagInterface) {
  return (
    <div className={`${className ? className : ''} ${status} status-tag`}>
      <OpsTypography variant={'label'} variantWeight={700} elementTag={'span'} children={status.toUpperCase()} />
    </div>
  );
}
