import { Modal } from '@gitlab-rtsensing/component-library';
import { useEffect, useState } from 'react';
import ReleaseVersionForm from '../release-version-form';
import { FormTitle, IReleaseVersion, IReleaseVersionInitialFormData } from '../release-note-interfaces';
import './index.scss';

interface ReleaseVersionModalProps {
  title?: string;
  opened: boolean;
  setModalOpened: Function;
  initialFormData: IReleaseVersionInitialFormData;
  fetchReleaseVersions?: () => Promise<void>;
  currentReleaseVersions?: IReleaseVersion[];
}

export default function ReleaseVersionModal({ opened, setModalOpened, initialFormData }: ReleaseVersionModalProps): JSX.Element {
  const [modalTitle, setModalTitle] = useState<FormTitle>(!!initialFormData ? 'Edit Release Version' : 'Create Release Version');

  useEffect(() => {
    setModalTitle(!!initialFormData ? 'Edit Release Version' : 'Create Release Version');
  }, [opened]);

  return (
    <Modal className="release-version-modal" opened={opened} onClose={() => setModalOpened(false)}>
      <Modal.Header contentClassName="d-flex align-items-center" className="release-version-modal__header">
        <Modal.Title className="release-version-modal__title" title={modalTitle} />
      </Modal.Header>
      <>
        {opened && (
          <div className="release-version-modal__wrapper">
            <ReleaseVersionForm
              onReleaseVersionModalClosed={() => setModalOpened(false)}
              closeModal={() => setModalOpened(false)}
              initialFormData={initialFormData}
            />
          </div>
        )}
      </>
    </Modal>
  );
}
