import { useContext, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import UserAuditLogo from '../../assets/icons/user-audit';
import UsageMetricLogo from '../../assets/icons/usage-metric';
import AccessRequestLogo from '../../assets/icons/access-request';
import { AppContext } from '../../contexts/app-context';
import { useNavigate, useLocation } from 'react-router-dom';
import ReleaseNotesLogo from '../../assets/icons/release-note';
import AccessLogIcon from '../../assets/icons/access-logs';
import MonitoringETL from '../../assets/icons/monitoring-etl';
import { apiResponse } from '../../utility/commonMethods';
import inventoryIcon from '../../assets/images/inventoryIcon.png';
import jobMonitorIcon from '../../assets/images/jobMonitorIcon.png';
import configUpdateIcon from '../../assets/images/config-update-icon.png';
import hamburgerAssetIcon from '../../assets/icons/hamburger-icon.svg';
import { ChangeIcon, ChartLineIcon, CommentIcon } from '@opsdti-global-component-library/amgen-design-system';

interface NavTabInterface {
  id: string;
  label: string;
  route: string;
  icon: JSX.Element;
}

const PageLayout = (props: any) => {
  const hamburgerIcon = process.env.REACT_APP_URL + hamburgerAssetIcon;
  //const hamburgerIcon = 'http://localhost:3000' + hamburgerAssetIcon;
  const [highlightedMenu, setHighlightedMenu] = useState<string>('Usage Metric');
  const [isMenuExpanded, setIsMenuExpanded] = useState<boolean>(false);
  const { authData, constInfo, setConstInfo } = useContext(AppContext);
  const navigate = useNavigate();
  const location = useLocation();
  const tagPages = [
    '/admin',
    '/admin/usage-metric',
    '/admin/user-audit',
    '/admin/sensing-access-request',
    '/admin/access-logs',
    '/admin/release-notes',
    '/admin/etl-monitoring',
    // '/admin/data-elements-inventory',
    '/admin/sensing-constants',
  ];

  const navTabObj: NavTabInterface[] = [
    {
      id: 'usageMetric',
      label: 'Usage Metric',
      route: '/admin/usage-metric',
      icon: <UsageMetricLogo fill={highlightedMenu === 'Usage Metric' ? '#0063C3' : '#555555'} />,
    },
    {
      id: 'userAudit',
      label: 'User Audit',
      route: '/admin/user-audit',
      icon: <UserAuditLogo fill={highlightedMenu === 'User Audit' ? '#0063C3' : '#555555'} />,
    },
    {
      id: 'userAuditView',
      label: 'User Audit',
      route: '/admin/user-audit',
      icon: <UserAuditLogo fill={highlightedMenu === 'User Audit' ? '#0063C3' : '#555555'} />,
    },
    {
      id: 'sensingAccessRequest',
      label: 'Access Request',
      route: '/admin/sensing-access-request',
      icon: <AccessRequestLogo fill={highlightedMenu === 'Access Request' ? '#0063C3' : '#555555'} />,
    },
    {
      id: 'accessLog',
      label: 'Access Logs',
      route: '/admin/access-logs',
      icon: <AccessLogIcon fill={highlightedMenu === 'Access Logs' ? '#0063C3' : '#555555'} />,
    },
    {
      id: 'releaseNotes',
      label: 'Release Notes',
      route: '/admin/release-notes',
      icon: <ReleaseNotesLogo sizeInPx={18} fill={highlightedMenu === 'Release Notes' ? 'var(--ops-primary-blue)' : 'var(--ops-gray-600)'} />,
    },
    {
      id: 'etlMonitoring',
      label: 'Jobs Monitoring',
      route: '/admin/etl-monitoring',
      icon: <img src={jobMonitorIcon} height={'20px'} width={'22px'}></img>,
    },
    /* {
      id: 'dataElementsInventory',
      label: 'Data Elements Inventory',
      route: '/admin/data-elements-inventory',
      icon: <img src={inventoryIcon} height={'18px'} width={'18px'}></img>,
    }, */
    {
      id: 'sensingConstants',
      label: 'Sensing Constants',
      route: '/admin/sensing-constants',
      icon: <img src={configUpdateIcon} height={'18px'} width={'18px'}></img>,
    },
    {
      id: 'brandRefresh',
      label: 'Refresh Brand',
      route: '/admin/refresh-brand',
      icon: <img src={configUpdateIcon} height={'18px'} width={'18px'}></img>,
    },
    {
      id: 'gptFeedback',
      label: 'GPT Feedback',
      route: '/admin/gpt-feedback',
      icon: <ChartLineIcon width={18} height={18} />,
    },
    {
      id: 'peopleVpMapping',
      label: 'VP Mapping',
      route: '/admin/vp-mapping',
      icon: <ChangeIcon width={18} height={18} />,
    }
  ];

  const [navTab, setNavTabs] = useState<NavTabInterface[]>(navTabObj);

  const handleMobileMenu = () => {
    setIsMenuExpanded(!isMenuExpanded);
  };

  const getMetricNames = async () => {
    const payload = {
      work_stream: 'admin',
    };
    try {
      const res = await apiResponse('post', 'get-usage-metric-names', [], payload);
      if (res?.data?.status === 'SUCCESS') {
        if (res.data.data.length === 0) {
          console.log('Const API Data Not available');
        } else {
          let constMap = new Map(res.data.data.map((obj: { config_key: string; config_value: string }) => [obj.config_key, obj.config_value]));
          setConstInfo(constMap);
        }
      } else {
        console.log('Const API Failed');
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (constInfo.size === 0) getMetricNames();
  }, []);

  useEffect(() => {
    const tagPage = tagPages.filter(page => {
      if (window.location.pathname.includes(page)) {
        return true;
      }
      return false;
    });

    navTab.forEach(router => {
      if (router && router.route) {
        let currentPath = window.location.pathname;
        if (tagPage.length > 0) {
          currentPath = window.location.pathname;
        }
        if (currentPath.includes(router.route)) {
          setHighlightedMenu(router.label);
        }
      }
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [window.location.pathname]);

  /*   useEffect(() => {
    setNavTabs(navTabObj);
  }, [highlightedMenu]); */

  useEffect(() => {
    if (authData !== null) {
      let arr = navTab.filter(obj => authData.adminAuthorization[obj.id] === true);
      let fullTagPages = tagPages.map(obj => window.location.origin + obj);
      let fullFilteredPages = arr.map(obj => window.location.origin + obj.route);

      setNavTabs(() => {
        let removeDuplicate: any = []; //Incase audit & auditView both are true, don't show user Audit twice
        arr = arr.filter(obj => {
          if (!removeDuplicate.includes(obj.label)) {
            removeDuplicate.push(obj.label);
            return true;
          }
          return false;
        });
        return arr;
      });

      //To remove links from side nav, if its not authorized
      document.querySelectorAll('.nav-sub-section-link[href]').forEach(el => {
        let path = (el as HTMLAnchorElement).href;
        //Should be in all Pages list but not in filtered pages list
        if (fullTagPages.includes(path) && !fullFilteredPages.includes(path)) {
          (el as HTMLAnchorElement).remove();
        }
      });

      if (arr.length === 0) {
        navigate('/unauthorized'); //Never hitting here
      } else if (arr.map(obj => obj.route).indexOf(location.pathname) === -1) {
        navigate(arr[0].route);
      }
      document.getElementById('nav-bar')?.classList.remove('hidden');
    }
  }, [authData, highlightedMenu]);

  return (
    <>
      {
        <div className="container-fluid px-0 hidden" id="nav-bar">
          <nav className="navbar navbar-expand-lg navbar-light">
            <div className="container-fluid px-0">
              <button
                className="navbar-toggler ms-auto"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#navbarNav"
                aria-controls="navbarNav"
                aria-expanded={isMenuExpanded}
                aria-label="Toggle navigation"
                onClick={() => handleMobileMenu()}
              >
                <span className="navbar-toggler-icon" style={{ backgroundImage: `url(${hamburgerIcon})` }}></span>
              </button>
              <div className={`collapse navbar-collapse ${isMenuExpanded ? 'show' : ''}`} id="navbarNav">
                <ul className="navbar-nav mt-3 border-bottom admin-section-name">
                  {/* eslint-disable-next-line array-callback-return */}
                  {navTab.map((element, index) => {
                    if (element)
                      return (
                        <li
                          key={index}
                          onClick={() => {
                            setIsMenuExpanded(false);
                          }}
                          className="nav-item"
                        >
                          <Link
                            onClick={() => {
                              setHighlightedMenu(element.label);
                              if ('_paq' in window && window._paq !== undefined && window._paq !== null) {
                                let matamoWindow: any = window;
                                let _paq = (matamoWindow._paq = matamoWindow._paq || []);
                                console.log('element.label', element.label);
                                //_paq.push(['trackPageView', 'Tab-' + element.label]);
                                //_paq.push(['trackEvent', 'Tab Navigation', 'Tab Name click', 'Tab-' + element.label]);
                              }
                            }}
                            to={element.route}
                            className={highlightedMenu === element.label ? 'nav-link active px-0' : 'nav-link px-0'}
                          >
                            {element.icon}
                            <span className="menu-text">{element.label}</span>
                          </Link>
                        </li>
                      );
                  })}
                </ul>
              </div>
            </div>
          </nav>

          <div>{props.children}</div>
          {/*  <div className="footer border-top mt-1 mx-4 py-3">
            <div className="d-flex gap-4"></div>
          </div> */}
        </div>
      }
    </>
  );
};

export default PageLayout;
