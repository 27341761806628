import React from 'react';
import './revert-bootstrap.scss';
import './admin.scss';
import AdminRoutes from './components/admin-routes';
import { BrowserRouter } from 'react-router-dom';
import { ThemeProvider, Button } from '@opsdti-global-component-library/amgen-design-system';
import packageJson from '../package.json';

type Props = {
  themeService: unknown;
};

const Admin: React.FC = props => {
  console.log('"@opsdti-global-component-library/amgen-design-system"', packageJson?.dependencies['@opsdti-global-component-library/amgen-design-system']);
  return (
    <div className="admin-sensing-wrapper">
      <div className="admin-sensing-container">
        <ThemeProvider themeService={null}>
          <BrowserRouter>
            <AdminRoutes />
          </BrowserRouter>
        </ThemeProvider>
      </div>
    </div>
  );
};

export default Admin;
