const rowSpan = (params: any) => {
  return params.data.row_span_count;
};

export const Metric_Header = [
  {
    field: 'metric_name',
    headerName: 'Metric Name',
    rowSpan: rowSpan,
    cellClassRules: {
      'cell-span': 'value !== undefined',
    },
    minWidth: 220,
    tooltipField: 'metric_name',
    cellStyle: { fontWeight: 600 },
  },
  {
    field: 'metric_definition',
    headerName: 'Metric Definition',
    rowSpan: rowSpan,
    cellClassRules: {
      'cell-span': 'value !== undefined',
    },
    minWidth: 300,
    tooltipField: 'metric_definition',
  },
  {
    field: 'metric_calculation',
    headerName: 'Metric Calculation',
    rowSpan: rowSpan,
    cellClassRules: {
      'cell-span': 'value !== undefined',
    },
    minWidth: 300,
    tooltipField: 'metric_calculation',
  },
  {
    field: 'dashboard_component',
    headerName: 'Dashboard Component',
    rowSpan: rowSpan,
    cellClassRules: {
      'cell-span': 'value !== undefined',
    },
    tooltipField: 'dashboard_component',
  },
  {
    headerName: 'Dashboard Subcomponent',
    field: 'dashboard_subcomponent',
  },
  // {
  //   headerName: 'Metric Workstream Name',
  //   field: 'metric_work_stream_name',
  // },
  {
    field: 'refresh_cadence',
    headerName: 'Refresh Cadence',
    rowSpan: rowSpan,
    cellClassRules: {
      'cell-span': 'value !== undefined',
    },
    tooltipField: 'refresh_cadence',
  },
  {
    field: 'data_business_owner',
    headerName: 'Data Business Owner',
    tooltipField: 'data_business_owner',
  },
  {
    field: 'business_attribute_name',
    headerName: 'Business Attribute Name',
    tooltipField: 'business_attribute_name',
  },
  {
    field: 'attribute_description',
    headerName: 'Attribute Description',
    tooltipField: 'attribute_description',
  },
  // {
  //   headerName: 'Attribute Workstream Name',
  //   field: 'attribute_work_stream_name',
  // },

  {
    headerName: 'Data Security',
    field: 'data_security',
  },
  {
    field: 'source_name',
    headerName: 'Source Name',
    tooltipField: 'source_name',
  },
  {
    headerName: 'Source Description',
    field: 'source_description',
    tooltipField: 'source_description',
    minWidth: 500,
  },
];

export const Business_Attribute_Header = [
  {
    field: 'business_attribute_name',
    headerName: 'Business Attribute Name',
    rowSpan: rowSpan,
    cellClassRules: {
      'cell-span': 'value !== undefined',
    },
    tooltipField: 'business_attribute_name',
    cellStyle: { fontWeight: 600 },
  },
  {
    field: 'attribute_description',
    headerName: 'Attribute Description',
    tooltipField: 'attribute_description',
    rowSpan: rowSpan,
    cellClassRules: {
      'cell-span': 'value !== undefined',
    },
  },
  // {
  //   headerName: 'Attribute Workstream Name',
  //   field: 'attribute_work_stream_name',
  // },
  {
    field: 'data_business_owner',
    headerName: 'Data Business Owner',
    rowSpan: rowSpan,
    cellClassRules: {
      'cell-span': 'value !== undefined',
    },
    tooltipField: 'data_business_owner',
  },
  {
    field: 'refresh_cadence',
    headerName: 'Refresh Cadence',
    rowSpan: rowSpan,
    cellClassRules: {
      'cell-span': 'value !== undefined',
    },
    tooltipField: 'refresh_cadence',
  },
  {
    field: 'metric_name',
    headerName: 'Metric Name',
    tooltipField: 'metric_name',
  },
  {
    field: 'metric_definition',
    headerName: 'Metric Definition',
    minWidth: 400,
    tooltipField: 'metric_definition',
  },
  {
    field: 'metric_calculation',
    headerName: 'Metric Calculation',
    tooltipField: 'metric_calculation',
  },
  {
    field: 'dashboard_component',
    headerName: 'Dashboard Component',
    tooltipField: 'dashboard_component',
  },
  {
    headerName: 'Dashboard Subcomponent',
    field: 'dashboard_subcomponent',
  },
  {
    headerName: 'Data Security',
    field: 'data_security',
  },
  {
    field: 'source_name',
    headerName: 'Source Name',
    tooltipField: 'source_name',
  },
  {
    headerName: 'Source Description',
    field: 'source_description',
    tooltipField: 'source_description',
    minWidth: 500,
  },
  // {
  //   headerName: 'Metric Workstream Name',
  //   field: 'metric_work_stream_name',
  // },
];
