import { Modal, OpsButton, OpsTypography } from '@gitlab-rtsensing/component-library';
import AmgenNetworks from '../../assets/icons/amgen-networks';
import Logo from '../../assets/images/Amgen-Sensing.png';
import './index.scss';

interface ConfirmOverlayPropsInterface {
  onClose: () => void;
  onConfirm: () => void;
  isOpen: boolean;
  title?: string;
  description?: string;
  confirmBtnLabel?: string;
}

export default function ConfirmOverlay({ isOpen, onClose, title, description, confirmBtnLabel, onConfirm }: ConfirmOverlayPropsInterface): JSX.Element {
  return (
    <>
      {isOpen && (
        <div className="confirmation-overlay">
          <div className="confirmation-overlay__wrapper">
            <OpsTypography variant="h3" elementTag="p" children={title || ''} variantWeight={700} />
            <OpsTypography variant="p1" elementTag="p" children={description || ''} />
            <div className="confirmation-overlay__footer">
              <OpsButton className="confirmation-overlay__cancel-button" onClick={onClose} label="Cancel" type="secondary" />
              <OpsButton className="confirmation-overlay__confirm-button" onClick={onConfirm} label={confirmBtnLabel || ''} type="primary" />
            </div>
          </div>
        </div>
      )}
    </>
  );
}
