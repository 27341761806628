export const usageTrackingHeader = [
  {
    headerName: 'User Name',
    field: 'fullname',
  },
  {
    headerName: 'User ID',
    field: 'userid',
  },
  {
    headerName: 'Position',
    field: 'position',
  },
  {
    headerName: 'Function',
    field: 'function',
  },
  {
    headerName: 'Visits',
    field: 'visitcount',
  },
  {
    headerName: 'First Visit',
    field: 'first_visit_date',
  },
  {
    headerName: 'Last Visit',
    field: 'last_visit_date',
  },
];
