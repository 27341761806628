export const UserAccess_Table_Header = [
  {
    Header: 'User Name',
    accessor: 'fullname',
  },
  {
    Header: 'User ID',
    accessor: 'loginname',
  },
  {
    Header: 'Status',
    accessor: 'employee_status',
  },
  {
    Header: 'Position',
    accessor: 'position',
  },
  {
    Header: 'Function',
    accessor: 'function',
  },
  {
    Header: 'GCF Level',
    accessor: 'gcf_level',
  },
  {
    Header: 'Email',
    accessor: 'email',
  },
  {
    Header: 'Immediate Supervisor',
    accessor: 'supervisor_loginname',
  },
  {
    Header: 'First Visit',
    accessor: 'firstvisitdate',
  },
  {
    Header: 'Last Visit',
    accessor: 'lastvisitdate',
  },
  {
    Header: 'Action',
    accessor: 'action',
  },
];
