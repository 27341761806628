import { useState } from 'react';
import { IReleaseVersion } from '../release-note-interfaces';
import { DateRange, getQuarterDateRanges } from '../utils';
import { MultiSelect } from 'react-multi-select-component';
import './index.scss';

interface QuarterDropdownProps {
  releaseVersions: IReleaseVersion[];
  onSelectedItemsChange: (selectedItems: DateRange[]) => void;
}

const QuarterDropdown: React.FC<QuarterDropdownProps> = ({ releaseVersions, onSelectedItemsChange }) => {
  const [selectedItems, setSelectedItems] = useState<{ label: string; value: string }[]>([]);

  const quarterDateRanges = getQuarterDateRanges(releaseVersions);

  const options = quarterDateRanges
    .map(qdr => ({
      label: qdr.quarterYear,
      value: JSON.stringify(qdr.dateRange),
    }))
    .reverse();

  const handleSelectedItemsChange = (newSelectedItems: { label: string; value: string }[]) => {
    setSelectedItems(newSelectedItems);
    onSelectedItemsChange(newSelectedItems.map(item => JSON.parse(item.value) as DateRange));
  };

  const valueRenderer = (selected: typeof options) => {
    if (!selected.length) {
      return 'Filter by Quarter';
    }
  };

  return (
    <div>
      <MultiSelect
        className="ops-fs-5 quarter-dropdown"
        options={options}
        value={selectedItems}
        valueRenderer={valueRenderer}
        onChange={handleSelectedItemsChange}
        labelledBy="Filter by Quarters"
      />
    </div>
  );
};

export default QuarterDropdown;
