const Chrome = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48" width="68px" height="68px">
      <path fill="#fff" d="M34,24c0,5.521-4.479,10-10,10s-10-4.479-10-10s4.479-10,10-10S34,18.479,34,24z" />
      <linearGradient
        id="Pax8JcnMzivu8f~SZ~k1ya"
        x1="5.789"
        x2="31.324"
        y1="34.356"
        y2="20.779"
        gradientTransform="matrix(1 0 0 -1 0 50)"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0" stopColor="#4caf50" />
        <stop offset=".489" stopColor="#4aaf50" />
        <stop offset=".665" stopColor="#43ad50" />
        <stop offset=".79" stopColor="#38aa50" />
        <stop offset=".892" stopColor="#27a550" />
        <stop offset=".978" stopColor="#11a050" />
        <stop offset="1" stopColor="#0a9e50" />
      </linearGradient>
      <path
        fill="url(#Pax8JcnMzivu8f~SZ~k1ya)"
        d="M31.33,29.21l-8.16,14.77C12.51,43.55,4,34.76,4,24C4,12.96,12.96,4,24,4v11 c-4.97,0-9,4.03-9,9s4.03,9,9,9C27.03,33,29.7,31.51,31.33,29.21z"
      />
      <linearGradient
        id="Pax8JcnMzivu8f~SZ~k1yb"
        x1="33.58"
        x2="33.58"
        y1="6"
        y2="34.797"
        gradientTransform="matrix(1 0 0 -1 0 50)"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0" stopColor="#ffd747" />
        <stop offset=".482" stopColor="#ffd645" />
        <stop offset=".655" stopColor="#fed43e" />
        <stop offset=".779" stopColor="#fccf33" />
        <stop offset=".879" stopColor="#fac922" />
        <stop offset=".964" stopColor="#f7c10c" />
        <stop offset="1" stopColor="#f5bc00" />
      </linearGradient>
      <path
        fill="url(#Pax8JcnMzivu8f~SZ~k1yb)"
        d="M44,24c0,11.05-8.95,20-20,20h-0.84l8.17-14.79C32.38,27.74,33,25.94,33,24 c0-4.97-4.03-9-9-9V4c7.81,0,14.55,4.48,17.85,11C43.21,17.71,44,20.76,44,24z"
      />
      <linearGradient
        id="Pax8JcnMzivu8f~SZ~k1yc"
        x1="36.128"
        x2="11.574"
        y1="44.297"
        y2="28.954"
        gradientTransform="matrix(1 0 0 -1 0 50)"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0" stopColor="#f7572f" />
        <stop offset=".523" stopColor="#f7552d" />
        <stop offset=".712" stopColor="#f75026" />
        <stop offset=".846" stopColor="#f7461b" />
        <stop offset=".954" stopColor="#f7390a" />
        <stop offset="1" stopColor="#f73100" />
      </linearGradient>
      <path
        fill="url(#Pax8JcnMzivu8f~SZ~k1yc)"
        d="M41.84,15H24c-4.97,0-9,4.03-9,9c0,1.49,0.36,2.89,1.01,4.13H16L7.16,13.26H7.14 C10.68,7.69,16.91,4,24,4C31.8,4,38.55,8.48,41.84,15z"
      />
      <linearGradient
        id="Pax8JcnMzivu8f~SZ~k1yd"
        x1="19.05"
        x2="28.95"
        y1="30.95"
        y2="21.05"
        gradientTransform="matrix(1 0 0 -1 0 50)"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0" stopColor="#2aa4f4" />
        <stop offset="1" stopColor="#007ad9" />
      </linearGradient>
      <path fill="url(#Pax8JcnMzivu8f~SZ~k1yd)" d="M31,24c0,3.867-3.133,7-7,7s-7-3.133-7-7s3.133-7,7-7S31,20.133,31,24z" />
    </svg>
  );
};
export default Chrome;
