export const timeToHrMinSecond = (val: number) => {
  let remainder = val % 60;
  if (val < 60) {
    return val + 's';
  } else {
    let min: number = Number(val - remainder);
    if (Number(min / 60) >= 60) {
      min = min / 60;
      let hrRemainder = Number(min) - (min % 60);
      return hrRemainder / 60 + 'h ' + Number(min % 60) + 'm ' + remainder + 's';
    } else {
      return Number(min / 60) + 'm ' + remainder + 's';
    }
  }
};
