interface Props {
  fill: string;
  classname?: string;
}

const UsageMetricLogo = ({ fill, classname }: Props) => {
  return (
    <svg width="18" height="18" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M4.5 8.2V10.6M9.5 7V10.6M7 4.6V10.6M12 3.88V10.12C12 11.1281 12 11.6321 11.7956 12.0172C11.6159 12.3559 11.329 12.6312 10.9762 12.8038C10.5751 13 10.0501 13 9 13H5C3.9499 13 3.42485 13 3.02377 12.8038C2.67096 12.6312 2.38413 12.3559 2.20436 12.0172C2 11.6321 2 11.1281 2 10.12V3.88C2 2.87191 2 2.36786 2.20436 1.98282C2.38413 1.64413 2.67096 1.36876 3.02377 1.19619C3.42485 1 3.9499 1 5 1H9C10.0501 1 10.5751 1 10.9762 1.19619C11.329 1.36876 11.6159 1.64413 11.7956 1.98282C12 2.36786 12 2.87191 12 3.88Z"
        stroke={fill}
        strokeWidth="0.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default UsageMetricLogo;
