import { Modal } from '@opsdti-global-component-library/amgen-design-system';
import SearchByUser from '../search-by-user';
import AmgenNetworks from '../../../assets/icons/amgen-networks';
import Logo from '../../../assets/images/Amgen-Sensing.png';

interface SearchModalProps {
  opened: boolean;
  searchModalClosed: () => void;
  userID?: string;
}

export default function SearchModal(props: SearchModalProps): JSX.Element {
  const { opened, searchModalClosed, userID } = props;
  return (
    <Modal
      className="search-user-access-modal"
      open={opened}
      onCancel={searchModalClosed}
      maskCloseable={false}
      title={
        <div className="search-user-modal-header d-flex align-items-center">
          <AmgenNetworks fill="#000" />
          <img src={Logo} alt="amgen logo"></img>
          <p className="search-user-modal-title">Search By User</p>
        </div>
      }
      width={1200}
      height={500}
    >
      <SearchByUser searchModalClosed={searchModalClosed} userID={userID} />
    </Modal>
  );
}
