import { Modal } from '@gitlab-rtsensing/component-library';
import { useEffect, useState } from 'react';
import ReleaseNotesForm from '../release-notes-form';
import { FormTitle } from '../release-note-interfaces';
import './index.scss';
import { useReleaseNotesContext } from '../../../contexts/release-note-context';

interface ReleaseNotesModalProps {
  opened: boolean;
  setModalOpened: Function;
}

export default function ReleaseNotesModal({ opened, setModalOpened }: ReleaseNotesModalProps): JSX.Element {
  const { releaseNoteInitialFormData } = useReleaseNotesContext();
  const [modalTitle, setModalTitle] = useState<FormTitle>(!!releaseNoteInitialFormData ? 'Edit Release Notes' : 'Add Release Notes');

  const modalClose = () => {
    setModalOpened(false);
  };
  useEffect(() => {
    setModalTitle(!!releaseNoteInitialFormData ? 'Edit Release Notes' : 'Add Release Notes');
  }, [opened]);

  return (
    <Modal className="release-notes-modal" opened={opened} onClose={modalClose}>
      <Modal.Header contentClassName="d-flex align-items-center" className="release-notes-modal__header">
        <Modal.Title className="release-notes-modal__title" title={modalTitle} />
      </Modal.Header>
      <>
        {opened && (
          <div className="release-notes-form__wrapper">
            <ReleaseNotesForm onReleaseNotesModalClosed={modalClose} closeModal={modalClose} handleAddToEdit={(title: FormTitle) => setModalTitle(title)} />
          </div>
        )}
      </>
    </Modal>
  );
}
