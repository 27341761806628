import React, { useCallback, useRef, useState } from 'react';

interface ConfirmDialogState {
  isOpen: boolean;
  title?: string;
  description?: string;
  confirmBtnLabel?: string;
}

type ConfirmFunction = (data: Partial<ConfirmDialogState>) => Promise<boolean>;

interface IConfirmDialogueContextState {
  confirm: ConfirmFunction;
  confirmState: ConfirmDialogState;
  confirmFn: React.MutableRefObject<((choice: boolean) => void) | undefined>;
}

export default function useConfirm(): IConfirmDialogueContextState {
  const [state, setState] = useState<ConfirmDialogState>({ isOpen: false });
  const fn = useRef<(choice: boolean) => void>();

  const confirm: ConfirmFunction = useCallback(
    data => {
      return new Promise(resolve => {
        setState(prevState => ({ ...prevState, ...data, isOpen: true }));
        fn.current = choice => {
          resolve(choice);
          setState(prevState => ({ ...prevState, isOpen: false }));
        };
      });
    },
    [setState],
  );

  return {
    confirmState: state,
    confirm,
    confirmFn: fn,
  };
}
