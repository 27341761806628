import { useState, useEffect, useContext } from 'react';
import 'react-date-range/dist/styles.css'; // main css file
import 'react-date-range/dist/theme/default.css'; // theme css file
import './index.scss';
import { apiResponse } from '../../../utility/commonMethods';
import { toast } from 'react-toastify';
import { Loader, LineChartComponent, Button } from '@gitlab-rtsensing/component-library';
import NewsLetterModal from '../newsletter-modal';
import { AppContext } from '../../../contexts/app-context';

interface NewsLetterType {
  news_letters: string;
  total_views: number;
}
const NewsLetterChart = () => {
  const [isLoading, setLoading] = useState(true);
  const [getNewsLetrDetails, setNewsLetrDetails] = useState<NewsLetterType[]>([]);
  const [newsLetterModal, setNewsLetterModal] = useState(false);
  const { authData } = useContext(AppContext);
  const [chartHeight, setChartHeight] = useState(350);

  useEffect(() => {
    if (getNewsLetrDetails.length === 0) {
      getUsageTrackingData();
    }
  }, [getNewsLetrDetails]);

  const getUsageTrackingData = async () => {
    setLoading(true);

    const payload = {};
    try {
      const res = await apiResponse('get', 'get-newsletter', [], payload);
      if (res?.data?.status === 'SUCCESS') {
        if (res?.data?.data) {
          if (res.data.data.length > 0) {
            res.data.data.sort((a: { news_letters: string }, b: { news_letters: string }) => {
              let aYr = a.news_letters.split(' ');
              let bYr = b.news_letters.split(' ');
              if (aYr.length === bYr.length && aYr.length === 2) {
                if (Number(aYr[1]) > Number(bYr[1])) {
                  return 1;
                } else if (Number(aYr[1]) < Number(bYr[1])) {
                  return -1;
                } else {
                  return 0;
                }
              }
            });
            let chartHeight = document?.querySelector('#newsletter-chart')?.clientHeight || 350;
            setChartHeight(chartHeight - 100);

            setNewsLetrDetails(res.data.data.map((data: { total_views: string }) => Object.assign(data, { total_views: Number(data.total_views) })));
          } else {
            toast.error('NewsLetter Data not available!!');
          }
        }
      } else {
        console.log(res);
        toast.error('Failed to load!!');
      }
    } catch (error) {
      console.log(error);
      toast.error('Something Went Wrong!!');
    }
    setLoading(false);
  };

  const CustomTooltip = (count: unknown, payload: string | number) => {
    if (count && payload) {
      return (
        <div className="custom-tooltip">
          <p className="label">{`${String(payload).replaceAll('_', ' ').toUpperCase()} : ${count}`}</p>
        </div>
      );
    }
  };

  return (
    <div className="kpi-metric">
      <div className="metric-header no-left-field">
        <div className="non-filter-note">
          <p>* This metric is not filterable by Date & User Group</p>
        </div>
        {authData.adminAuthorization.usageMetric ? (
          <Button type="secondary" label="Add/Update" className="notification-btn" onClick={() => setNewsLetterModal(true)}></Button>
        ) : null}
      </div>
      {isLoading && getNewsLetrDetails.length === 0 ? (
        <div className="adm-loader-container">
          <Loader />
        </div>
      ) : (
        <div className="visit-info">
          <div className="visit-chart">
            <LineChartComponent
              data={getNewsLetrDetails}
              dataKeys={[
                {
                  color: '#3773d2',
                  label: 'total_views',
                },
              ]}
              useTooltip={true}
              //   showLegend={true}
              xAxisKey="news_letters"
              tick={true}
              yAxisWidth={40}
              chartHeight={chartHeight}
              tooltipFormatter={CustomTooltip}
            />
          </div>
        </div>
      )}
      {newsLetterModal ? (
        <NewsLetterModal
          opened={newsLetterModal}
          newsLetterModalClosed={(val: boolean) => setNewsLetterModal(val)}
          newsLetterData={getNewsLetrDetails}
          setReloadChart={() => setNewsLetrDetails([])}
        />
      ) : null}
    </div>
  );
};
export default NewsLetterChart;
