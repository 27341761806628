export const usageTrackingHeader = [
  {
    headerName: 'Page Title',
    field: 'page_title',
  },
  {
    headerName: 'Visits',
    field: 'count_visits',
  },
  {
    headerName: 'Unique Visits',
    field: 'uniqueusers',
  },
  {
    headerName: 'Total Time Spent',
    field: 'totaltimespent',
  },
];
