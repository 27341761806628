import { useState, ChangeEvent, useEffect } from 'react';
import './index.scss';

interface XYZInputInterface {
  updateForm: (version: string) => void;
  initialVersion: string | null;
}

const XYZInput = ({ updateForm, initialVersion }: XYZInputInterface) => {
  let x = null,
    y = null,
    z = null;
  if (initialVersion) [x, y, z] = initialVersion.split('.');
  const [values, setValues] = useState({ X: x || '', Y: y || '', Z: z || '' });

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    const regex = /^[0-9\b]+$/;
    const { name, value } = event.target;
    if (event.target.value === '' || regex.test(event.target.value)) {
      setValues({ ...values, [name]: value });
    }
  };

  useEffect(() => {
    updateForm(`${values.X}.${values.Y}.${values.Z}`);
  }, [values]);

  return (
    <div className="xyz-input">
      <input name="X" value={values.X} onChange={handleChange} className="xyz-input__input" min={0} />
      .
      <input name="Y" value={values.Y} onChange={handleChange} className="xyz-input__input" />
      .
      <input name="Z" value={values.Z} onChange={handleChange} className="xyz-input__input" />
    </div>
  );
};

export default XYZInput;
