export const outlinks = [
  {
    headerName: 'User Name',
    field: 'fullname',
  },
  {
    headerName: 'User ID',
    field: 'userid',
  },
  // {
  //   headerName: "Position",
  //   field: "position",
  // },
  // {
  //   headerName: "Function",
  //   field: "function",
  // },
  {
    headerName: 'Page Title',
    field: 'pagetitle',
  },
  {
    headerName: 'Date',
    field: 'insert_date',
  },
  {
    headerName: 'URL',
    field: 'visited_url',
  },
];
export const unique_outlinks = [
  {
    headerName: 'Page Title',
    field: 'pagetitle',
  },
  {
    headerName: 'Outlinks',
    field: 'visited_url',
    width: '500px'
  },
  {
    headerName: 'Count',
    field: 'count_of_visits',
  },
];
