import React, { useState, useEffect } from 'react';
import './index.scss';
import { MultiSelect } from 'react-multi-select-component';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { DropDownValType } from '../../components/user-audit/user-audit-interface';
import { apiResponse, getUserDetailsFromOktaToken } from '../../utility/commonMethods';
import { Tabs, Loader } from '@gitlab-rtsensing/component-library';
import { Button } from '@opsdti-global-component-library/amgen-design-system';
import ConfigUpdateTable from '../../components/config-update/existing-constant-update';
import AddConstant from '../../components/config-update/add-new-constant';

const ConfigAPIUpdate: React.FC = () => {
  const [workstreamData, setworkstreamData] = useState([]);
  const defaultId = process.env.REACT_APP_ADMIN_ID_CONSTANTS === undefined ? '' : process.env.REACT_APP_ADMIN_ID_CONSTANTS?.toString(); // will never hit '' but just for typescript
  const [selectedWS, setSelectedWS] = useState<{ label: string; value: string }>({ value: defaultId, label: 'admin' }); //Just default, will configuring from env since there is Id disprepancy in table for stage-prd & test-dev
  const [categoryList, setCategoryList] = useState<DropDownValType[]>([]);
  const [selectedCategory, setSelectedCategory] = useState<DropDownValType[]>([]);
  const [configAPIData, setConfigAPIData] = useState([]);
  const [optionLoading, setOptionLoading] = useState(false);
  const [isLoading, setLoading] = useState(false);
  const [showCategory, setShowCategory] = useState(true);

  const [newCategory, setNewCategory] = useState('');
  const [categoryDetail, setCategoryDetail] = useState('');
  const [addedRows, setAddedRows] = useState<any>([]);
  const userInfo = getUserDetailsFromOktaToken();
  const [enable, setEnable] = useState(true);
  const [isDefaultLoad, setIsDefaultLoad] = useState(true);
  const [preventMultipleCalls, setPreventMultipleCalls] = useState<{
    workstreamId: string;
    categoryIds: string[];
  }>();

  const [configVal, setConfigVal] = useState('');
  const [configArray, setConfigArray] = useState<any>([]);
  const [configFinalArray, setConfigFinalArray] = useState<any>([]);
  const [sortOrder, setSortOrder] = useState('ASC');

  useEffect(() => {
    const preference = localStorage.getItem('constants-preference');
    if (preference !== null) {
      const preferenceObj = JSON.parse(preference);
      setSelectedWS({ label: preferenceObj.workstream.label, value: preferenceObj.workstream.id });
      setSelectedCategory(preferenceObj.category);
      getConfigApiData(
        preferenceObj.workstream.id,
        preferenceObj.category.map((categoryIds: { id: string }) => categoryIds.id.toString()),
      );
      getWorkStreamList();
      getCategoryList(preferenceObj.workstream.id);
      //setIsDefaultLoad(false); don't need to trigger useEffect based on default load
    } else {
      setIsDefaultLoad(false);
    }
  }, []);

  useEffect(() => {
    if (!isDefaultLoad) {
      getWorkStreamList();
      getCategoryList(selectedWS?.value);
    }
  }, [isDefaultLoad, selectedWS]);

  const getWorkStreamList = async () => {
    try {
      const res = await apiResponse('get', 'get-config-api-ws-list', [], {});
      if (res?.status === 200) {
        if (res?.data) {
          if (res.data.length > 0) {
            setworkstreamData(res.data);
          } else {
            toast.error('Constant - Data not available!!');
          }
        }
      } else {
        console.log(res);
        toast.error('Failed to load!!');
      }
    } catch (error) {
      console.log(error);
      toast.error('Something Went Wrong!!');
    }
  };

  const getCategoryList = async (label: string) => {
    setOptionLoading(true);
    try {
      const res = await apiResponse('get', 'get-config-api-category-list', [label], {});
      if (res?.status === 200) {
        if (res?.data && res.data.length > 0) {
          let options = new Array<DropDownValType>();
          res.data.map((name: { category_id: number; category: string }) => {
            options.push({
              id: name.category_id,
              value: name.category,
              label: name.category,
            });
          });
          setCategoryList(options);

          if (!isDefaultLoad) {
            //If default load false or manual change, then execute below code
            setSelectedCategory(options);
            let categoryIds = options.map(categoryIds => categoryIds.id.toString());
            getConfigApiData(label, categoryIds);
          } else {
          }
        } else {
          toast.error('Constant - Category Data not available!!');
          setPreventMultipleCalls({ workstreamId: '', categoryIds: [] });
          cleanup();
        }
      } else {
        toast.error('Failed to load!!');
        cleanup();
      }
    } catch (error) {
      console.log(error);
      toast.error('Something Went Wrong!!');
      cleanup();
    }
    setOptionLoading(false);
  };

  const cleanup = () => {
    setSelectedCategory([]);
    setCategoryList([]);
    setConfigAPIData([]);
  };

  /*
   ** @description: API Call to get config data based on workstream id & category ids
   **/
  const getConfigApiData = async (value: string, categoryIds: string[]) => {
    if (categoryIds.length === 0) return;

    if (preventMultipleCalls?.workstreamId === value && JSON.stringify(preventMultipleCalls?.categoryIds) === JSON.stringify(categoryIds)) return;

    setLoading(true);
    setPreventMultipleCalls({
      workstreamId: value,
      categoryIds: categoryIds,
    });

    try {
      const res = await apiResponse('get', 'get-config-api-data', [value, 'CATEGORY_IDS=' + categoryIds.toString()], {});
      if (res?.status === 200) {
        if (res?.data) {
          if (res.data.length > 0) {
            if (sortOrder === 'ASC') {
              res.data.sort((a: any, b: any) => a.config_key.localeCompare(b.config_key));
            } else {
              res.data.sort((a: any, b: any) => b.config_key.localeCompare(a.config_key));
            }
            setConfigAPIData(res.data);
          } else {
            toast.error('Constant - Table Data not available!!');
            setConfigAPIData([]);
          }
        }
      } else {
        toast.error('Failed to load!!');
      }
    } catch (error) {
      console.log(error);
      toast.error('Something Went Wrong!!');
    }
    setLoading(false);
  };

  useEffect(() => {
    if (selectedCategory.length > 0 && !isDefaultLoad) {
      let categoryIds = selectedCategory.map(categoryIds => categoryIds.id.toString());
      getConfigApiData(selectedWS?.value, categoryIds);
    }
  }, [selectedCategory]);

  const handleEdit = () => {
    setEnable(false);
  };

  const handleCancel = () => {
    let categoryIds = selectedCategory.map(categoryIds => categoryIds.id.toString());
    getConfigApiData(selectedWS?.value, categoryIds);
    setEnable(true);
    setLoading(false);
  };

  const handleChangeConfigValue = (e: React.ChangeEvent<HTMLTextAreaElement>, obj: any) => {
    setConfigArray((item: any) => [...item, obj]);
    setConfigVal((obj['config_value'] = e.target.value));
  };

  useEffect(() => {
    const unique = Object.values(
      configArray.reduce((accumulator: any, item: any) => {
        const { sys_config_id, ...rest } = item;
        return {
          ...accumulator,
          [sys_config_id]: { sys_config_id, ...rest },
        };
      }, {}),
    );
    setConfigFinalArray(unique);
  }, [configArray]);

  useEffect(() => {
    if (sortOrder === 'ASC') {
      configAPIData.sort((a: any, b: any) => a.config_key.localeCompare(b.config_key));
    } else {
      configAPIData.sort((a: any, b: any) => b.config_key.localeCompare(a.config_key));
    }
    setConfigAPIData([...configAPIData]);
  }, [sortOrder]);

  /*
   ** @description: API Call to update config data
   **/
  const saveUpdateConfigApi = async () => {
    const payload = {
      work_stream_id: selectedWS?.value.toString(),
      created_by: userInfo?.email.split('@')[0],
      config_details: configFinalArray,
    };
    setLoading(true);

    try {
      const res = await apiResponse('post', 'update-config-api-data', [], payload);

      if (res?.status === 200 && res?.data.status === 'SUCCESS') {
        // setConfigAPIData(res.data);
        toast.success(res.data.data);
        setLoading(false);
        setEnable(true);
      } else {
        toast.error('Constant - Table Data not available!!');
        setLoading(false);
      }
    } catch (error) {
      console.log(error);
      toast.error('Something Went Wrong!!');
      setLoading(false);
    }
  };

  /*
   ** @description: API Call to add config data
   **/
  const addNewConstant = async (userData: any) => {
    const configDetails = userData.map((row: any) => {
      return {
        config_key: row.constant_key,
        config_value: row.constant_value,
      };
    });

    if (newCategory !== '')
      if (categoryList.filter(obj => obj.value.toLowerCase() === newCategory.toLowerCase()).length > 0) {
        toast.warning('Category already exists in ' + selectedWS?.label);
        setNewCategory('');
        return;
      }
    setLoading(true);

    // This api call will work for adding both new category and constant

    const payload = {
      category_id: userData[0]?.categoryID === undefined ? 0 : Number(userData[0]?.categoryID),
      workstream_id: Number(selectedWS?.value),
      catagory_name: userData[0]?.category === undefined ? newCategory : userData[0]?.category,
      catagory_details: categoryDetail,
      created_by: userInfo?.email.split('@')[0],
      config_details: configDetails,
    };
    try {
      const res = await apiResponse('post', 'insert-config-api-data', [], payload);
      if (res?.data.status === 'SUCCESS') {
        let categoryIds = selectedCategory.map(categoryIds => categoryIds.id.toString());
        if (res?.data.data.indexOf('exists') > -1) {
          toast.warning(res?.data.data);
        } else {
          toast.info('successfully inserted data');
        }
        setNewCategory('');
        setCategoryDetail('');
        if (userData.length !== 0) {
          setAddedRows([]);
        }
        setEnable(true);
        getConfigApiData(selectedWS?.value, categoryIds);
        getCategoryList(selectedWS?.value);
        handleClickedSavePreference();
      } else {
        // console.log(res?.data);
      }
    } catch (error) {
      console.log(error);
    }
    setLoading(false);
  };

  const handleWorkStreamChange = (e: any) => {
    let index = e.nativeEvent.target.selectedIndex;
    let label = e.nativeEvent.target[index].text;
    let value = e.target.value;

    setSelectedWS({ label: label, value: value });
    //getCategoryList(value);
    setIsDefaultLoad(false);
  };

  const handleClickedSavePreference = () => {
    //Setting to Localstorage for preference
    if (selectedCategory.length > 0 && selectedWS?.label !== undefined) {
      localStorage.setItem(
        'constants-preference',
        JSON.stringify({ workstream: { label: selectedWS.label, id: selectedWS.value }, category: selectedCategory }),
      );
    }
  };

  const handleCategoryUpdate = (options: DropDownValType[]) => {
    setSelectedCategory(options);
    let categoryIds = options.map(categoryIds => categoryIds.id.toString());
    if (categoryIds.length === 0) {
      setConfigAPIData([]);
    } else {
      getConfigApiData(selectedWS?.value, categoryIds);
    }
  };

  return (
    <div>
      <div className="d-flex config-filters">
        <div className={`${showCategory ? 'config-filter' : 'config-filter disable'}`}>
          <label className="spl-label">
            Workstream <sup className="sup-star">*</sup>
          </label>
          <select
            className="select-filter-data-elements"
            value={selectedWS?.value}
            onChange={e => {
              handleWorkStreamChange(e);
            }}
          >
            <option value="">Select</option>
            {workstreamData.map((option: { workstream_id: number; workstream_name: string }) => (
              <option className="option-style" key={option.workstream_id} value={option.workstream_id}>
                {option.workstream_name}
              </option>
            ))}
          </select>
        </div>
        {showCategory ? (
          <>
            <div className="category-container d-flex align-items-end">
              <div className="">
                <label className="spl-label">
                  Category <sup className="sup-star">*</sup>
                </label>
                <MultiSelect
                  options={categoryList}
                  value={selectedCategory}
                  isLoading={optionLoading}
                  className="multiselect"
                  onChange={(options: DropDownValType[]) => handleCategoryUpdate(options)}
                  labelledBy="Select"
                  disabled={categoryList.length > 0 ? false : true}
                />
              </div>
              <div className="">
                <Button text="Save Preference" type="secondary" onClick={handleClickedSavePreference} />
              </div>
            </div>
            <div className="constants-note non-filter-note">
              <span>* The constants you may find on this page are labels, text, colors within respective domain pages</span>
            </div>
          </>
        ) : (
          <div className="ctg-container">
            <div>
              <label className="spl-label config-label">New Category</label>
              <input
                type="text"
                className="search-input"
                value={newCategory}
                onChange={evt => {
                  setNewCategory(evt.target.value.toLowerCase());
                }}
                placeholder={'Type Category Name'}
              ></input>
            </div>
            <div className="category-btn">
              <Button
                text="Add Category"
                type="primary"
                disabled={newCategory === '' || selectedWS?.value === ''}
                onClick={() => {
                  addNewConstant([]);
                }}
              ></Button>
            </div>
          </div>
        )}
      </div>
      <Tabs
        className="constant-update-tabs"
        tabs={[
          {
            content: (
              <ConfigUpdateTable
                tableData={configAPIData}
                handleChangeConfigValue={handleChangeConfigValue}
                saveUpdateConfigApi={saveUpdateConfigApi}
                handleCancel={handleCancel}
                loader={isLoading}
                enable={enable}
                handleEdit={handleEdit}
                sortDirection={sortOrder}
                setSortDirection={(val: string) => setSortOrder(val)}
              />
            ),
            header: {
              label: 'Update Existing Constant',
              onClick: () => setShowCategory(true),
            },
          },
          {
            content: (
              <AddConstant
                workstream={selectedWS}
                categoryList={categoryList}
                saveConstant={addNewConstant}
                rowsAdded={addedRows}
                addrows={e => setAddedRows(e)}
                loader={isLoading}
              />
            ),
            header: {
              label: 'Add New Constant',
              onClick: () => setShowCategory(false),
            },
          },
        ]}
      />
      <ToastContainer
        className="toast-position"
        position="top-right"
        autoClose={3000}
        hideProgressBar
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
    </div>
  );
};

export default ConfigAPIUpdate;
