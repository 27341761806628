/**
 * @description : Key names are in CAPS separated by Underscore. Key Values are separated by double underscore(__)
 * It follows page__component__variant
 * Key Values are lowercase, page,component,variant names can have dash not underscore
 *
 */
export const adminConstants = {
  ADMIN_METRIC_DEVICE_KPI: 'admin__usage-metric__device-kpi',
  ADMIN_METRIC_ENTRYMODE_TRACKING: 'admin__usage-metric__entry-mode-tracking',
  ADMIN_METRIC_AVG_TIME_SPENT: 'admin__usage-metric__avg-time-spent',
  ADMIN_METRIC_UNAUTHORIZED_TRACKING: 'admin__usage-metric__unauthorized-tracking',
  ADMIN_METRIC_NEW_VISITOR_LOG: 'admin__usage-metric__new-visitor-log',
  ADMIN_METRIC_OUTGOING_LINKS: 'admin__usage-metric__outgoing-links',
  ADMIN_METRIC_PAUSED_ACTIVITY_USER: 'admin__usage-metric__paused-activity-by-user',
  ADMIN_METRIC_NOTIFICATION: 'admin__usage-metric__notification',
  ADMIN_METRIC_PAGE_USAGE_CHART: 'admin__usage-metric__page-usage-chart',
  ADMIN_METRIC_PAGE_LEVEL_USAGE: 'admin__usage-metric__page-level-usage',
  ADMIN_METRIC_USER_LOGIN_HISTORY: 'admin__usage-metric__user-login-history',
  ADMIN_METRIC_TOP_TEN_USER: 'admin__usage-metric__top-ten-user',
  ADMIN_METRIC_BAR_COLOR_REGULAR: 'admin__usage-metric__bar-color-regular',
  ADMIN_METRIC_BAR_COLOR_HIGHLIGHT: 'admin__usage-metric__bar-color-highlight',
  ADMIN_VP_MAPPING_VIEW_MODE: 'admin__vp_mapping__isviewonly',
};
