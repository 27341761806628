export const visit_Table_Header = [
  {
    Header: 'Visit Date',
    accessor: 'visit_date',
  },
  {
    Header: 'URL',
    accessor: 'url',
  },
  {
    Header: 'Browser',
    accessor: 'browser',
  },
];
