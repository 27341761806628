import axios from 'axios';
import React, { useState, useEffect, useContext } from 'react';
import { toast } from 'react-toastify';
import { Loader, OpsButton, OpsTypography, PlusIcon } from '@gitlab-rtsensing/component-library';

import { FormTitle, IReleaseNote, IReleaseNoteItem, IReleaseVersion, IReleaseWorkstream, IUpdateReleaseNoteByStatusPayload } from '../release-note-interfaces';
import { useReleaseNotesContext } from '../../../contexts/release-note-context';
import ErrorDisplay from '../error-display';
import ReleaseNoteInput from '../release-note-input';
import VersionSelect from '../version-select';
import WorkstreamSelect from '../workstream-select';

import { APPROVED, DEFERRED, DRAFT, PENDING, PUBLISHED } from '../constants';
import { apiResponse } from '../../../utility/commonMethods';
import { AppContext } from '../../../contexts/app-context';
import CommentSection from '../comment-section';
import ConfirmOverlay from '../../confirm-overlay';
import StatusTag from '../status-tags';
import useConfirm from '../../../hooks/use-confirm';

import './index.scss';
import TeamsDropdown from '../teams-filter-dropdown';
import { Option } from 'react-multi-select-component';

interface ReleaseNoteFormProps {
  closeModal: () => void;
  onReleaseNotesModalClosed: () => void;
  fetchReleaseNotes?: () => Promise<void>;
  currentReleaseNotes?: IReleaseNoteItem[];
  handleAddToEdit?: (title: FormTitle) => void;
}

interface SubmittedReleaseNote {
  releaseVersionId?: number;
  releaseNoteWorkstreamId?: number;
  releaseNotes: IReleaseNote[];
  username: string;
  statusId: number;
  releaseNoteId?: number;
  pmComments: string;
  dependencyTeam: string;
}

interface FormState {
  editing: boolean;
  updating: boolean;
  commentSectionDisabled: boolean;
}

const ReleaseNotesForm: React.FC<ReleaseNoteFormProps> = ({ closeModal, handleAddToEdit }) => {
  const BASE_FORM_STATE = {
    editing: false,
    updating: true,
    commentSectionDisabled: true,
  };

  const EDITING_FORM_STATE = {
    editing: true,
    updating: false,
    commentSectionDisabled: true,
  };

  const PM_FORM_STATE = {
    ...BASE_FORM_STATE,
    commentSectionDisabled: false,
  };

  const {
    releaseNotes: currentReleaseNotes,
    releaseVersions,
    deletedReleaseNotes,
    releaseWorkstreams: workstreams,
    releaseStatuses,
    refetch,
    username,
    releaseNoteInitialFormData,
    setInitialForm,
  } = useReleaseNotesContext();

  const { authData } = useContext(AppContext);
  const userIsPM = authData?.adminAuthorization.releaseNotesPm;

  const { confirm, confirmState, confirmFn } = useConfirm();

  const [releaseNoteId, setReleaseNoteId] = useState(releaseNoteInitialFormData?.releaseNoteId || -1);
  const [selectedReleaseVersion, setSelectedReleaseVersion] = useState<IReleaseVersion>();
  const [selectedWorkstream, setSelectedWorkstream] = useState<IReleaseWorkstream>();
  const [releaseNotesState, setReleaseNotes] = useState<IReleaseNote[]>(releaseNoteInitialFormData?.notes || []);
  const [releaseNoteStatus, setReleaseNoteStatus] = useState<string>(
    releaseNoteInitialFormData?.releaseStatusData?.status && releaseNoteInitialFormData?.releaseStatusData?.status !== 'deleted'
      ? releaseNoteInitialFormData.releaseStatusData.status
      : DRAFT,
  );
  const [comments, setComments] = useState<string>('');
  const [selectedTeams, setSelectedTeams] = useState<Option[]>([]);

  const initialFormState = () => {
    if ([DRAFT, DEFERRED].includes(releaseNoteStatus) && !releaseNoteInitialFormData) return EDITING_FORM_STATE;
    if (releaseNoteStatus === PENDING && userIsPM) return PM_FORM_STATE;
    return BASE_FORM_STATE;
  };

  const [formState, setFormState] = useState<FormState>(initialFormState());
  const [formError, setFormError] = useState('');
  const [isLoading, setIsLoading] = useState<boolean>(false);

  useEffect(() => {
    if (releaseNoteInitialFormData?.releaseVersionData && releaseNoteInitialFormData?.releaseWorkstreamData) {
      dependencyTeamPopulate(releaseNoteInitialFormData.dependencyTeam);
      setSelectedReleaseVersion(releaseNoteInitialFormData.releaseVersionData);
      setSelectedWorkstream(releaseNoteInitialFormData.releaseWorkstreamData);
    }
  }, []);

  useEffect(() => {
    const checkReleaseNote = async (releaseNote: IReleaseNoteItem) => {
      if (
        releaseNote.workstream === selectedWorkstream?.workstream &&
        releaseNote.releaseVersionDate === selectedReleaseVersion?.releaseVersionDate &&
        releaseNote.releaseVersionNumber === selectedReleaseVersion?.releaseVersionNumber
      ) {
        return true;
      }
    };

    const checkForExistingNotes = async () => {
      for (let releaseNote of currentReleaseNotes) {
        if (await checkReleaseNote(releaseNote)) {
          let choice = true;
          if (
            !releaseNoteInitialFormData ||
            releaseNoteInitialFormData.releaseWorkstreamData?.releaseNoteWorkstreamId !== selectedWorkstream?.releaseNoteWorkstreamId ||
            releaseNoteInitialFormData.releaseVersionData?.releaseVersionId !== selectedReleaseVersion?.releaseVersionId
          ) {
            choice = await confirm({
              title: 'Existing Release Note',
              description: `A release note with the selected release version and workstream already exists. Do you want to edit or add to the existing release note?`,
              confirmBtnLabel: 'Yes',
            });
          }
          if (!choice) {
            closeModal();
          }
          setInitialForm(releaseNote.releaseNoteId);
          setReleaseNotes(releaseNote.notes);
          setFormState({
            ...formState,
            editing: false,
            updating: true,
          });
          dependencyTeamPopulate(releaseNote.dependencyTeam);
          setReleaseNoteStatus(releaseNote.status);
          setReleaseNoteId(releaseNote.releaseNoteId);
          if (handleAddToEdit) handleAddToEdit('Edit Release Notes');
        }
      }
      for (let releaseNote of deletedReleaseNotes) {
        if (await checkReleaseNote(releaseNote)) {
          setFormState({
            ...formState,
            editing: true,
            updating: true,
          });
          setReleaseNoteId(releaseNote.releaseNoteId);
          setInitialForm(releaseNote.releaseNoteId);
          return;
        }
      }
    };
    if (releaseNoteInitialFormData?.workstream !== selectedWorkstream?.workstream) {
      checkForExistingNotes();
    }
  }, [selectedWorkstream, selectedReleaseVersion]);

  const dependencyTeamPopulate = (dependencyTeam: any) => {
    let dependencyTeamRefactor = dependencyTeam !== null && dependencyTeam !== '' && dependencyTeam.split(',').length > 0 ? dependencyTeam.split(',') : [];
    dependencyTeamRefactor = dependencyTeamRefactor.map((team: string, indx: number) => {
      let formattedTeam = team
        .split(' ')
        .map((word: string) => word.charAt(0).toUpperCase() + word.slice(1))
        .join(' ');
      let unformattedTeam = team
        .split(' ')
        .map((word: string) => word.charAt(0).toLowerCase() + word.slice(1))
        .join(' ');
      return {
        label: formattedTeam,
        value: formattedTeam,
        key: unformattedTeam,
      };
    });
    setSelectedTeams(dependencyTeamRefactor);
  };

  const validateReleaseNoteForm = (): boolean => {
    if (!selectedReleaseVersion || !selectedWorkstream) {
      setFormError('Please select a version and workstream.');
      return false;
    }

    if (releaseNotesState.length < 1) {
      setFormError('Please add a release note.');
      return false;
    }

    if (!!releaseNotesState.find(rn => rn.content.trim() === '')) {
      setFormError('Please fill out all fields');
      return false;
    }
    // if ([APPROVED].includes(releaseNoteStatus) && )

    // if (
    //   releaseNoteInitialFormData &&
    //   !!arraysOfObjectsAreEqual(
    //     [...releaseNoteInitialFormData.notes],
    //     [...releaseNotesState]
    //   )
    // ) {
    //   setFormError("No changes to the release notes have been detected");
    //   return false;
    // }

    return true;
  };

  const handleApiResponse = async (
    method: string,
    endpoint: string,
    payload: SubmittedReleaseNote | IUpdateReleaseNoteByStatusPayload,
    successMessage: string,
    errorMessage: string,
  ) => {
    setIsLoading(true);
    try {
      let { data } = await apiResponse(method, endpoint, [], payload);
      if (data) {
        toast.success(successMessage);
        await refetch();
        setIsLoading(false);
        closeModal();
      } else {
        toast.error(errorMessage);
        setIsLoading(false);
      }
    } catch (error) {
      setIsLoading(false);
      if (axios.isAxiosError(error)) {
        if (error.response) {
          console.error('Server error:', error.response.status, error.response.data);
          toast.error(`${errorMessage}: ${error.response.status}`);
        } else if (error.request) {
          console.error('Network error:', error.request);
          toast.error(`${errorMessage}. Please check your network connection.`);
        } else {
          console.error('Error', error.message);
          toast.error(`${errorMessage}: ${error.message}`);
        }
      } else {
        console.error(errorMessage, error);
        toast.error(errorMessage);
      }
    }
  };

  const submitReleaseNotes = (payload: SubmittedReleaseNote) =>
    handleApiResponse('post', 'create-release-notes', payload, 'Release note successfully created.', 'An error occurred while creating release note.');

  const updateReleaseNotes = (payload: SubmittedReleaseNote) =>
    handleApiResponse('post', 'update-release-notes', payload, 'Release note successfully updated.', 'An error occurred while updating release note.');

  const updateReleaseNotesByStatus = (payload: IUpdateReleaseNoteByStatusPayload) =>
    handleApiResponse('post', 'update-release-note-status', payload, 'Release note successfully updated.', 'An error occurred while updating release note.');

  const handleArrayChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement>, index: number, field: string) => {
    const newReleaseNotes = [...releaseNotesState];

    if (field === 'isPrivate') {
      newReleaseNotes[index] = { ...newReleaseNotes[index], [field]: (event.target as HTMLInputElement).checked };
    } else if (field === 'isRapidrelease') {
      newReleaseNotes[index] = { ...newReleaseNotes[index], [field]: (event.target as HTMLInputElement).checked };
    } else if (field === 'isGoNogo') {
      newReleaseNotes[index] = { ...newReleaseNotes[index], [field]: (event.target as HTMLSelectElement).value };
    } else {
      newReleaseNotes[index] = { ...newReleaseNotes[index], [field]: (event.target as HTMLInputElement).value };
    }
    setReleaseNotes(newReleaseNotes);
  };

  const handleReleaseNoteChange = (event: React.ChangeEvent<HTMLTextAreaElement>, index: number) => handleArrayChange(event, index, 'content');

  const handlePrivateNoteChange = (event: React.ChangeEvent<HTMLInputElement>, index: number) => handleArrayChange(event, index, 'isPrivate');

  const handleRapidReleaseChange = (event: React.ChangeEvent<HTMLInputElement>, index: number) => handleArrayChange(event, index, 'isRapidrelease');

  const handleGoChange = (event: React.ChangeEvent<HTMLSelectElement>, index: number) => handleArrayChange(event, index, 'isGoNogo');

  const handleEdit = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
    setFormState({
      ...formState,
      editing: true,
      updating: true,
    });
  };

  const handleAddReleaseNote = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
    setReleaseNotes([...releaseNotesState, { content: '', isPrivate: false, isRapidrelease: false, isGoNogo: '' }]);
  };

  const handleCommentSectionChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    event.preventDefault();
    setComments(event.target.value);
  };

  const handleRemoveReleaseNote = (event: React.MouseEvent<HTMLButtonElement>, index: number) => {
    event.preventDefault();
    const newReleaseNotes = [...releaseNotesState];
    newReleaseNotes.splice(index, 1);
    setReleaseNotes(newReleaseNotes);
  };

  const handleSubmit = async (event: React.MouseEvent<HTMLButtonElement>, status: string) => {
    event.preventDefault();
    if (!validateReleaseNoteForm()) {
      return;
    }
    const { releaseNoteStatusId: statusId } = releaseStatuses.find(rs => rs.status === (status === DEFERRED ? DRAFT : status)) || { releaseNoteStatusId: 0 };
    let releaseNoteSubmission: SubmittedReleaseNote = {
      releaseVersionId: selectedReleaseVersion?.releaseVersionId,
      releaseNoteWorkstreamId: selectedWorkstream?.releaseNoteWorkstreamId,
      releaseNotes: releaseNotesState,
      username: username,
      statusId: statusId,
      pmComments: comments || '',
      dependencyTeam: selectedTeams.map(teams => teams.value).join(','),
    };

    if (formState.updating) {
      releaseNoteSubmission = {
        ...releaseNoteSubmission,
        releaseNoteId: releaseNoteId,
      };
      updateReleaseNotes(releaseNoteSubmission);
    } else {
      submitReleaseNotes(releaseNoteSubmission);
    }
  };

  const handleSaveChanges = (event: React.MouseEvent<HTMLButtonElement>) => handleSubmit(event, releaseNoteStatus);

  const handleSubmitDraft = (event: React.MouseEvent<HTMLButtonElement>) => handleSubmit(event, PENDING);

  async function handleUpdateStatus(event: React.MouseEvent<HTMLButtonElement>, status: string): Promise<void> {
    event.preventDefault();
    const { releaseNoteStatusId: draftStatusId } = releaseStatuses.find(rs => rs.status === status) || {
      releaseNoteStatusId: 0,
    };

    let releaseNoteSubmission: IUpdateReleaseNoteByStatusPayload = {
      releaseNoteId: releaseNoteInitialFormData?.releaseNoteId || 0,
      username: username,
      statusId: draftStatusId,
    };

    let choice = true;
    if (status === DEFERRED || status === 'deleted') {
      choice = await confirm({
        title: `Confirm ${status === 'deleted' ? 'Delete' : 'Defer'}`,
        description: `Are you sure you want to ${status === 'deleted' ? 'delete' : 'defer'} this release note?`,
        confirmBtnLabel: 'Yes',
      });
    }

    if (!choice) {
      return;
    }
    if (status === DEFERRED && comments.length > 0) {
      releaseNoteSubmission.pmComments = comments;
    }

    let goNogoStatus = true; //For Validating Go flag should be selected before approve.
    releaseNotesState.map((notes) => {
      if (notes.isGoNogo === '' && status === APPROVED) {
        goNogoStatus = false;
        return;
      }
    });
    if (!goNogoStatus) {
      setFormError('Please select "GO?" for every note then approve.')
      return;
    }
    console.log(releaseNotesState)
    await updateReleaseNotesByStatus(releaseNoteSubmission);
    closeModal();
  }

  const disableButtonCondition = !selectedWorkstream || !selectedReleaseVersion || !releaseNotesState.length;

  const renderApprovalButtons = () => (
    <>
      <OpsButton label={'Publish'} onClick={e => handleUpdateStatus(e, PUBLISHED)} type="primary" />
      <OpsButton label={'Unapprove'} onClick={e => handleUpdateStatus(e, PENDING)} type="primary" />
    </>
  );

  const renderSaveChangesButton = () => <OpsButton label="Save Changes" disabled={disableButtonCondition} onClick={e => handleSaveChanges(e)} type="primary" />;

  const renderDraftButtons = () => (
    <>
      <OpsButton label="Save Draft" disabled={disableButtonCondition} onClick={e => handleSaveChanges(e)} type="secondary" />
      <OpsButton label="Submit" disabled={disableButtonCondition} onClick={e => handleSubmitDraft(e)} type="primary" />
    </>
  );

  const renderSubmitForApprovalButton = () => <OpsButton label="Submit" type="primary" onClick={e => handleSubmitDraft(e)} />;

  const renderPendingButtons = () => (
    <>
      <OpsButton label={'Revert'} onClick={e => handleUpdateStatus(e, DRAFT)} type="primary" />
      <OpsButton label={'Defer'} onClick={e => handleUpdateStatus(e, DEFERRED)} type="primary" />
      <OpsButton label={'Approve'} onClick={e => handleUpdateStatus(e, APPROVED)} type="primary" />
    </>
  );

  const renderUnpublishButton = () => <OpsButton label="Unpublish" onClick={e => handleUpdateStatus(e, APPROVED)} type="primary" />;

  const renderActions = (status: string) => {
    if (userIsPM) {
      if (status === APPROVED && !formState.editing) {
        return renderApprovalButtons();
      }
      if ([PENDING, APPROVED].includes(status) && formState.editing) {
        return renderSaveChangesButton();
      }
      if (status === PENDING) {
        return renderPendingButtons();
      }
      if (status === PUBLISHED) {
        return renderUnpublishButton();
      }
    }

    if ([DRAFT, DEFERRED].includes(status)) {
      return formState.editing ? renderDraftButtons() : renderSubmitForApprovalButton();
    }

    if (status === PENDING) {
      return <OpsButton label={'Revert'} onClick={e => handleUpdateStatus(e, DRAFT)} type="primary" />;
    }
  };

  return (
    <form className="release-note-form">
      {formError && <ErrorDisplay message={formError} />}
      <div className="release-note-form__main-section">
        <div className="release-note-form__version-workstream-container">
          <VersionSelect
            releaseVersions={releaseVersions}
            selectedReleaseVersion={selectedReleaseVersion}
            disabled={!formState.editing}
            handleVersionChange={version => setSelectedReleaseVersion(version)}
          />
          <WorkstreamSelect
            workstreams={workstreams}
            selectedWorkstream={selectedWorkstream}
            disabled={!formState.editing}
            handleWorkstreamChange={workstream => {
              setSelectedWorkstream(workstream);
              setSelectedTeams(selectedTeams.filter(teams => teams.key !== workstream?.workstream));
            }}
          />
          <TeamsDropdown
            selectedWorkstream={selectedWorkstream}
            selectedTeams={selectedTeams}
            disabled={!formState.editing}
            handleTeamsUpdate={teams => setSelectedTeams(teams)}
          />

          <div className="release-note-form__tag-edit-container">
            <StatusTag status={releaseNoteStatus} />
            {([DRAFT, DEFERRED].includes(releaseNoteStatus) && !formState.editing) ||
              ([APPROVED, PENDING].includes(releaseNoteStatus) && userIsPM && !formState.editing) ? (
              <OpsButton label="Edit" disabled={!selectedWorkstream} onClick={e => handleEdit(e)} type="secondary" />
            ) : (
              <div style={{ width: '68px' }} />
            )}
          </div>
        </div>
        <hr className="release-note-form__hr" />
        <div className="release-note-form__notes-container">
          {!!releaseNotesState.length && (
            <>
              <div className="release-note-form__input-headers">
                <div style={{ width: '315px' }} />
                <OpsTypography className="release-note-form__internal-header" variant={'label'} elementTag={'div'} children={'INTERNAL?'} />
                <OpsTypography className="release-note-form__internal-header" variant={'label'} elementTag={'div'} children={'RAPID RELEASE?'} />
                {([PENDING, DEFERRED, DRAFT].includes(releaseNoteStatus) && (
                  <OpsTypography className="release-note-form__internal-header goNogo-select" variant={'label'} elementTag={'div'} children={'GO?'} />
                )
                )}
                <OpsTypography className="release-note-form__delete-header" variant={'label'} elementTag={'div'} children={'DELETE'} />
              </div>
              <hr className="release-note-form__hr" />
            </>
          )}
          {selectedReleaseVersion?.releaseVersionId && selectedWorkstream?.releaseNoteWorkstreamId && (
            <>
              <div className="release-note-form__note-input">
                {releaseNotesState.map((note, index) => (
                  ([APPROVED, PUBLISHED].includes(releaseNoteStatus) && note.isGoNogo === 'No') ? <></> :
                    <ReleaseNoteInput
                      key={index}
                      note={note}
                      index={index}
                      disabled={!formState.editing || !selectedReleaseVersion?.releaseVersionId || !selectedWorkstream?.releaseNoteWorkstreamId}
                      handleReleaseNoteChange={handleReleaseNoteChange}
                      handleRemoveReleaseNote={handleRemoveReleaseNote}
                      handlePrivateNoteChange={handlePrivateNoteChange}
                      handleRapidReleaseChange={handleRapidReleaseChange}
                      releaseNoteStatus={releaseNoteStatus}
                      handleGoChange={handleGoChange}
                    />
                ))}
              </div>

              {formState.editing && selectedReleaseVersion?.releaseVersionId && selectedWorkstream?.releaseNoteWorkstreamId && (
                <OpsButton
                  type="secondary"
                  label="Add Note"
                  className="release-note-form__add-note-input-button"
                  onClick={e => handleAddReleaseNote(e)}
                  leftIcon={<PlusIcon fill="var(--ops-primary-blue)" height="14px" width="14px" />}
                />
              )}
            </>
          )}
        </div>
      </div>
      {releaseNoteInitialFormData && (
        <div className="release-note-form__comments-container">
          <hr className="release-note-form__hr" />
          <CommentSection
            note={releaseNoteInitialFormData}
            comments={comments}
            commentHistory={releaseNoteInitialFormData?.commentHistory}
            disabled={formState.commentSectionDisabled}
            handleCommentSectionChange={e => handleCommentSectionChange(e)}
          />
        </div>
      )}
      <div className="release-note-form__action-container">
        {([PENDING, DRAFT, DEFERRED].includes(releaseNoteStatus) || userIsPM) && releaseNoteInitialFormData && (
          <OpsButton label="Delete" type="secondary" onClick={e => handleUpdateStatus(e, 'deleted')} className="delete-release-note-button" />
        )}
        <OpsButton
          label="cancel"
          onClick={e => {
            e.preventDefault();
            closeModal();
          }}
          type="secondary"
        />
        {renderActions(releaseNoteStatus)}
      </div>
      <ConfirmOverlay {...confirmState} onClose={() => confirmFn?.current?.(false)} onConfirm={() => confirmFn?.current?.(true)} />
      {isLoading && (
        <div className="release-note-form__overlay">
          <Loader />
        </div>
      )}
    </form>
  );
};

export default ReleaseNotesForm;
