export const usageTrackingHeader = [
  {
    headerName: 'User Name',
    field: 'fullname',
  },
  {
    headerName: 'User ID',
    field: 'userid',
  },
  {
    headerName: 'Device Type',
    field: 'device_type',
  },
  {
    headerName: 'Device Model',
    field: 'devicemodel',
  },
  {
    headerName: 'Operating system',
    field: 'operatingsystem',
  },
  {
    headerName: 'Browser',
    field: 'browser',
  },
  {
    headerName: 'Visits',
    field: 'no_of_visits',
  },
];
