export const brand_table = [
  // {
  //     Header: 'Table Name',
  //     accessor: 'table',
  // },
  // {
  //     Header: 'Process ID',
  //     accessor: 'process_uuid',
  // },
  {
    Header: 'Snapshot Date [Run#]',
    accessor: 'table_load_dt',
  },
  {
    Header: 'Refresh Date',
    accessor: 'metric_latest_refresh_dt',
  },
  {
    Header: '',
    accessor: 'submitted_to_refresh',
  },
];
