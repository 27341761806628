export const usageTrackingHeader = [
  {
    headerName: 'User Name',
    field: 'fullname',
  },
  {
    headerName: 'User ID',
    field: 'userid',
  },
  {
    headerName: 'Page Title',
    field: 'page_title',
  },
  {
    headerName: 'URL',
    field: 'url',
  },
  {
    headerName: 'Average Time Spent',
    field: 'average_timespent',
  },
  // {
  //   headerName: "Date",
  //   field: "servertimepretty",
  // },
];
