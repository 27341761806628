import React, { useMemo, useState, useEffect, useContext } from 'react';
import DataTable from '../../data-table';

import { Level1_Table_Header } from './Level1TableHeader';
import { Button } from '@opsdti-global-component-library/amgen-design-system';
import { Loader } from '@gitlab-rtsensing/component-library';
import { apiResponse } from '../../../utility/commonMethods';
import { toast } from 'react-toastify';
import { useUserAppInfo } from '../../../hooks/use-user-app-info';
import { AppContext } from '../../../contexts/app-context';
import { rowSpanning } from '../../../utility/commonMethods';

const LevelOneApprover: React.FC = () => {
  const columns = useMemo(() => Level1_Table_Header, []);
  const [data, setData] = useState([]);
  const [isLoading, setLoading] = useState(true);
  const [level1ReqList, setLevel1ReqList] = useState([{}]);
  const [isDisabled, setDisabled] = useState(true);
  const userAppInfo = useUserAppInfo();
  const tileList = userAppInfo !== null ? userAppInfo.pageFeature : [];
  const { accessRequestSuccessful } = useContext(AppContext);
  let userInfo: { email: string };

  let idToken = JSON.parse(localStorage.getItem('sensing-okta-token') || '{}')?.idToken?.claims;
  userInfo = { email: idToken.email };

  useEffect(() => {
    if (tileList.length > 1) {
      //First is empty object
      getLevelOneRequest();
    }
  }, [tileList, accessRequestSuccessful === true]);

  const getLevelOneRequest = async () => {
    const payload = {
      approval_level: 'level_1',
    };
    try {
      setLoading(true);
      const res = await apiResponse('post', 'get-access-request-list', [], payload);
      if (res?.data.data) {
        let tableData = res?.data.data.request_details;
        rowSpanning(tableData, 'request_id');
        // for creating tile list on level one table as per Page
        tableData.map((row: any, indx: Number) => {
          let tiles: any = [];
          tileList.map((tile: any) => {
            if (row.page_name.toLowerCase() === tile.name.toLowerCase()) {
              tile?.compartment.map((compt: any) => {
                tiles.push({ value: compt.key, label: compt.name });
              });
            }
          });
          return (row.default_tile = row.tile_name), (row.tile_name = tiles), (row.index = indx);
        });
        setData(tableData);
        setLoading(false);
      } else {
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
  };
  const submitLevel1Req = async () => {
    const payload = {
      approval_request_list: level1ReqList,
    };
    // console.log(payload)
    try {
      setDisabled(true);
      const res = await apiResponse('post', 'submit-requests', [], payload);
      if (res?.data.status === 'SUCCESS') {
        toast.success('Level One Request has been acted upon');
        getLevelOneRequest();
      } else {
        toast.error('Failed to Submit Request');
      }
      setDisabled(false);
    } catch (error) {
      console.log(error);
      toast.error('Failed to Submit Request');
      setDisabled(false);
    }
  };

  return (
    <>
      <div className="table-container">
        {isLoading ? (
          <div className="adm-loader-container">
            <Loader />
          </div>
        ) : (
          <div>
            <DataTable columns={columns} data={data} handleTaskClick={setLevel1ReqList} setSubmitDisable={setDisabled} component="level_1" />
            <div className="d-flex justify-content-end ">
              <Button text="Submit" type="primary" onClick={() => submitLevel1Req()} className="admin-btn" disabled={isDisabled}></Button>
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default LevelOneApprover;
