import React, { useState } from 'react';
import { MultiSelect, Option } from 'react-multi-select-component';
import { useReleaseNotesContext } from '../../../contexts/release-note-context';
import './index.scss';
import { OpsTypography } from '@gitlab-rtsensing/component-library';
import { IDependentTeams, IReleaseWorkstream } from '../release-note-interfaces';

interface TeamsDropDownProps {
  disabled?: boolean;
  selectedTeams: IDependentTeams[];
  selectedWorkstream?: IReleaseWorkstream;
  handleTeamsUpdate: (selectedTeams: IDependentTeams[]) => void;
}

const TeamsDropdown: React.FC<TeamsDropDownProps> = props => {
  const { releaseWorkstreams } = useReleaseNotesContext();
  let filteredWorkstreams: IReleaseWorkstream[] = [];
  if (props.selectedWorkstream !== undefined) {
    filteredWorkstreams = releaseWorkstreams.filter(workstream => workstream.workstream !== props.selectedWorkstream?.workstream);
  }
  const allTeams = filteredWorkstreams.map(workstream =>
    workstream.workstream
      .split(' ')
      .map((word: string) => word.charAt(0).toUpperCase() + word.slice(1))
      .join(' '),
  );

  const valueRenderer = (selected: any) => {
    if (!selected.length) {
      return '-- Select Workstream';
    }
  };

  return (
    <div className="dropdown-container">
      <OpsTypography className="teams-select__label" variant="label" elementTag="label" variantWeight={700} children={'DEPENDENT WORKSTREAM'} />
      <MultiSelect
        className="ops-fs-5 teams-dropdown"
        options={allTeams.map(team => ({
          label: team,
          value: team,
          key: team
            .split(' ')
            .map((word: string) => word.charAt(0).toLowerCase() + word.slice(1))
            .join(' '),
        }))}
        value={props.selectedTeams}
        valueRenderer={valueRenderer}
        onChange={(teams: any) => props.handleTeamsUpdate(teams)}
        labelledBy="-- Select Workstream"
        disabled={props.disabled}
        disableSearch={true}
      />
    </div>
  );
};

export default TeamsDropdown;
