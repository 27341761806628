import './index.scss';
import { Loader, Button, ExternalLinkIcon } from '@gitlab-rtsensing/component-library';
import { useState, useEffect } from 'react';
import { apiResponse, formatMonthDayYearToMMDDYYDash } from '../../utility/commonMethods';
import { toast } from 'react-toastify';
import ETLMonitoringComp from '../../components/etl-monitoring';
import ExternalLinkLogo from '../../assets/icons/external-link';

interface MapType {
  work_stream: string;
  databricks_job_status: {
    job_name: string;
    run_start_time: string;
    run_end_time: string;
    job_status: string;
  }[];
}

const ETLJobs: React.FC = () => {
  const [isLoading, setLoading] = useState(true);
  const [etlJobsData, setETLJobsData] = useState<Map<string, MapType[]>>();

  useEffect(() => {
    if (etlJobsData === undefined) getETLJobsInfo();
  }, []);

  /*
   ** @description: API Call to get Etl Databricks Job Status
   **/
  const getETLJobsInfo = async () => {
    setLoading(true);

    const payload = {};
    try {
      const res = await apiResponse('post', 'get-etl-jobs', [], payload);
      if (res?.data?.status === 'SUCCESS') {
        if (res.data.data.length > 0) {
          res.data.data.map((job: MapType) =>
            job.databricks_job_status.map(obj =>
              Object.assign(
                obj,
                { date: formatMonthDayYearToMMDDYYDash(obj.run_start_time.split(' ')[0]) },
                {
                  'start/end': obj.run_start_time.split(' ')[1] + '/' + (obj.run_end_time !== '' ? obj.run_end_time.split(' ')[1] : obj.run_end_time),
                },
              ),
            ),
          );

          let map = new Map<string, MapType[]>(res.data.data.map((job: MapType) => [job.work_stream, job.databricks_job_status]));
          setETLJobsData(map);
        } else {
          toast.error('Jobs Data not available!!');
        }
      } else {
        console.log(res);
        toast.error('Failed to load!!');
      }
    } catch (error) {
      console.log(error);
      toast.error('Something Went Wrong!!');
    }
    setLoading(false);
  };

  return (
    <>
      {isLoading ? (
        <div className="adm-loader-container">
          <Loader />
        </div>
      ) : (
        <>
          {etlJobsData !== undefined ? (
            <div className="workstream-container">
              <div className="jobs-legend">
                <div className="legend-box">
                  <div className="legend-color success"></div>
                  <div className="legend-text"> Job Succeed </div>
                </div>
                <div className="legend-box">
                  <div className="legend-color failed"></div>
                  <div className="legend-text"> Job Failed </div>
                </div>
                <div className="legend-box">
                  <div className="legend-color on-hold"></div>
                  <div className="legend-text"> Job On-Hold </div>
                </div>
                <div className="legend-box">
                  <div className="legend-color in-progress"></div>
                  <div className="legend-text"> Job In-Progress </div>
                </div>
                <div className="link-box">
                  <Button
                    label={'Grafana Cron Job Dashboard'}
                    onClick={() => {
                      window.open(process.env.REACT_APP_GRAFANA_LINK, '_blank');
                    }}
                    type="secondary"
                    icon={<ExternalLinkIcon />}
                    iconPosition="right"
                  />
                </div>
              </div>

              <>
                {Array.from(etlJobsData).map(([workstreamName, workstreamKey]) => {
                  return <ETLMonitoringComp workstream={workstreamName} jobsData={etlJobsData.get(workstreamName)} />;
                })}
              </>
            </div>
          ) : null}
        </>
      )}
    </>
  );
};

export default ETLJobs;
