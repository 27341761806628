import { useState, useEffect } from 'react';
import 'react-date-range/dist/styles.css'; // main css file
import 'react-date-range/dist/theme/default.css'; // theme css file
import '../index.scss';
import { apiResponse } from '../../../utility/commonMethods';
import { toast } from 'react-toastify';
import { Loader, LineChartComponent } from '@gitlab-rtsensing/component-library';
import { SpoVSExecUsageProps } from '../usage-metric-interface';
import { BarChart, Bar, YAxis, ResponsiveContainer, XAxis, CartesianGrid, Tooltip, Legend, LineChart, Line } from 'recharts';
import { format, set } from 'date-fns';

interface CustomToolTipProps {
  active: Boolean;
  payload: {
    payload: any;
    executive: string;
    spo: string;
  }[];
  label: string;
}
interface UserListProps {
  executive: string;
  executive_number_of_visits: number;
  insert_date: Date | number;
  spo: string;
  spo_number_of_visits: string;
}

const SpoVSExec = (props: SpoVSExecUsageProps) => {
  const [isLoading, setLoading] = useState(true);
  const [getUserList, setUserList] = useState<UserListProps[]>([
    { executive: '', executive_number_of_visits: 0, insert_date: 0, spo: '', spo_number_of_visits: 'string' },
  ]);
  const [getUserListOriginal, setUserListOriginal] = useState([]);

  const [selectedExec, setSelectExec] = useState('');
  const [execOtp, setExecOpt] = useState([]);
  const [viewType, setViewType] = useState('Daily');
  const [chartHeight, setChartHeight] = useState(350);
  const [chartWidth, setChartWidth] = useState(500);

  const viewOption = ['Daily', 'Weekly', 'Monthly'];

  useEffect(() => {
    if (props.clearData) {
      setViewType('Daily');
      setUserListOriginal([]);
      getUsageTrackingData();
    }
  }, [props.clearData]);

  useEffect(() => {
    let filtredExec = getUserListOriginal.filter((data: UserListProps) => data.executive === selectedExec);
    setUserList(filtredExec);
  }, [selectedExec, getUserListOriginal]);

  useEffect(() => {
    setUserListOriginal([]);
    getUsageTrackingData();
  }, [viewType]);

  const getUsageTrackingData = async () => {
    setLoading(true);
    let metricID = 0;
    if (viewType === 'Monthly') {
      metricID = 18;
    } else if (viewType === 'Weekly') {
      metricID = 19;
    } else {
      metricID = 16;
    }
    const payload = {
      metric_id: metricID,
      date_type: props.date.type,
      to_date: props.date.toDate,
      fr_date: props.date.fromDate,
    };
    try {
      const res = await apiResponse('post', 'get-usage-metric-data', [], payload);
      if (res?.data?.status === 'SUCCESS') {
        if (res?.data?.data?.user_metric) {
          if (res.data.data.user_metric.length > 0) {
            let graphData = res.data.data.user_metric;
            if (viewType === 'Monthly') {
              graphData.map((data: any) => {
                data.month_start_date = format(new Date(data.month_start_date), 'MMM yyyy');
              });
            } else if (viewType === 'Weekly') {
              graphData.map((data: any) => {
                data.week_start_date = format(new Date(data.week_start_date), 'MMM dd, yyyy');
              });
            } else {
              graphData.map((data: any) => {
                data.insert_date = format(new Date(data.insert_date), 'MMM dd, yyyy');
              });
            }

            let chartHeight = document?.querySelector('#spo_vs_exec_views')?.clientHeight || 350;
            let chartWidth = document?.querySelector('#spo_vs_exec_views')?.clientWidth || 500;
            setChartHeight(chartHeight - 150);
            setChartWidth(chartWidth);

            const exec = graphData.map((data: UserListProps) => {
              return data.executive;
            });
            setExecOpt(Array.from(new Set(exec)));
            setUserListOriginal(graphData);
            setUserList(graphData);
            setSelectExec(graphData[0]?.executive);
            props.setClearData(false);
          } else {
            toast.error('Data not available!!');
          }
        }
      } else {
        console.log(res);
        toast.error('Failed to load!!');
      }
    } catch (error) {
      console.log(error);
      toast.error('Something Went Wrong!!');
    }
    setLoading(false);
  };

  function CustomToolTip(props: CustomToolTipProps) {
    const { active, payload, label } = props;
    if (active && payload?.length > 1) {
      return (
        <div className="spoVsExec-custom-tooltip">
          <p className="label">{label}</p>
          <p className="label" style={{ color: '#82ca9d' }}>
            {payload[0].payload.executive !== '' ? (
              payload[0].payload.executive + ':' + Number(payload[0].payload.executive_number_of_visits)
            ) : (
              <div> No Executive</div>
            )}
          </p>
          <p className="label" style={{ color: '#0063c3' }}>
            {payload[0].payload.spo !== '' ? payload[0].payload.spo + ':' + Number(payload[0].payload.spo_number_of_visits) : <div> No SPO</div>}
          </p>
        </div>
      );
    }
    return null;
  }

  let graphWidth = getUserList.length < 8 ? 47.5 + 'em' : getUserList.length * 6 + 'em';

  return (
    <div className="usage-metric SpoVSExec-metric">
      <div className="metric-header no-left-field">
        <div className="non-filter-note">
          <p>* Weekly and Monthly views are not date filterable</p>
        </div>
        <div className="">
          <span className="ops-text ops-text-dark ops-fw-bold ops-fs-4 gcf-text">View By: </span>
          <select
            className="select-filter-chart"
            onChange={e => {
              setViewType(e.target.value);
            }}
            value={viewType}
          >
            {viewOption.map((option: any) => (
              <option className="option-style" key={option} value={option}>
                {option}
              </option>
            ))}
          </select>
        </div>
      </div>
      <div className="exec-filter">
        <span className="ops-text ops-text-dark ops-fw-bold ops-fs-4 gcf-text">Executive: </span>
        <select
          className="select-filter-chart"
          onChange={e => {
            setSelectExec(e.target.value);
          }}
          value={selectedExec}
        >
          {execOtp.map((option: any) => (
            <option className="option-style" key={option} value={option}>
              {option}
            </option>
          ))}
        </select>
      </div>
      {isLoading && getUserList.length === 0 ? (
        <div className="adm-loader-container">
          <Loader />
        </div>
      ) : (
        <div className="SpoVSExec-metric-container">
          {viewType === 'Daily' && (
            <div className="adm-barchart-container" style={{ height: chartHeight, width: graphWidth }}>
              <>
                <ResponsiveContainer width="100%">
                  <BarChart barSize={18} data={getUserList} barCategoryGap={40}>
                    <CartesianGrid horizontal={false} stroke="#E9E9E9" />
                    <XAxis
                      dataKey="insert_date"
                      axisLine={false}
                      tickLine={false}
                      style={{
                        fontSize: '10px',
                        fontFamily: 'Inter',
                        fontWeight: 700,
                        lineHeight: '14px',
                        color: '#9C9C9C',
                      }}
                    />
                    <YAxis
                      allowDecimals={false}
                      axisLine={false}
                      tickLine={false}
                      style={{
                        fontSize: '10px',
                        fontFamily: 'Inter',
                        fontWeight: 700,
                        lineHeight: '14px',
                        color: '#9C9C9C',
                        cursor: 'pointer',
                      }}
                      label={{ value: 'VISIT', angle: -90, position: 'insideLeft', fill: 'black', fontWeight: 600 }}
                    />
                    <Tooltip content={<CustomToolTip active={true} payload={[]} label={''} />} />

                    <Legend
                      payload={[
                        {
                          id: getUserList[0]?.spo,
                          type: 'square',
                          value: `SPO: ${getUserList[0]?.spo}`,
                          color: '#0063c3',
                        },
                        {
                          id: getUserList[0]?.executive,
                          type: 'square',
                          value: `Executive: ${getUserList[0]?.executive}`,
                          color: '#82ca9d',
                        },
                      ]}
                      wrapperStyle={{ fontSize: '14px', bottom: 0, marginLeft: '30px', fontWeight: '700' }}
                    />
                    <Bar barSize={30} dataKey="spo_number_of_visits" fill="#0063c3" />
                    <Bar barSize={30} dataKey="executive_number_of_visits" fill="#82ca9d" />
                  </BarChart>
                </ResponsiveContainer>
              </>
            </div>
          )}
          {(viewType === 'Weekly' || viewType === 'Monthly') && (
            <div className="adm-linechart-container">
              <>
                <ResponsiveContainer width="100%">
                  <LineChart data={getUserList}>
                    <CartesianGrid vertical={false} stroke="#E9E9E9" />
                    <XAxis
                      dataKey={viewType === 'Weekly' ? 'week_start_date' : 'month_start_date'}
                      axisLine={false}
                      tickLine={false}
                      style={{
                        fontSize: '10px',
                        fontFamily: 'Inter',
                        fontWeight: 700,
                        lineHeight: '14px',
                        color: '#9C9C9C',
                      }}
                    />
                    <YAxis
                      allowDecimals={false}
                      axisLine={false}
                      tickLine={false}
                      style={{
                        fontSize: '10px',
                        fontFamily: 'Inter',
                        fontWeight: 700,
                        lineHeight: '14px',
                        color: '#9C9C9C',
                        cursor: 'pointer',
                      }}
                      label={{ value: 'VISIT', angle: -90, position: 'insideLeft', fill: 'black', fontWeight: 600 }}
                    />
                    <Tooltip content={<CustomToolTip active={true} payload={[]} label={''} />} />

                    <Legend
                      payload={[
                        {
                          id: getUserList[0]?.spo,
                          type: 'square',
                          value: `SPO: ${getUserList[0]?.spo}`,
                          color: '#0063c3',
                        },
                        {
                          id: getUserList[0]?.executive,
                          type: 'square',
                          value: `Executive: ${getUserList[0]?.executive}`,
                          color: '#82ca9d',
                        },
                      ]}
                      wrapperStyle={{ fontSize: '14px', bottom: 0, marginLeft: '30px', fontWeight: '700' }}
                    />
                    <Line type="linear" strokeWidth={3} dataKey="executive_number_of_visits" stroke="#82ca9d" />
                    <Line type="linear" strokeWidth={3} dataKey="spo_number_of_visits" stroke="#0063c3" />
                  </LineChart>
                </ResponsiveContainer>
              </>
            </div>
          )}
        </div>
      )}
    </div>
  );
};
export default SpoVSExec;
