import { Button } from '@opsdti-global-component-library/amgen-design-system';
import { UserAccess_Table_Header } from './userAuditTableHeader';
import { useMemo, useState, useContext } from 'react';
import { workDayRedirect } from '../../../utility/commonMethods';
import ExternalLinkLogo from '../../../assets/icons/external-link';
import SearchModal from '../search-modal';
import Profile from '../../../assets/images/Profile.png';
import './index.scss';
import { AppContext } from '../../../contexts/app-context';

interface TableProps {
  tableData: any;
  confirmRemoveUser: (val: string) => void;
}

const SpecificAccessTable = (props: TableProps) => {
  const columns = useMemo(() => UserAccess_Table_Header, []);
  const [searchModalOpen, setSearchModalOpen] = useState(false);
  const [userID, setUserID] = useState('');
  const { authData } = useContext(AppContext);

  const searchUser = async (name: any) => {
    setSearchModalOpen(true);
    setUserID(name);
  };
  return (
    <>
      <table className="table-bordered audit-table specific-access-audit-table">
        <thead>
          <tr>
            {columns.map((data: any, indx: any) => {
              if (data.Header === 'Action' && !authData.adminAuthorization.userAuditView) {
                return;
              } else {
                return (
                  <th key={indx} className="rts-group">
                    {data.Header}
                  </th>
                );
              }
            })}
          </tr>
        </thead>
        <tbody>
          {props.tableData.map((data: any, indx: any) => {
            return (
              <tr key={indx}>
                {columns.map((colData: any, colIndx: any) => {
                  if (colData.Header === 'Action' && !authData.adminAuthorization.userAuditView) {
                    return (
                      <td key={indx + colIndx}>
                        <div className="d-flex justify-content-center">
                          <Button text="Remove" type="primary" onClick={() => props.confirmRemoveUser(data)} className="audit-btn"></Button>
                        </div>
                      </td>
                    );
                  } else if (colData.accessor === 'fullname') {
                    return (
                      <td className="faux-link" key={indx + colIndx} onClick={() => workDayRedirect(data.profile_link)}>
                        <span className="user-id">{data[colData.accessor]}</span>
                        <span className="external-link">
                          <ExternalLinkLogo />
                        </span>
                      </td>
                    );
                  } else if (colData.accessor === 'loginname') {
                    return (
                      <td
                        key={indx + colIndx}
                        data-testid={data[colData.accessor]}
                        className={!authData.adminAuthorization.userAuditView ? 'faux-link' : ''}
                        onClick={!authData.adminAuthorization.userAuditView ? () => searchUser(data[colData.accessor]) : undefined}
                      >
                        {data[colData.accessor]}
                        <span className="external-link">
                          <img src={Profile} alt="profile-link" className="profile" />
                        </span>
                      </td>
                    );
                  } else {
                    return <td key={indx + colIndx}>{data[colData.accessor]}</td>;
                  }
                })}
              </tr>
            );
          })}
        </tbody>
      </table>
      <SearchModal opened={searchModalOpen} searchModalClosed={() => setSearchModalOpen(false)} userID={userID} />
    </>
  );
};

export default SpecificAccessTable;
