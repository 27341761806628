import React, { useMemo, useState, useEffect } from 'react';
import DataTable from '../../data-table';
import { Feature_Table_Header } from './featureTableHeader';
import { Button } from '@opsdti-global-component-library/amgen-design-system';
import { Loader } from '@gitlab-rtsensing/component-library';
import { apiResponse, getUserDetailsFromOktaToken } from '../../../utility/commonMethods';
import { toast } from 'react-toastify';

const FeatureApprover: React.FC = () => {
  const columns = useMemo(() => Feature_Table_Header, []);
  const [data, setData] = useState([]);
  const [isLoading, setLoading] = useState(true);
  const [featureOwn_ReqList, setFeatureOwn_ReqList] = useState([{}]);
  const [isDisabled, setDisabled] = useState(true);
  const [originalData, setOriginalData] = useState([]);
  const userInfo = getUserDetailsFromOktaToken();

  useEffect(() => {
    getFeatureOwnRequest();
  }, []);

  useEffect(() => {
    if (originalData.length > 0) {
      filterOwnerData();
    }
  }, [originalData]);

  const getFeatureOwnRequest = async () => {
    const payload = {
      approval_level: 'feature_owner',
    };
    try {
      setLoading(true);
      const res = await apiResponse('post', 'get-access-request-list', [], payload);
      if (res?.data.data) {
        let tableData = res?.data.data.request_details;
        setOriginalData(tableData);
        setLoading(false);
      } else {
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
  };

  const filterOwnerData = async () => {
    const payload = {
      user_id: userInfo?.email.split('@')[0],
    };
    try {
      setLoading(true);
      const res = await apiResponse('post', 'filter-feature-owner-data', [], payload);
      if (res?.data.status === 'SUCCESS') {
        setLoading(false);
        const filterPage = res.data.data.feature_access;
        let tableData = filterPage.flatMap((pageArr: any) =>
          originalData.filter((row: any) => {
            return row.page_name.toLowerCase() === pageArr.toLowerCase();
          }),
        );
        setData(tableData);
      } else {
        setLoading(false);
        toast.error('Failed to filter data');
      }
    } catch (error) {
      setLoading(false);
      console.log(error);
      toast.error('Something went wrong');
    }
  };
  const submitFeatureOwnReq = async () => {
    const payload = {
      approval_request_list: featureOwn_ReqList,
    };
    try {
      setDisabled(true);
      const res = await apiResponse('post', 'submit-requests', [], payload);
      if (res?.data.status === 'SUCCESS') {
        toast.success('Feature approval request has been submitted');
        getFeatureOwnRequest();
      } else {
        toast.error('Failed to Submit Request');
      }
      setDisabled(false);
    } catch (error) {
      console.log(error);
      toast.error('Failed to Submit Request');
      setDisabled(false);
    }
  };

  useEffect(() => {
    if (featureOwn_ReqList.length > 0) {
      setDisabled(false);
    } else setDisabled(true);
  }, [featureOwn_ReqList]);

  return (
    <>
      <div className="table-container">
        {isLoading ? (
          <div className="adm-loader-container">
            <Loader />
          </div>
        ) : (
          <div>
            <DataTable columns={columns} data={data} handleTaskClick={setFeatureOwn_ReqList} component="feature_owner" />
            <div className="d-flex justify-content-end ">
              <Button text="Submit" type="primary" onClick={() => submitFeatureOwnReq()} className="admin-btn" disabled={isDisabled}></Button>
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default FeatureApprover;
