import React from 'react';
import { IReleaseWorkstream } from '../release-note-interfaces';
import { OpsTypography } from '@gitlab-rtsensing/component-library';
import './index.scss';

interface WorkstreamSelectProps {
  workstreams: IReleaseWorkstream[];
  selectedWorkstream?: IReleaseWorkstream;
  disabled?: boolean;
  handleWorkstreamChange: (selectedWorkstream?: IReleaseWorkstream) => void;
}

const WorkstreamSelect: React.FC<WorkstreamSelectProps> = ({ workstreams, selectedWorkstream, disabled, handleWorkstreamChange }) => {
  return (
    <div className="workstream-select">
      <OpsTypography className="workstream-select__label" variant="label" elementTag="label" variantWeight={700} children={'WORKSTREAM'} />
      <select
        value={`${selectedWorkstream?.releaseNoteWorkstreamId}-${selectedWorkstream?.workstream}`}
        onChange={e => {
          const [workstreamId, workstreamName] = e.target.value.split('-');
          handleWorkstreamChange({
            releaseNoteWorkstreamId: Number(workstreamId),
            workstream: workstreamName,
          });
        }}
        disabled={disabled}
        className="ops-fs-5 workstream-select__select"
      >
        <option className="ops-fs-5" value="">
          -- Select Workstream --
        </option>
        {workstreams.map(ws => (
          <option key={ws.releaseNoteWorkstreamId} className="ops-fs-5" value={`${ws.releaseNoteWorkstreamId}-${ws.workstream}`}>
            {`${ws.workstream
              .split(' ')
              .map(el => el[0].toUpperCase() + el.slice(1, el.length))
              .join(' ')}`}
          </option>
        ))}
      </select>
    </div>
  );
};

export default WorkstreamSelect;
