import { useState, useEffect } from 'react';
import 'react-date-range/dist/styles.css'; // main css file
import 'react-date-range/dist/theme/default.css'; // theme css file
import '../index.scss';
import { apiResponse } from '../../../../utility/commonMethods';
import { toast } from 'react-toastify';
import { Loader } from '@gitlab-rtsensing/component-library';
import WindowTen from '../../../../assets/icons/window10';
import Chrome from '../../../../assets/icons/chrome';
import Mac from '../../../../assets/icons/mac';
import IOS from '../../../../assets/icons/ios';
import Edge from '../../../../assets/icons/edge';
import Safari from '../../../../assets/icons/safari';
import Firefox from '../../../../assets/images/firefox.png';
import OtherBrowser from '../../../../assets/images/Other-Browser.png';
import { DeviceKPIType, KPIProps } from '../../usage-metric-interface';

const DeviceKPI = (props: KPIProps) => {
  const [isLoading, setLoading] = useState(true);
  const [getUsersList, setUsersList] = useState([]);
  const legendObj = {
    legendChromeCount: '',
    legendFirefoxCount: '',
    legendEdgeCount: '',
    legendSafariCount: '',
    legendOtherBrowserCount: '',
  };
  const [legendCount, setLegendCount] = useState<{
    legendChromeCount: string;
    legendFirefoxCount: string;
    legendEdgeCount: string;
    legendSafariCount: string;
    legendOtherBrowserCount: string;
  }>(legendObj);
  let count: DeviceKPIType = {
    windows: {
      chrome: 0,
      edge: 0,
      firefox: 0,
      other: 0,
      browserTotal: 0,
      chromePercentage: 0,
      edgePercentage: 0,
      firefoxPercentage: 0,
      otherPercentage: 0,
    },
    mac: {
      chrome: 0,
      firefox: 0,
      safari: 0,
      other: 0,
      browserTotal: 0,
      chromePercentage: 0,
      safariPercentage: 0,
      firefoxPercentage: 0,
      otherPercentage: 0,
    },
    ios: {
      chrome: 0,
      firefox: 0,
      safari: 0,
      other: 0,
      browserTotal: 0,
      chromePercentage: 0,
      safariPercentage: 0,
      firefoxPercentage: 0,
      otherPercentage: 0,
    },
    other: {
      chrome: 0,
      edge: 0,
      firefox: 0,
      other: 0,
      browserTotal: 0,
      chromePercentage: 0,
      edgePercentage: 0,
      firefoxPercentage: 0,
      otherPercentage: 0,
    },
    all: {
      allBrowserCountAcross: 0,
    },
  };

  const [countObj, setDeviceCount] = useState<DeviceKPIType>(count);

  useEffect(() => {
    if (props.clearData) {
      setUsersList([]);
      getUsageTrackingData();
    }
  }, [props.clearData]);

  useEffect(() => {
    const filterGroupData = getUsersList.filter((obj: any) => {
      if (props.userGroupValue.toLowerCase() === 'all sensing users' && props.includeCoreMember === 'N') {
        return obj.group_name.toLowerCase() === props.userGroupValue.toLowerCase() && obj.core_member_flag === props.includeCoreMember;
      } else {
        return obj.group_name.toLowerCase() === props.userGroupValue.toLowerCase();
      }
    });

    if (filterGroupData.length === 0) {
      setDeviceCount(count);
    } else {
      let userMetricArray = filterGroupData;

      userMetricArray.map((device: any) => {
        if (device.operatingsystem.toLowerCase().indexOf('mac') > -1) {
          if (device.browsername.toLowerCase().indexOf('chrome') > -1) {
            count.mac.chrome = device.count + count.mac.chrome;
          } else if (device.browsername.toLowerCase().indexOf('firefox') > -1) {
            count.mac.firefox = device.count + count.mac.firefox;
          } else if (device.browsername.toLowerCase().indexOf('safari') > -1) {
            count.mac.safari = device.count + count.mac.safari;
          } else {
            count.mac.other = device.count + count.mac.other;
          }
          count.mac.browserTotal = count.mac.chrome + count.mac.safari + count.mac.firefox + count.mac.other;
        } else if (device.operatingsystem.toLowerCase().indexOf('window') > -1) {
          if (device.browsername.toLowerCase().indexOf('chrome') > -1) {
            count.windows.chrome = device.count + count.windows.chrome;
          } else if (device.browsername.toLowerCase().indexOf('edge') > -1) {
            count.windows.edge = device.count + count.windows.edge;
          } else if (device.browsername.toLowerCase().indexOf('firefox') > -1) {
            count.windows.firefox = device.count + count.windows.firefox;
          } else {
            count.windows.other = device.count + count.windows.other;
          }
          count.windows.browserTotal = count.windows.chrome + count.windows.edge + count.windows.firefox + count.windows.other;
        } else if (device.operatingsystem.toLowerCase().indexOf('ios') > -1) {
          if (device.browsername.toLowerCase().indexOf('chrome') > -1) {
            count.ios.chrome = device.count + count.ios.chrome;
          } else if (device.browsername.toLowerCase().indexOf('firefox') > -1) {
            count.ios.firefox = device.count + count.ios.firefox;
          } else if (device.browsername.toLowerCase().indexOf('safari') > -1) {
            count.ios.safari = device.count + count.ios.safari;
          } else {
            count.ios.other = device.count + count.ios.other;
          }
          count.ios.browserTotal = count.ios.chrome + count.ios.safari + count.ios.firefox + count.ios.other;
        } else {
          if (device.browsername.toLowerCase().indexOf('chrome') > -1) {
            count.other.chrome = device.count + count.other.chrome;
          } else if (device.browsername.toLowerCase().indexOf('edge') > -1) {
            count.other.edge = device.count + count.other.edge;
          } else if (device.browsername.toLowerCase().indexOf('firefox') > -1) {
            count.other.firefox = device.count + count.other.firefox;
          } else {
            count.other.other = device.count + count.other.other;
          }
          count.other.browserTotal = count.other.chrome + count.other.edge + count.other.firefox + count.other.other;
        }
      });
      setDeviceCount(count);
    }
  }, [props.userGroupValue, getUsersList, props.includeCoreMember]);

  const getUsageTrackingData = async () => {
    setLoading(true);

    const payload = {
      metric_id: '8',
      date_type: props.date.type,
      to_date: props.date.toDate,
      fr_date: props.date.fromDate,
    };
    try {
      const res = await apiResponse('post', 'get-usage-metric-data', [], payload);
      if (res?.data?.status === 'SUCCESS') {
        if (res?.data?.data?.user_metric) {
          setUsersList(res.data.data.user_metric);
          props.setClearData(false);
        }
      } else {
        console.log(res);
        toast.error('Failed to load!!');
      }
    } catch (error) {
      console.log(error);
      toast.error('Something Went Wrong!!');
    }
    setLoading(false);
  };

  return (
    <div className="kpi-metric">
      <div className="metric-header"></div>
      {isLoading && getUsersList.length === 0 ? (
        <div className="adm-loader-container">
          <Loader />
        </div>
      ) : (
        <div className="kpi-metric-container">
          <div className="device-count">
            <div className="device-type windows">
              <div className="device-os" title="Includes devices with version of Windows">
                <WindowTen />
                <span className="total-field">Total: {countObj.windows.browserTotal}</span>
              </div>
              <div className="browser chrome" title="Includes version of Google Chrome">
                <Chrome />
                <span className="count-field">{countObj.windows.chrome}</span>
              </div>
              <div className="browser edge" title="Includes version of Microsoft Edge">
                <Edge />
                <span className="count-field">{countObj.windows.edge}</span>
              </div>
              <div className="browser firefox" title="Includes version of Mozilla Firefox">
                <img src={Firefox} alt="firefox" />
                <span className="count-field">{countObj.windows.firefox}</span>
              </div>
              <div className="browser other-browser" title="Includes Less Popular Browser E.g. Brave etc.">
                <img src={OtherBrowser} alt="other-browser" />
                <span className="count-field">{countObj.windows.other}</span>
              </div>
            </div>
            <div className="device-type mac">
              <div className="device-os" title="Includes devices with version of Macintosh">
                <Mac />
                <span className="total-field">Total: {countObj.mac.browserTotal}</span>
              </div>
              <div className="browser chrome" title="Includes version of Google Chrome">
                <Chrome />
                <span className="count-field">{countObj.mac.chrome}</span>
              </div>
              <div className="browser safari" title="Includes version of Safari">
                <Safari />
                <span className="count-field">{countObj.mac.safari}</span>
              </div>
              <div className="browser firefox" title="Includes version of Mozilla Firefox">
                <img src={Firefox} alt="firefox" />
                <span className="count-field">{countObj.mac.firefox}</span>
              </div>
              <div className="browser other-browser" title="Includes Less Popular Browser E.g. Brave etc.">
                <img src={OtherBrowser} alt="other-browser" />
                <span className="count-field">{countObj.mac.other}</span>
              </div>
            </div>

            <div className="device-type ios">
              <div className="device-os" title="Includes devices with version of IOS">
                <IOS />
                <span className="total-field">Total: {countObj.ios.browserTotal}</span>
              </div>
              <div className="browser chrome" title="Includes version of Google Chrome">
                <Chrome />
                <span className="count-field">{countObj.ios.chrome}</span>
              </div>
              <div className="browser safari" title="Includes version of Safari">
                <Safari />
                <span className="count-field">{countObj.ios.safari}</span>
              </div>
              <div className="browser firefox" title="Includes version of Mozilla Firefox">
                <img src={Firefox} alt="firefox" />
                <span className="count-field">{countObj.ios.firefox}</span>
              </div>
              <div className="browser other-browser" title="Includes Less Popular Browser E.g. Brave etc.">
                <img src={OtherBrowser} alt="other-browser" />
                <span className="count-field">{countObj.ios.other}</span>
              </div>
            </div>

            <div className="device-type other">
              <div className="device-os" title="Includes devices with version of Android, Linux">
                <span className="count-field">Other</span>
                <span className="total-field">Total: {countObj.other.browserTotal}</span>
              </div>
              <div className="browser chrome" title="Includes version of Google Chrome">
                <Chrome />
                <span className="count-field">{countObj.other.chrome}</span>
              </div>
              <div className="browser edge" title="Includes version of Microsoft Edge">
                <Edge />
                <span className="count-field">{countObj.other.edge}</span>
              </div>
              <div className="browser firefox" title="Includes version of Mozilla Firefox">
                <img src={Firefox} alt="firefox" />
                <span className="count-field">{countObj.other.firefox}</span>
              </div>
              <div className="browser other-browser" title="Includes Less Popular Browser E.g. Brave etc.">
                <img src={OtherBrowser} alt="other-browser" />
                <span className="count-field">{countObj.other.other}</span>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};
export default DeviceKPI;
