export const usageTrackingHeader = [
  {
    headerName: 'User Name',
    field: 'fullname',
  },
  {
    headerName: 'User ID',
    field: 'userid',
  },
  {
    headerName: 'Position',
    field: 'position',
  },
  {
    headerName: 'Function',
    field: 'function',
  },
  {
    headerName: 'Login Date',
    field: 'date_logged_in',
  },
  {
    headerName: 'Page Visited (Path)',
    field: 'pages_visited',
  },
];
