export const Ad_group_allocation_Table_Header = [
  {
    Header: 'Name',
    accessor: 'name',
  },
  {
    Header: 'Function',
    accessor: 'function',
  },
  {
    Header: 'Role',
    accessor: 'role',
  },
  {
    Header: 'Current Access',
    accessor: 'current_access',
  },

  {
    Header: 'Page',
    accessor: 'page_name',
  },
  {
    Header: 'Tile',
    accessor: 'tile_name',
  },
  {
    Header: 'AD-Groups',
    accessor: 'ad_groups',
  },
];
