import { IReleaseNoteItem, IReleaseVersion } from '../release-note-interfaces';

export interface DateRange {
  start: string;
  end: string;
}

export interface QuarterDateRange {
  quarterYear: string;
  dateRange: DateRange;
}

function parseDate(input: string): Date {
  const [month, day, year] = input.split(/,| /).filter(s => s);
  return new Date(`${month} ${day} ${year}`);
}

function getQuarterYear(date: Date): string {
  const quarter = Math.ceil((date.getMonth() + 1) / 3);
  const year = date.getFullYear();
  return `Q${quarter} ${year}`;
}

function getQuarterDateRange(quarterYear: string): DateRange {
  const [quarter, year] = quarterYear.split(' ');
  const startMonth = (parseInt(quarter[1]) - 1) * 3 + 1;
  const startDate = new Date(`${startMonth} 1, ${year}`);
  const endDate = new Date(startDate);
  endDate.setMonth(startDate.getMonth() + 2);
  endDate.setDate(new Date(endDate.getFullYear(), endDate.getMonth() + 1, 0).getDate());
  return {
    start: `${startDate.toLocaleString('en-US', {
      month: 'long',
    })} ${startDate.getDate()}, ${startDate.getFullYear()}`,
    end: `${endDate.toLocaleString('en-US', {
      month: 'long',
    })} ${endDate.getDate()}, ${endDate.getFullYear()}`,
  };
}

export function getQuarterDateRanges(releaseVersions: IReleaseVersion[]): QuarterDateRange[] {
  const quarterYearsSet = new Set<string>();

  releaseVersions.forEach(releaseVersion => {
    const releaseDate = parseDate(releaseVersion.releaseVersionDate);
    const quarterYear = getQuarterYear(releaseDate);
    quarterYearsSet.add(quarterYear);
  });

  return Array.from(quarterYearsSet).map(quarterYear => {
    return {
      quarterYear: quarterYear,
      dateRange: getQuarterDateRange(quarterYear),
    };
  });
}

function isDateInRange(date: Date, range: DateRange): boolean {
  const startDate = parseDate(range.start);
  const endDate = parseDate(range.end);
  return date >= startDate && date <= endDate;
}

export function filterByDateRanges(releaseNotes: IReleaseNoteItem[], dateRanges: DateRange[]): IReleaseNoteItem[] {
  if (dateRanges.length === 0) return releaseNotes;
  return releaseNotes.filter(releaseNote => {
    const releaseDate = parseDate(releaseNote.releaseVersionDate);
    return dateRanges.some(range => isDateInRange(releaseDate, range));
  });
}

export function filterReleaseVersionsByDateRanges(releaseVersions: IReleaseVersion[], dateRanges: DateRange[]): IReleaseVersion[] {
  if (dateRanges.length === 0) return releaseVersions;
  return releaseVersions.filter(releaseVersion => {
    const releaseDate = parseDate(releaseVersion.releaseVersionDate);
    return dateRanges.some(range => isDateInRange(releaseDate, range));
  });
}

export function filterReleaseNoteItemsByWorkstream(releaseNoteItems: IReleaseNoteItem[], workstreams: string[]): IReleaseNoteItem[] {
  if (workstreams.length === 0) return releaseNoteItems;
  return releaseNoteItems.filter(releaseNoteItem => workstreams.includes(releaseNoteItem.workstream));
}

export function filterReleaseNoteItemsByStatus(releaseNoteItems: IReleaseNoteItem[], statuses: string[]): IReleaseNoteItem[] {
  if (statuses.length === 0) return releaseNoteItems;
  return releaseNoteItems.filter(releaseNoteItem => statuses.includes(releaseNoteItem.status));
}
