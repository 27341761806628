import React, { useState, useContext, useEffect } from 'react';
import '../../admin.scss';
import LevelOneApprover from '../../components/access-request-pages/level-one-approver';
import LevelTwoApprover from '../../components/access-request-pages/level-two-approver';
import FeatureApprover from '../../components/access-request-pages/feature-approver';
import AdGroupAllocator from '../../components/access-request-pages/ad-group-allocator';
import './index.scss';
import { Button } from '@opsdti-global-component-library/amgen-design-system';
import { AppContext } from '../../contexts/app-context';
import InternalRequestAccessModal from '../../components/internal-request-access-modal';
import ExternalRequestAccessModal from '../../components/external-request-access-modal';

const AccessRequestPage: React.FC = (props: any) => {
  const [tableName, setTableName] = useState('');
  const [requestAccessModalOpen, setRequestAccessModalOpen] = useState(false);
  const [externalRequestAccessModalOpen, setExternalRequestAccessModalOpen] = useState(false);
  const [requestTypes, setRequestTypes] = useState(['']);
  const { authData } = useContext(AppContext);
  const { setAccessRequestSuccessful } = useContext(AppContext);

  useEffect(() => {
    const access = authData.adminAuthorization;
    const optionList: any = [];
    Object.keys(access).forEach(name => {
      if (name === 'levelOne' && access[name] === true) {
        optionList.push('Level One Approval', 'Allocation of AD Groups');
      }
      if (name === 'levelTwo' && access[name] === true) {
        optionList.push('Level Two Approval');
      }
      if (name === 'featureOwner' && access[name] === true) {
        optionList.push('Feature Approval');
      }
    });
    setRequestTypes(optionList);
    if (optionList[0] !== undefined) {
      setTableName(optionList[0]);
    }
  }, [authData]);

  useEffect(() => {
    setAccessRequestSuccessful(false);
  }, [requestAccessModalOpen === false]);

  const customOnChange = (e: any) => {
    setTableName(e.target.value);
  };
  return (
    <div className="access-request-container">
      <label className="form-label">
        Access Request Type <sup className="sup-star">*</sup>
      </label>
      <select
        className="select-filter-user-audit"
        value={tableName}
        onChange={(selected: any) => {
          customOnChange(selected);
        }}
      >
        {requestTypes.map((option: any) => (
          <option className="option-style-user-audit" key={option} value={option}>
            {option}
          </option>
        ))}
      </select>
      <div className='access-btn-container'>
      <Button text="External Request Access" type="secondary" className="external-access-btn" onClick={() => setExternalRequestAccessModalOpen(true)}></Button>
      <Button text="Request Access" type="secondary" className="raise-on-behalf" onClick={() => setRequestAccessModalOpen(true)}></Button>
      </div>
      {
      tableName === 'Level One Approval' && <LevelOneApprover />}
      {tableName === 'Level Two Approval' && <LevelTwoApprover />}
      {tableName === 'Feature Approval' && <FeatureApprover />}
      {tableName === 'Allocation of AD Groups' && <AdGroupAllocator />}
      <InternalRequestAccessModal opened={requestAccessModalOpen} onRequestAccessModalClosed={() => setRequestAccessModalOpen(false)} />
      <ExternalRequestAccessModal opened={externalRequestAccessModalOpen} onRequestAccessModalClosed={() => setExternalRequestAccessModalOpen(false)} />
    </div>
  );
};

export default AccessRequestPage;
