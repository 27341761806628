import { Modal } from '@gitlab-rtsensing/component-library';
import AmgenNetworks from '../../assets/icons/amgen-networks';
import Logo from '../../assets/images/Amgen-Sensing.png';
import ExternalAccessForm from '../external-request-access-form';
import './index.scss';

interface ExternalRequestAccessModalProps {
  title?: string;
  opened: boolean;
  onRequestAccessModalClosed: () => void;
  // requestAccessSource: string;
}

export default function ExternalRequestAccessModal(props: ExternalRequestAccessModalProps): JSX.Element {
  const { title = 'External Request Access', opened, onRequestAccessModalClosed } = props;

  return (
    <Modal className="internal-request-access-modal" opened={opened} onClose={onRequestAccessModalClosed}>
      <Modal.Header contentClassName="d-flex align-items-center" className="request-modal-header">
        <AmgenNetworks fill="var(--ops-white)" />
        <img src={Logo} alt="amgen logo"></img>
        <Modal.Title className="request-modal-title" title={title} />
      </Modal.Header>
      <div className="request-access-form-wrapper">
        <ExternalAccessForm onRequestAccessModalClosed={onRequestAccessModalClosed} />
      </div>
    </Modal>
  );
}
