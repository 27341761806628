import './index.scss';

export const columnDefs = [
  { headerName: 'VP Level', field: 'vp_level', tooltipField: 'vp_level' },
  { headerName: 'VP Lead Name', field: 'vp_lead_name', tooltipField: 'vp_lead_name' },
  { headerName: 'VP Org ID', field: 'vp_org_id', tooltipField: 'vp_org_id' },
  { headerName: 'Subfunction Level', field: 'subfunction_level', tooltipField: 'subfunction_level' },
  { headerName: 'Subfunction Lead Name', field: 'subfunction_lead_name', tooltipField: 'subfunction_lead_name' },
  { headerName: 'Subfunction Short Name', field: 'subfunction_short_name', tooltipField: 'subfunction_short_name' },
  { headerName: 'Subfunction Org ID', field: 'subfunction_org_id', tooltipField: 'subfunction_org_id' },
  { headerName: 'Function', field: 'function', tooltipField: 'function' },
  { headerName: 'Function Lead', field: 'function_lead', tooltipField: 'function_lead' },
  { headerName: 'Function Short Name', field: 'function_short_name', tooltipField: 'function_short_name' },
  { headerName: 'Function Org ID', field: 'function_org_id', tooltipField: 'function_org_id' },
  { headerName: 'Lower Env Allowed Groups', field: 'lower_env_allowed_groups', tooltipField: 'lower_env_allowed_groups' },
  { headerName: 'Higher Env Allowed Groups', field: 'higher_env_allowed_groups', tooltipField: 'higher_env_allowed_groups' },
];
export const vpMappingDiffCol = [
  { headerName: 'Login Name', field: 'login_name', tooltipField: 'login_name' },
  { headerName: 'Type', field: 'diff_type', tooltipField: 'diff_type'},
  { headerName: 'Changed Column', field: 'changed_column', tooltipField: 'changed_column' },
  { headerName: 'Old Value', field: 'old_value', tooltipField: 'old_value' },
  { headerName: 'New Value', field: 'new_value', tooltipField: 'new_value' },
  { headerName: 'Updated Timestamp', field: 'updated_date', tooltipField: 'updated_date' },
]
