import React, { useState } from 'react';
import { MultiSelect, Option } from 'react-multi-select-component';
import { useReleaseNotesContext } from '../../../contexts/release-note-context';

const StatusDropdown: React.FC = () => {
  const { releaseStatuses, filters, setFilters } = useReleaseNotesContext();
  const [selectedItems, setSelectedItems] = useState<Option[]>([]);

  const options = releaseStatuses.map(status => ({
    label: status.status
      .split(' ')
      .map((word: string) => word.charAt(0).toUpperCase() + word.slice(1))
      .join(' '),
    value: status.releaseNoteStatusId,
  }));

  const handleSelectedItemsChange = (newSelectedItems: { label: string; value: number }[]) => {
    setSelectedItems(newSelectedItems);
    setFilters({
      ...filters,
      statusFilters: newSelectedItems.map(item => item.label.toLowerCase()),
    });
  };

  const valueRenderer = (selected: typeof options) => {
    if (!selected.length) {
      return 'Filter by Status';
    }
  };

  return (
    <div className="dropdown-container">
      <MultiSelect
        className="ops-fs-5 status-dropdown"
        options={options}
        value={selectedItems}
        valueRenderer={valueRenderer}
        onChange={handleSelectedItemsChange}
        labelledBy="Filter by Status"
      />
    </div>
  );
};

export default StatusDropdown;
