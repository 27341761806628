import { useContext, useState } from 'react';
import ReleaseVersionsList from '../release-versions-list';
import { OpsButton, PlusIcon } from '@gitlab-rtsensing/component-library';
import { IReleaseVersionInitialFormData } from '../release-note-interfaces';
import ReleaseVersionModal from '../release-version-modal';
import './index.scss';
import { AppContext } from '../../../contexts/app-context';
import { useReleaseNotesContext } from '../../../contexts/release-note-context';

export default function ReleaseVersionsContainer() {
  const { isPublicView } = useReleaseNotesContext();
  const [modalOpened, setModalOpened] = useState<boolean>(false);
  const [initialFormData, setInitialFormData] = useState<IReleaseVersionInitialFormData | null>(null);

  const { authData } = useContext(AppContext);
  const userIsPM = authData?.adminAuthorization.releaseNotesPm;

  const addVersion = () => {
    setInitialFormData(null);
    setModalOpened(true);
  };

  const editVersion = (initialFormData: IReleaseVersionInitialFormData) => {
    setInitialFormData(initialFormData);
    setModalOpened(true);
  };

  return (
    <div className="release-versions-container">
      <ReleaseVersionsList editVersion={editVersion} />
      {userIsPM && !isPublicView && (
        <OpsButton
          label="Create Release"
          type="primary"
          className="release-versions-contianer__create-button"
          onClick={addVersion}
          rightIcon={<PlusIcon fill="var(--ops-white)" height="14px" width="14px" />}
        />
      )}
      <ReleaseVersionModal opened={modalOpened} setModalOpened={setModalOpened} initialFormData={initialFormData} />
    </div>
  );
}
