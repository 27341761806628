import { Modal, OpsButton, OpsTypography } from '@gitlab-rtsensing/component-library';
import './index.scss';

interface ConfirmModalPropsInterface {
  onClose: () => void;
  onConfirm: () => void;
  isOpen: boolean;
  title?: string;
  description?: string;
  confirmBtnLabel?: string;
}

export default function ConfirmModal({ isOpen, onClose, title, description, confirmBtnLabel, onConfirm }: ConfirmModalPropsInterface): JSX.Element {
  return (
    <Modal className="confirmation-modal" opened={isOpen} onClose={onClose}>
      <Modal.Header contentClassName="d-flex align-items-center">
        <Modal.Title title={title} />
      </Modal.Header>
      <div className="confirmation-modal__wrapper">
        <OpsTypography variant="p1" elementTag="p" children={description || ''} />
        <div className="confirmation-modal__footer">
          <OpsButton className="confirmation-modal__cancel-button" onClick={onClose} label="Cancel" type="secondary" />
          <OpsButton className="confirmation-modal__confirm-button" onClick={onConfirm} label={confirmBtnLabel || ''} type="primary" />
        </div>
      </div>
    </Modal>
  );
}
