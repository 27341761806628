import React from 'react';
import { IReleaseVersion } from '../release-note-interfaces';
import { OpsTypography } from '@gitlab-rtsensing/component-library';
import './index.scss';
import { formatMonthDayYearToMMDDYY } from '../../../utility/commonMethods';

interface VersionSelectProps {
  releaseVersions: IReleaseVersion[];
  selectedReleaseVersion?: IReleaseVersion;
  disabled?: boolean;
  handleVersionChange: (selectedReleaseVersion?: IReleaseVersion) => void;
}

const VersionSelect: React.FC<VersionSelectProps> = ({ releaseVersions, selectedReleaseVersion, disabled, handleVersionChange }) => {
  return (
    <div className="version-select">
      <OpsTypography className="version-select__label" variant="label" elementTag="label" variantWeight={700} children={'RELEASE VERSION'} />
      <select
        className="version-select__select ops-fs-5"
        value={`${selectedReleaseVersion?.releaseVersionId}-${selectedReleaseVersion?.releaseVersionNumber}-${selectedReleaseVersion?.releaseVersionDate}`}
        onChange={e => {
          const [selectedVersionId, selectedVersion, selectedDate] = e.target.value.split('-');
          handleVersionChange({
            releaseVersionId: Number(selectedVersionId),
            releaseVersionNumber: selectedVersion,
            releaseVersionDate: selectedDate,
          });
        }}
        disabled={disabled}
      >
        <option className="ops-fs-5" value="">
          -- Release Version --
        </option>
        {releaseVersions.map(rv => (
          <option key={rv.releaseVersionId} value={`${rv.releaseVersionId}-${rv.releaseVersionNumber}-${rv.releaseVersionDate}`}>
            {`v${rv.releaseVersionNumber} (${formatMonthDayYearToMMDDYY(rv.releaseVersionDate)})`}
          </option>
        ))}
      </select>
    </div>
  );
};

export default VersionSelect;
