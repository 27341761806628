import './index.scss';
import { useState, useEffect } from 'react';

interface MapType {
  work_stream: string;
  databricks_job_status: {
    job_name: string;
    run_start_time: string;
    run_end_time: string;
    job_status: string;
  }[];
}
interface JobsPropsType {
  workstream: string;
  jobsData: MapType[] | undefined;
}
const ETLMonitoringComp = (props: JobsPropsType) => {
  //console.log('workstrea', workstreamName, workstreamKey);
  const columns = [
    {
      Header: 'Job Name',
      accessor: 'job_name',
    },
    {
      Header: 'Status',
      accessor: 'job_status',
    },
    {
      Header: 'Date',
      accessor: 'date',
    },
    {
      Header: 'Start/End Time',
      accessor: 'start/end',
    },
  ];
  return (
    <>
      <div className="workstream-box">
        <div className="workstream-name">{props.workstream}</div>
        <div className="jobs-status">
          <table className="usage-metric-table etl-table">
            <thead>
              <tr>
                {columns.map((data: any, indx: any) => {
                  return <th key={indx}>{data.Header}</th>;
                })}
              </tr>
            </thead>
            <tbody>
              {props.jobsData?.map((data: any, indx: any) => {
                return (
                  <tr key={indx}>
                    {columns.map((colData: any, colIndx: any) => {
                      if (colData.accessor === 'job_status') {
                        if (data[colData.accessor] === 'SUCCESS') {
                          return (
                            <td key={indx + colIndx} title="Job Succeed">
                              <div className="jobs success"></div>
                            </td>
                          );
                        } else if (data[colData.accessor] === 'FAILED') {
                          return (
                            <td key={indx + colIndx} title="Job Failed">
                              <div className="jobs failed"></div>
                            </td>
                          );
                        } else if (data[colData.accessor] === 'ON HOLD') {
                          return (
                            <td key={indx + colIndx} title="Job On-Hold">
                              <div className="jobs on-hold"></div>
                            </td>
                          );
                        } else {
                          return (
                            <td key={indx + colIndx} title="Job In-Progress">
                              <div className="jobs in-progress"></div>
                            </td>
                          );
                        }
                      } else if (colData.accessor === 'run_start_time' || colData.accessor === 'run_end_time') {
                        return (
                          <td key={indx + colIndx}>
                            <span className="user-name">{data[colData.accessor]}</span>
                          </td>
                        );
                      } else {
                        return <td key={indx + colIndx}>{data[colData.accessor]}</td>;
                      }
                    })}
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      </div>
    </>
  );
};

export default ETLMonitoringComp;
