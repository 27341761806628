import { useMemo } from 'react';
import { useState, useEffect } from 'react';
import 'react-date-range/dist/styles.css'; // main css file
import 'react-date-range/dist/theme/default.css'; // theme css file
import '../index.scss';
import { usageTrackingHeader } from './usageTrackingTable';
import { apiResponse, filterMembers } from '../../../utility/commonMethods';
import { toast } from 'react-toastify';
import { Loader } from '@gitlab-rtsensing/component-library';
import { timeToHrMinSecond } from '../utility';
import ExternalLinkLogo from '../../../assets/icons/external-link';
import { PageLevelUsageProps } from '../usage-metric-interface';
import YRotate from '../../../assets/images/YRotate.png';
import PageUsageChart from '../kpi-at-top/page-usage-chart';
import { FileDownloadIcon } from '@opsdti-global-component-library/amgen-design-system';
import AgGridTable from '../../../utility/agGridTable';
import { ExpandIcon, CloseIcon } from '@opsdti-global-component-library/amgen-design-system';

const PageLevelUsage = (props: PageLevelUsageProps) => {
  const columns = useMemo(() => usageTrackingHeader, []);
  const [usageTrackingData, setUsageTrackingData] = useState([]);
  const [usageTrackingOriginal, setUsageTrackingOriginal] = useState([]);
  const [isLoading, setLoading] = useState(true);
  const [tableMsg, setTableMsg] = useState('No Data Available');
  const [flipButtonText, setflipButtonText] = useState('Go To Table View');
  const [filterInVisible, setFilterInvisible] = useState(false);
  const [showExpandOrClose, setShowExpandOrClose] = useState(false);
  const [triggerDownload, setTriggerDownload] = useState(false);

  const [dateValue, setDateValue] = useState({
    type: props.date.type,
    fromDate: props.date.fromDate,
    toDate: props.date.toDate,
    matamoType: 'day',
    matamoDate: props.date.toDate,
    displayValue: props.date.displayValue,
  });

  useEffect(() => {
    if (props.clearData) {
      setUsageTrackingData([]);
      setUsageTrackingOriginal([]);
      getUsageTrackingData();
    }
  }, [props.clearData]);

  const getUsageTrackingData = async () => {
    setLoading(true);

    const payload = {
      metric_id: '13',
      date_type: props.date.type,
      to_date: props.date.toDate,
      fr_date: props.date.fromDate,
    };
    try {
      const res = await apiResponse('post', 'get-usage-metric-data', [], payload);
      if (res?.data?.status === 'SUCCESS') {
        if (res?.data?.data?.user_metric) {
          if (res.data.data.user_metric.length === 0) {
            toast.error('Page Level Usage: Data not available!!');
          } else {
            res.data.data.user_metric.sort((a: any, b: any) => {
              if (Number(a.count_visits) > Number(b.count_visits)) {
                return -1;
              } else if (Number(a.count_visits) < Number(b.count_visits)) {
                return 1;
              } else {
                return 0;
              }
            });
            res.data.data.user_metric = res.data.data.user_metric.map((obj: any) => {
              return Object.assign(
                obj,
                {
                  page_title: obj.page_title.indexOf('?') > -1 ? obj.page_title.split('?')[0] : obj.page_title,
                },
                { totaltimespent: timeToHrMinSecond(obj.totaltimespent) },
              );
            });
            setUsageTrackingOriginal(res.data.data.user_metric);
            props.setClearData(false);
          }
        }
      } else {
        console.log(res);
        toast.error('Page Level Usage: Failed to load!!');
      }
    } catch (error) {
      console.log(error);
      toast.error('Page Level Usage: Something Went Wrong!!');
    }
    setLoading(false);
  };

  useEffect(() => {
    if (usageTrackingOriginal.length === 0) return;
    setUsageTrackingData(filterGroupValue([]));
  }, [props.userGroupValue, props.includeCoreMember, usageTrackingOriginal]);

  const filterGroupValue = (dataArray: any) => {
    if (dataArray.length === 0) {
      dataArray = usageTrackingOriginal;
    }
    const filteredData = filterMembers(dataArray, props.userGroupValue, props.includeCoreMember);
    let dataList: any = []; //for merging core-member and non-core member data
    filteredData.map((value: any, index: any, self: any) => {
      if (index === self.findIndex((t: any) => t.page_title == value.page_title)) {
        dataList.push({
          count_visits: value.count_visits,
          totaltimespent: value.totaltimespent,
          uniqueusers: value.uniqueusers,
          page_title: value.page_title,
        });
      } else {
        dataList.map((data: any) => {
          if (data.page_title === value.page_title) {
            data.count_visits = Number(data.count_visits) + Number(value.count_visits);
          }
        });
      }
    });
    if (dataList.length === 0) {
      setTableMsg(`No Data Available for  ${props.userGroupValue}`);
    }
    return dataList;
  };

  const flipButtonClick = () => {
    let flipPanel = document.querySelector('.page-level-usage .flip-panel');

    if (flipPanel?.classList.contains('rotate')) {
      flipPanel?.classList.remove('rotate');
      setFilterInvisible(false);
      setflipButtonText('Go To Table View');
    } else {
      flipPanel?.classList.add('rotate');
      setflipButtonText('Go To Chart View');
      setFilterInvisible(true);
    }
  };

  //  useEffect(() => {
  //     if (!showExpandOrClose) {
  //       document.getElementById('page-level-usage')?.classList.add('container-width-100');
  //     } else {
  //       document.getElementById('page-level-usage')?.classList.remove('container-width-100');
  //     }
  //   }, [showExpandOrClose]);

  return (
    <div className="usage-metric page-level-usage">
      <div className="metric-header no-left-field">
        <div className="flip-button" onClick={() => flipButtonClick()}>
          <span>{flipButtonText}</span>
          <img src={YRotate} alt="flip-icon"></img>
        </div>
      </div>
      <div className='metric-header'>
        <div>
          <label className="form-label">{props.date.displayValue}</label>
        </div>
        <div className="filters">
          {filterInVisible ?
            <div className="filters ">
              <FileDownloadIcon height={28} width={28} onClick={() => {
                setTriggerDownload(true);
              }} />
              {showExpandOrClose ? (
                <span
                  onClick={() => {
                    setShowExpandOrClose(false);
                  }}
                  className="expand-collapse-icon"
                >
                  <ExpandIcon className="expand-collapse-icon" /> {/*This classname somehow not rendering*/}
                </span>
              ) : (
                <span
                  onClick={() => {
                    setShowExpandOrClose(true);
                  }}
                >
                  <CloseIcon className="expand-collapse-icon" />
                </span>
              )}
            </div> : null}
        </div>
      </div>
      <div className="flip-panel">
        <div className="flip-panel-inner">
          <div className="front-panel">
            <PageUsageChart
              date={props.date}
              clearData={props.clearData}
              setClearData={props.setClearData}
              userGroupValue={props.userGroupValue}
              includeCoreMember={props.includeCoreMember}
            />
          </div>
          <div className="back-panel">
            {isLoading ? (
              <div className="adm-loader-container">
                <Loader />
              </div>
            ) : (
              <div className="table-container usage-metric-container-page">
                {usageTrackingData.length === 0 ? (
                  <p className="table-msg">{tableMsg}</p>
                ) : (
                  <AgGridTable
                    rowData={usageTrackingData}
                    columnDefs={columns}
                    matamoDate=""
                    matamoType=""
                    triggerdownload={triggerDownload}
                    setTriggerDownload={setTriggerDownload}
                    onExpandableClosed={() => setShowExpandOrClose(false)}
                    expandable={showExpandOrClose}
                    tableName="Page Level Usage"
                  />
                )}
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};
export default PageLevelUsage;
