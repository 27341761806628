interface Props {
  fill: string;
  classname?: string;
}

const UserAuditLogo = ({ fill, classname }: Props) => {
  return (
    <svg width="18" height="18" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M5.14285 9.5364C5.33211 9.09051 5.77398 8.77778 6.28889 8.77778H8.15556C8.67047 8.77778 9.11234 9.09051 9.3016 9.5364M8.46667 6.44445C8.46667 7.13174 7.90951 7.68889 7.22223 7.68889C6.53494 7.68889 5.97778 7.13174 5.97778 6.44445C5.97778 5.75716 6.53494 5.2 7.22223 5.2C7.90951 5.2 8.46667 5.75716 8.46667 6.44445ZM10.3333 7.22223C10.3333 8.94045 8.94045 10.3333 7.22223 10.3333C5.50401 10.3333 4.11111 8.94045 4.11111 7.22223C4.11111 5.50401 5.50401 4.11111 7.22223 4.11111C8.94045 4.11111 10.3333 5.50401 10.3333 7.22223Z"
        stroke={fill}
        strokeWidth="0.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M15 15L11.6167 11.6167M13.4444 7.22222C13.4444 10.6587 10.6587 13.4444 7.22222 13.4444C3.78578 13.4444 1 10.6587 1 7.22222C1 3.78578 3.78578 1 7.22222 1C10.6587 1 13.4444 3.78578 13.4444 7.22222Z"
        stroke={fill}
        strokeWidth="0.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
export default UserAuditLogo;
