import './index.scss';
import { MetricCard } from '@gitlab-rtsensing/component-library';
import UserLoginHistory from '../../components/usage-metric-charts/user-login-history';
import PageLevelUsage from '../../components/usage-metric-charts/page-level-usage';
import AvgTimeSpent from '../../components/usage-metric-charts/avg-time-spent';
import EntryModeTracking from '../../components/usage-metric-charts/entry-mode-tracking';
import VisitorLog from '../../components/usage-metric-charts/new-visitor-log';
import UnauthorizedTracking from '../../components/usage-metric-charts/unauthorized-tracking';
import KPI from '../../components/usage-metric-charts/kpi-at-top/kpi';
import DeviceKPI from '../../components/usage-metric-charts/kpi-at-top/device-kpi';
import { Button } from '@opsdti-global-component-library/amgen-design-system';
import Outlinks from '../../components/usage-metric-charts/outlinks';
import { apiResponse, camelToHyphen, hyphenToCamel } from '../../utility/commonMethods';

import { addDays, format, differenceInDays, endOfToday, endOfYesterday } from 'date-fns';
import React, { useState, useEffect, useContext, useRef } from 'react';
import { DateRangePicker } from 'react-date-range';
import 'react-date-range/dist/styles.css'; // main css file
import 'react-date-range/dist/theme/default.css'; // theme css file
import { AppContext } from '../../contexts/app-context';
import UserInPause from '../../components/usage-metric-charts/user-in-pause';
import Notification from '../../components/usage-metric-charts/notification';
import { useLocation } from 'react-router';
import { adminConstants } from '../../utility/constants';
import VisitChart from '../../components/usage-metric-charts/visit-chart';
import NewsLetterChart from '../../components/usage-metric-charts/newletter-chart';
import SpoVSExec from '../../components/usage-metric-charts/spo-vs-exec';

interface MatamoWindow extends Window {
  _paq?: Array<any>;
}

export const UsageMetric = (): JSX.Element => {
  const dateFormat = 'yyyy-MM-dd';
  const fromDate = format(addDays(new Date(), -7), dateFormat);
  const toDate = format(endOfToday(), dateFormat);
  const [selectedUserGroup, setSelectedUserGroup] = useState('All Sensing Users');
  const [resetFilter, setResetFilter] = useState(false);
  const { userFilter, setUserFilter, constInfo, userGroups, setUserGroups } = useContext(AppContext);
  const minDate = '2022-12-15'; //This is as per discussion on 20Apr23, for the last date range

  const [emptyDataUserMetric, setDataEmptyUserMetric] = useState(true);
  const [dateVisibilityUserMetric, setdateVisibilityUserMetric] = useState(false);
  const [dateValueUserMetric, setDateValueUserMetric] = useState({
    type: 'range',
    fromDate: fromDate,
    toDate: toDate,
    matamoType: 'range',
    matamoDate: fromDate + ',' + toDate,
    displayValue: 'From: ' + format(addDays(new Date(), -7), 'MM-dd-yyyy') + ' To: ' + format(endOfToday(), 'MM-dd-yyyy'),
  });

  const [internalUserDisable, setInternalUserDisable] = useState(true);
  const [internalUserCheck, setInternalUserCheck] = useState(true);

  const topTenUserRef = useRef(null);
  const deviceKpiRef = useRef<HTMLDivElement>(null);
  const userLoginHistoryRef = useRef<HTMLDivElement>(null);
  const averageTimeSpentByUserPerPageRef = useRef<HTMLDivElement>(null);
  const entryModeTrackingRef = useRef<HTMLDivElement>(null);
  const unauthorizedTrackingRef = useRef<HTMLDivElement>(null);
  const newVisitorLogRef = useRef<HTMLDivElement>(null);
  const outgoingLinksFromSensingRef = useRef<HTMLDivElement>(null);
  const pausedActivityByUserRef = useRef<HTMLDivElement>(null);
  const notificationsRef = useRef<HTMLDivElement>(null);
  const pageUsageChartRef = useRef<HTMLDivElement>(null);
  const pageLevelUsageRef = useRef<HTMLDivElement>(null);

  const refs: { [key: string]: React.RefObject<HTMLDivElement> } = {
    topTenUser: topTenUserRef,
    deviceKpi: deviceKpiRef,
    userLoginHistory: userLoginHistoryRef,
    averageTimeSpentByUserPerPage: averageTimeSpentByUserPerPageRef,
    entryModeTracking: entryModeTrackingRef,
    unauthorizedTracking: unauthorizedTrackingRef,
    newVisitorLog: newVisitorLogRef,
    outgoingLinksFromSensing: outgoingLinksFromSensingRef,
    pausedActivityByUser: pausedActivityByUserRef,
    notifications: notificationsRef,
    pageUsageChart: pageUsageChartRef,
    pageLevelUsage: pageLevelUsageRef,
  };

  const getRef = (key: string): React.RefObject<HTMLDivElement> | null => {
    return refs[key] || null;
  };

  const location = useLocation();

  const isInViewport = (element: HTMLDivElement) => {
    const rect = element.getBoundingClientRect();
    return (
      rect.top >= 0 &&
      rect.left >= 0 &&
      rect.bottom <= (window.innerHeight || document.documentElement.clientHeight) &&
      rect.right <= (window.innerWidth || document.documentElement.clientWidth)
    );
  };

  const scrollToSection = (): void => {
    if (location) {
      const currentRef: React.RefObject<HTMLDivElement> | null = getRef(hyphenToCamel(location.hash.slice(1)));

      if (currentRef && currentRef.current) {
        const isElementInView = isInViewport(currentRef.current);

        if (!isElementInView) {
          window.scroll({
            top: currentRef.current.getBoundingClientRect().top - 60,
            behavior: 'auto',
          });
        }
      }
    }
  };

  useEffect(() => {
    if (location.hash) {
      setTimeout(() => {
        scrollToSection();
      }, 500);
    }
  }, [location.hash]);

  const userSpecific = [
    {
      tiles: [
        {
          label: 'Visit Chart',
          refKey: 'userVisitChart',
          tile: <VisitChart userGroupValue={selectedUserGroup} includeCoreMember={internalUserCheck ? 'Y' : 'N'} />,
        },
      ],
    },
    {
      tiles: [
        {
          label: constInfo.get(adminConstants.ADMIN_METRIC_PAGE_LEVEL_USAGE),
          refKey: 'pageLevelUsage',
          tile: (
            <PageLevelUsage
              date={dateValueUserMetric}
              clearData={emptyDataUserMetric}
              setClearData={setDataEmptyUserMetric}
              userGroupValue={selectedUserGroup}
              includeCoreMember={internalUserCheck ? 'Y' : 'N'}
            />
          ),
        },
      ],
    },
    {
      tiles: [
        {
          label: constInfo.get(adminConstants.ADMIN_METRIC_TOP_TEN_USER),
          refKey: 'topTenUser',
          tile: (
            <KPI
              date={dateValueUserMetric}
              clearData={emptyDataUserMetric}
              setClearData={setDataEmptyUserMetric}
              resetFilter={resetFilter}
              userGroupValue={selectedUserGroup}
              includeCoreMember={internalUserCheck ? 'Y' : 'N'}
              primaryColor={constInfo.get(adminConstants.ADMIN_METRIC_BAR_COLOR_REGULAR)}
              highlightedColor={constInfo.get(adminConstants.ADMIN_METRIC_BAR_COLOR_HIGHLIGHT)}
            />
          ),
        },
      ],
    },

    {
      tiles: [
        {
          label: constInfo.get(adminConstants.ADMIN_METRIC_USER_LOGIN_HISTORY),
          refKey: 'userLoginHistory',
          tile: (
            <UserLoginHistory
              date={dateValueUserMetric}
              clearData={emptyDataUserMetric}
              setClearData={setDataEmptyUserMetric}
              userGroupValue={selectedUserGroup}
              includeCoreMember={internalUserCheck ? 'Y' : 'N'}
            />
          ),
        },
      ],
    },

    {
      tiles: [
        {
          label: constInfo.get(adminConstants.ADMIN_METRIC_DEVICE_KPI),
          refKey: 'deviceKpi',
          tile: (
            <DeviceKPI
              date={dateValueUserMetric}
              clearData={emptyDataUserMetric}
              setClearData={setDataEmptyUserMetric}
              userGroupValue={selectedUserGroup}
              includeCoreMember={internalUserCheck ? 'Y' : 'N'}
            />
          ),
        },
      ],
    },

    {
      tiles: [
        {
          label: constInfo.get(adminConstants.ADMIN_METRIC_ENTRYMODE_TRACKING),
          refKey: 'entryModeTracking',
          tile: (
            <EntryModeTracking
              date={dateValueUserMetric}
              clearData={emptyDataUserMetric}
              setClearData={setDataEmptyUserMetric}
              userGroupValue={selectedUserGroup}
              includeCoreMember={internalUserCheck ? 'Y' : 'N'}
            />
          ),
        },
      ],
    },
    {
      tiles: [
        {
          label: constInfo.get(adminConstants.ADMIN_METRIC_UNAUTHORIZED_TRACKING),
          refKey: 'unauthorizedTracking',
          tile: (
            <UnauthorizedTracking
              date={dateValueUserMetric}
              clearData={emptyDataUserMetric}
              setClearData={setDataEmptyUserMetric}
              userGroupValue={selectedUserGroup}
              includeCoreMember={internalUserCheck ? 'Y' : 'N'}
            />
          ),
        },
      ],
    },
    {
      tiles: [
        {
          label: constInfo.get(adminConstants.ADMIN_METRIC_NEW_VISITOR_LOG),
          refKey: 'newVisitorLog',
          tile: (
            <VisitorLog
              date={dateValueUserMetric}
              clearData={emptyDataUserMetric}
              setClearData={setDataEmptyUserMetric}
              userGroupValue={selectedUserGroup}
              includeCoreMember={internalUserCheck ? 'Y' : 'N'}
            />
          ),
        },
      ],
    },
    {
      tiles: [
        {
          label: constInfo.get(adminConstants.ADMIN_METRIC_OUTGOING_LINKS),
          refKey: 'outgoingLinksFromSensing',
          tile: (
            <Outlinks
              date={dateValueUserMetric}
              clearData={emptyDataUserMetric}
              setClearData={setDataEmptyUserMetric}
              userGroupValue={selectedUserGroup}
              includeCoreMember={internalUserCheck ? 'Y' : 'N'}
            />
          ),
        },
      ],
    },
    {
      tiles: [
        {
          label: constInfo.get(adminConstants.ADMIN_METRIC_PAUSED_ACTIVITY_USER),
          refKey: 'pausedActivityByUser',
          tile: <UserInPause userGroupValue={selectedUserGroup} includeCoreMember={internalUserCheck ? 'Y' : 'N'} />,
        },
      ],
    },
    {
      tiles: [
        {
          label: constInfo.get(adminConstants.ADMIN_METRIC_NOTIFICATION),
          refKey: 'notifications',
          tile: (
            <Notification
              date={dateValueUserMetric}
              clearData={emptyDataUserMetric}
              setClearData={setDataEmptyUserMetric}
              userGroupValue={selectedUserGroup}
              includeCoreMember={internalUserCheck ? 'Y' : 'N'}
            />
          ),
        },
      ],
    },
    {
      tiles: [
        {
          label: 'Sensing Newsletter Views',
          refKey: 'newsletterChart',
          tile: <NewsLetterChart />,
        },
      ],
    },
    {
      tiles: [
        {
          label: 'SPO vs Exec views',
          refKey: 'SPO_vs_Exec_views',
          tile: <SpoVSExec date={dateValueUserMetric} clearData={emptyDataUserMetric} setClearData={setDataEmptyUserMetric} />,
        },
      ],
    },
    
    {
      tiles: [
        {
          label: constInfo.get(adminConstants.ADMIN_METRIC_AVG_TIME_SPENT),
          refKey: 'averageTimeSpentByUserPerPage',
          tile: (
            <AvgTimeSpent
              date={dateValueUserMetric}
              clearData={emptyDataUserMetric}
              setClearData={setDataEmptyUserMetric}
              userGroupValue={selectedUserGroup}
              includeCoreMember={internalUserCheck ? 'Y' : 'N'}
            />
          ),
        },
      ],
    },
  ];

  const [stateUserMetric, setStateUserMetric] = useState({
    selection: {
      startDate: new Date(),
      endDate: addDays(new Date(), -7),
      key: 'selection',
    },
    compare: {
      startDate: new Date(),
      endDate: addDays(new Date(), 0),
      key: 'compare',
    },
  });

  const getUserGroups = async () => {
    const payload = {};
    try {
      const res = await apiResponse('get', 'get-user-group', [], payload);
      if (res?.data?.status === 'SUCCESS') {
        if (res?.data?.data?.userGroups) {
          if (res.data.data.userGroups.length === 0) {
          } else {
            res.data.data.userGroups.sort((a: any, b: any) => {
              if (a < b) {
                return -1;
              } else if (a > b) {
                return 1;
              } else {
                return 0;
              }
            });
            setUserGroups(res.data.data.userGroups);
          }
        }
      } else {
        console.log(res);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const onDateChange = (item: any, name: string) => {
    let dateObj: any = {};
    if (item.selection === undefined) return;

    if (differenceInDays(item.selection.startDate, item.selection.endDate) === 0) {
      if (differenceInDays(item.selection.startDate, endOfToday()) === 0) {
        dateObj.type = 'today';
        dateObj.toDate = '';
      } else if (differenceInDays(item.selection.startDate, endOfYesterday()) === 0) {
        dateObj.type = 'yesterday';
        dateObj.toDate = '';
      } else {
        dateObj.type = 'singleday';
        dateObj.toDate = format(item.selection.startDate, dateFormat);
      }
      dateObj.displayValue = format(item.selection.startDate, 'MM-dd-yyyy');
      dateObj.fromDate = '';
      dateObj.matamoType = 'day';
      dateObj.matamoDate = format(item.selection.startDate, dateFormat);
    } else {
      dateObj.type = 'range';
      dateObj.matamoType = 'range';
      dateObj.displayValue = 'From: ' + format(item.selection.startDate, 'MM-dd-yyyy') + ' To: ' + format(item.selection.endDate, 'MM-dd-yyyy');
      dateObj.fromDate = format(item.selection.startDate, dateFormat);
      dateObj.toDate = format(item.selection.endDate, dateFormat);
      dateObj.matamoDate = dateObj.fromDate + ',' + dateObj.toDate;
    }
    if (name === 'user-metric-datepicker') {
      setDateValueUserMetric(dateObj);
      setStateUserMetric({ ...stateUserMetric, ...item });
    }
  };

  useEffect(() => {
    setResetFilter(false);
  }, [userFilter]);

  useEffect(() => {
    setResetFilter(true);
    if (selectedUserGroup === 'All Sensing Users') {
      setInternalUserDisable(true);
    } else {
      setInternalUserDisable(false);
      setInternalUserCheck(false);
    }
  }, [selectedUserGroup]);

  useEffect(() => {
    window.addEventListener('click', onWindowClick);
    return () => {
      window.removeEventListener('click', onWindowClick);
    };
  }, []);

  const onWindowClick = (e: any) => {
    if (e.target?.classList.contains('metric-input') || e.target?.closest('.date-container')) {
    } else {
      setdateVisibilityUserMetric(false);
    }
  };

  useEffect(() => {
    if (userGroups.length === 0) {
      getUserGroups();
    }
  }, [userGroups]);

  const onDataInputClickUserMetric = () => {
    setdateVisibilityUserMetric(!dateVisibilityUserMetric);
  };

  return (
    <>
      <div className="usage-metric-page">
        <>
          <div className="usage-metric-other-container">
            <>
              {userSpecific.map((tiles, index) => {
                return (
                  <React.Fragment key={index}>
                    {tiles.tiles.map(tile => {
                      if (index === 0) {
                        let metricGrpName = 'user-metric-datepicker';
                        return (
                          <React.Fragment key={index}>
                            {index === 0 && (
                              <>
                                <div className="usage-metric usage-metric-header">
                                  <div className="range-selection-apply d-flex">
                                    <input
                                      type="text"
                                      onClick={onDataInputClickUserMetric}
                                      value={dateValueUserMetric.displayValue}
                                      className="metric-input range-picker"
                                      readOnly
                                    ></input>
                                    <Button
                                      text="Apply"
                                      className="apply-btn"
                                      onClick={() => {
                                        setdateVisibilityUserMetric(false);
                                        setDataEmptyUserMetric(true);
                                      }}
                                      type="primary"
                                    ></Button>
                                  </div>
                                  {dateVisibilityUserMetric && (
                                    <>
                                      <DateRangePicker
                                        className="date-container"
                                        months={1}
                                        minDate={new Date(minDate)}
                                        maxDate={addDays(new Date(), 0)}
                                        direction="vertical"
                                        showMonthAndYearPickers={true}
                                        showDateDisplay={false}
                                        showPreview={true}
                                        onChange={(item: any) => onDateChange(item, metricGrpName)}
                                        onShownDateChange={(item: any) => onDateChange(item, metricGrpName)}
                                        scroll={{ enabled: false }}
                                        editableDateInputs={true}
                                        ranges={[stateUserMetric.selection]}
                                      />
                                    </>
                                  )}
                                  <div className="d-flex">
                                    <div className={!internalUserDisable ? 'internal-user disabled' : 'internal-user'}>
                                      <input
                                        type="checkbox"
                                        id="internal-user"
                                        disabled={!internalUserDisable}
                                        checked={internalUserCheck}
                                        onChange={() => {
                                          setInternalUserCheck(!internalUserCheck);
                                          setUserFilter({
                                            userId: '',
                                            filter: false,
                                            fullname: '',
                                          });
                                          setResetFilter(true);
                                        }}
                                      ></input>
                                      <label htmlFor="internal-user" className="ops-text ops-text-dark ops-fw-bold ops-fs-4">
                                        Include Sensing Team Members
                                      </label>
                                    </div>
                                    <div className="user-group-multiselect">
                                      <span className="ops-text ops-text-dark ops-fw-bold ops-fs-4 ">User Group</span>
                                      <select
                                        onChange={e => {
                                          setSelectedUserGroup(e.target.value);
                                          setUserFilter({
                                            userId: '',
                                            filter: false,
                                            fullname: '',
                                          });
                                          setResetFilter(true);
                                        }}
                                        value={selectedUserGroup}
                                      >
                                        {userGroups.map((option: string) => (
                                          <option className="option-style" key={option} value={option}>
                                            {option}
                                          </option>
                                        ))}
                                      </select>
                                    </div>
                                  </div>
                                </div>
                              </>
                            )}

                            <div key={index} id={camelToHyphen(tile.refKey)} ref={getRef(tile.refKey)} className="adm-containers">
                              <MetricCard className="card" key={tile.refKey}>
                                <>
                                  <MetricCard.Header>
                                    <MetricCard.Title title={tile.label} />
                                  </MetricCard.Header>
                                  <MetricCard.Content className="nav adm-section-name">
                                    <>{tile.tile}</>
                                  </MetricCard.Content>
                                </>
                              </MetricCard>
                            </div>
                          </React.Fragment>
                        );
                      } else {
                        return (
                          <div key={index} id={camelToHyphen(tile.refKey)} ref={getRef(tile.refKey)} className="adm-containers">
                            <MetricCard className="card" key={tile.refKey}>
                              <>
                                <MetricCard.Header>
                                  <MetricCard.Title title={tile.label} />
                                </MetricCard.Header>
                                <MetricCard.Content className="nav adm-section-name">
                                  <>{tile.tile}</>
                                </MetricCard.Content>
                              </>
                            </MetricCard>
                          </div>
                        );
                      }
                    })}
                  </React.Fragment>
                );
              })}
            </>
          </div>
        </>
      </div>
    </>
  );
};

export default UsageMetric;
