import React, { useState, useEffect } from 'react';
import { useTable } from 'react-table';
import './index.scss';
import { toast } from 'react-toastify';
import { MultiSelect } from 'react-multi-select-component';
import { DataTableProps, RequestData } from '../usage-metric-charts/usage-metric-interface';
import { APPROVED } from '../release-notes/constants';

const DataTable = (props: DataTableProps) => {
  let userName: string = '';
  (() => {
    let idToken = JSON.parse(localStorage.getItem('sensing-okta-token') || '{}')?.idToken?.claims;
    userName = idToken.email.split('@')[0];
  })();

  const { columns, component, handleTaskClick, AD_Group_List } = props;
  const [data, setData] = useState<any>([]);
  const [nextLevel, setNextLevel] = useState([]);
  const [actionSelected, setActionSelected] = useState([]);
  const [request, setRequest] = useState(new Array<RequestData>());
  const [tileSelected, setTileSelected] = useState(new Array<[]>());
  const [adGroup, setAdGroup] = useState(new Array<[]>());
  const [emailOptOut, setEmailOptOut] = useState([]);

  const NextLevelOption: any = [
    {
      label: 'Feature Owner Approval',
      value: 'feature_owner_approval',
    },
    {
      label: 'Second Level Approval',
      value: 'second_level_approval',
    },
  ];

  const { headerGroups, rows, prepareRow } = useTable({
    // @ts-ignore
    columns,
    data,
  });

  useEffect(() => {
    if (data.length === 0) {
      setData(props.data);
    }
  }, []);

  const handleLevelChange = (e: any, index: any) => {
    const newReq: any = [...actionSelected];
    const newLevel: any = [...nextLevel];
    newLevel[index] = e.target.value;
    setNextLevel(newLevel);

    if (newReq[index] === APPROVED && newLevel[index] === '') {
      newReq[index] = undefined;
      setActionSelected(newReq);
    }
  };

  const handleTileChange = (selectedObj: any, index: any) => {
    const newReq: any = [...actionSelected];
    const newTile: any = [...tileSelected];
    newTile[index] = selectedObj;
    setTileSelected(newTile);
    if (newReq[index] === APPROVED && newTile[index].length === 0) {
      newReq[index] = undefined;
      setActionSelected(newReq);
    }
  };

  const handleEmailChecbox = (e: any, index: any, requestId: any) => {
    const newOption: any = [...emailOptOut];
    newOption[index] = e.target.checked;
    setEmailOptOut(newOption);
    let updateData = data;
    updateData.map((row: RequestData) => {
      if (row.request_id === requestId) {
        row.notify_off = e.target.checked;
      }
    });
    setData(updateData);
    let updateRequestData = request;
    updateRequestData.map((row: RequestData) => {
      if (row.request_id === requestId) {
        row.notify_off = e.target.checked;
      }
    });
    setRequest(updateRequestData);
  };

  const handleRequest = (e: any, index: any, rowData: any) => {
    const newReq: any = [...actionSelected];
    const newADGroup: any = [...adGroup];
    if (component === 'allocation') {
      newADGroup[index] = e;
      setAdGroup(newADGroup);
    } else {
      // for making radio button act as checkbox
      if (newReq[index] !== e.target.value) {
        newReq[index] = e.target.value;
      } else {
        newReq[index] = undefined;
      }

      //for form-validation
      if (component === 'level_1' && newReq[index] === APPROVED) {
        if (nextLevel[index] === undefined || nextLevel[index] === '') {
          toast.warn('Please select  NEXT LEVEL.');
          return;
        }
        if (tileSelected[index] === undefined) {
          toast.warn('Please select TILE');
          return;
        } else if (tileSelected[index] !== undefined && tileSelected[index].length === 0) {
          toast.warn('Please select TILE');
          return;
        }
      }

      setActionSelected(newReq);
    }

    const reqData = [...request];
    const existData = reqData.find(data => data.request_id === rowData.request_id && data.item_no === rowData.item_no);
    if (existData === undefined) {
      reqData.push({
        request_id: rowData.request_id,
        item_no: rowData.item_no,
        approve_by: userName,
        approval_level: component,
        next_level: component === 'level_1' ? nextLevel[index] : rowData.next_level,
        approval_reject: component === 'allocation' ? '' : newReq[index],
        tile_name:
          component === 'level_1' ? (tileSelected[index] !== undefined ? tileSelected[index].map((obj: any) => obj.label).join(', ') : '') : rowData.tile_name,
        ad_group: component === 'allocation' ? (newADGroup[index] !== undefined ? newADGroup[index].map((obj: any) => obj.label).join(', ') : '') : '',
        notify_off: rowData.notify_off,
      });
    } else if (existData !== undefined) {
      reqData.map(data => {
        if (data.request_id === rowData.request_id && data.item_no === rowData.item_no) {
          data.request_id = rowData.request_id;
          data.item_no = rowData.item_no;
          data.approve_by = userName;
          data.approval_level = component;
          data.next_level = component === 'level_1' ? nextLevel[index] : rowData.next_level;
          data.approval_reject = component === 'allocation' ? '' : newReq[index];
          data.tile_name =
            component === 'level_1'
              ? tileSelected[index] !== undefined
                ? tileSelected[index].map((obj: any) => obj.label).join(', ')
                : ''
              : rowData.tile_name;
          data.ad_group = component === 'allocation' ? (newADGroup[index] !== undefined ? newADGroup[index].map((obj: any) => obj.label).join(', ') : '') : '';
          data.notify_off = rowData.notify_off;
        }
      });
    }

    setRequest(reqData);
  };

  useEffect(() => {
    //default pipeline portfolio_valuations_metrics select when request is submitted from pipeline page
    if (component === 'level_1') {
      let pipeLineArr = props.data.filter((obj: any) => obj.page_name.toLowerCase() === 'pipeline');

      const newTile: any = [];
      for (let i = 0; i < pipeLineArr.length; i++) {
        newTile[pipeLineArr[i].index] = [
          {
            value: pipeLineArr[i].default_tile,
            label: pipeLineArr[i].default_tile,
            pageName: pipeLineArr[i].page_name.toLowerCase(),
            checked: pipeLineArr[i].default_tile !== '' ? true : false,
          },
        ];
      }
      setTileSelected(newTile);
    }
  }, []);

  useEffect(() => {
    if (component === 'level_1') {
      let allApprovedOpt = actionSelected.filter((val: string) => val === APPROVED);
      let allRejectOpt = actionSelected.filter((val: string) => val === 'reject');
      if (allRejectOpt.length > 0 && allApprovedOpt.length === 0) {
        props.setSubmitDisable(false);
      } else if (allApprovedOpt.length === 0) {
        props.setSubmitDisable(true);
      } else if (tileSelected.filter((obj: []) => obj?.length > 0).length === 0) {
        props.setSubmitDisable(true);
      } else if (nextLevel.filter((val: string) => val !== '').length === 0) {
        props.setSubmitDisable(true);
      } else {
        props.setSubmitDisable(false);
      }
    }
  }, [tileSelected, actionSelected, nextLevel]);

  useEffect(() => {
    //updating final request list
    if (component === 'level_1' || component === 'level_2' || component === 'feature_owner' || component === 'allocation') {
      const finalData = request.filter(action => action.approval_reject !== undefined);
      handleTaskClick(finalData);
    }
    if (component === 'allocation') {
      const finalData = request.filter(data => data.ad_group !== '');
      handleTaskClick(finalData);
    }
  }, [request]);

  let columnChecks = ['Name', 'Function', 'Role', 'Current Access', 'Email Opt-Out'];
  var currentRequestId = '';

  return (
    <React.Fragment>
      <div className="table-scroll-container ops-fs-5">
        <table className="data-table" id={component}>
          <thead>
            {headerGroups.map(headerGroup => (
              <tr>
                {headerGroup.headers.map(column => (
                  <th>{column.render('Header')}</th>
                ))}
              </tr>
            ))}
          </thead>

          <tbody>
            {rows.map((row: any, index: any) => {
              prepareRow(row);
              return (
                <tr>
                  {row.cells.map((cell: any) => {
                    if (cell.column.Header === 'Next Level') {
                      return (
                        <td>
                          <div>
                            <select
                              className="select-filter"
                              key={index + row.original.item_no}
                              value={nextLevel[index]}
                              onChange={(selected: any) => {
                                handleLevelChange(selected, index);
                              }}
                            >
                              <option value="">Select</option>
                              {NextLevelOption.map((option: any) => (
                                <option className="option-style" key={option.value} value={option.value}>
                                  {option.label}
                                </option>
                              ))}
                            </select>
                            {cell.render('Cell')}
                          </div>
                        </td>
                      );
                    }
                    if (cell.column.Header === 'Action') {
                      return (
                        <td>
                          <div className="d-flex">
                            <div className="form-check m-1">
                              <input
                                className="form-check-input"
                                type="radio"
                                value={APPROVED}
                                id={APPROVED + index}
                                name={'action-group' + index}
                                onClick={(e: any) => {
                                  handleRequest(e, index, row.original);
                                }}
                                checked={actionSelected[index] === APPROVED ? true : false}
                                onChange={(e: any) => {}}
                              />
                              <label htmlFor={APPROVED + index}>Approve</label>
                            </div>
                            <div className="form-check m-1">
                              <input
                                className="form-check-input"
                                type="radio"
                                value="reject"
                                id={'reject' + index}
                                name={'action-group' + index}
                                onClick={(e: any) => {
                                  handleRequest(e, index, row.original);
                                }}
                                checked={actionSelected[index] === 'reject' ? true : false}
                                onChange={(e: any) => {}}
                              />
                              <label htmlFor={'reject' + index}>Reject</label>
                            </div>
                          </div>
                        </td>
                      );
                    }
                    if (cell.column.Header === 'AD-Groups') {
                      return (
                        <td>
                          <MultiSelect
                            options={AD_Group_List.map((option: any, indx: number) => {
                              return {
                                id: indx,
                                value: option,
                                label: option,
                              };
                            })}
                            value={adGroup[index] ? adGroup[index] : []}
                            className="dropdown-container"
                            onChange={(selected: any) => {
                              handleRequest(selected, index, row.original);
                            }}
                            labelledBy="Select"
                            disableSearch={true}
                          />
                        </td>
                      );
                    }
                    if (component === 'level_1' && cell.column.Header === 'Tile') {
                      return (
                        <td>
                          <MultiSelect
                            options={row.original.tile_name !== '' ? row.original.tile_name : []}
                            value={tileSelected[index] ? tileSelected[index].filter((obj: any) => obj.checked) : []}
                            className="dropdown-container"
                            onChange={(selected: any) => {
                              selected.map((obj: any) => Object.assign(obj, { checked: true }));
                              //Don't need to worry about checked false case, since only true case will exist in SetTileSelected function
                              handleTileChange(selected, index);
                            }}
                            labelledBy="Select"
                            disableSearch={true}
                            disabled={tileSelected[index]?.filter((obj: any) => obj.checked && obj.pageName === 'pipeline').length > 0 ? true : false}
                          />
                          {/* [{cell.render("Cell")}] */}
                        </td>
                      );
                    }
                    if (cell.column.Header === 'Page' && row.original.page_name === 'External') {
                      return (
                        <td className="is-external" key={index + row.original.page_name}>
                          {row.original.page_name}
                        </td>
                      );
                    }
                    if (component === 'User-ad-groups' && cell.column.Header === 'AD-Group List') {
                      return <td key={index + row.original}>{row.original}</td>;
                    }

                    if (component === 'level_1' && columnChecks.indexOf(cell.column.Header) > -1) {
                      if (row.original.row_span_count > 1) {
                        currentRequestId = row.original.request_id;
                        if (cell.column.Header === 'Email Opt-Out') {
                          return (
                            <td key={index + row.original.request_id} rowSpan={row.original.row_span_count}>
                              <input
                                type={'checkbox'}
                                id={'email_opt_out' + row.original.item_no}
                                key={'email_opt_out' + row.original.item_no}
                                checked={emailOptOut[index]}
                                defaultChecked={row.original.notify_off}
                                onChange={(selected: any) => {
                                  handleEmailChecbox(selected, index, row.original.request_id);
                                }}
                                className="level1_checkbox"
                              ></input>
                            </td>
                          );
                        } else {
                          return (
                            <td key={index + row.original.item_no} rowSpan={row.original.row_span_count}>
                              {cell.render('Cell')}
                            </td>
                          );
                        }
                      } else if (row.original.row_span_count === 1 && currentRequestId === row.original.request_id) {
                        return <React.Fragment key={index + row.original.row_span_count}></React.Fragment>;
                      } else {
                        if (cell.column.Header === 'Email Opt-Out') {
                          return (
                            <td key={index + row.original.request_id} rowSpan={row.original.row_span_count}>
                              <input
                                type={'checkbox'}
                                id={'email_opt_out' + row.original.item_no}
                                key={'email_opt_out' + row.original.item_no}
                                checked={emailOptOut[index]}
                                defaultChecked={row.original.notify_off}
                                onChange={(selected: any) => {
                                  handleEmailChecbox(selected, index, row.original.request_id);
                                }}
                                className="level1_checkbox"
                              ></input>
                            </td>
                          );
                        } else {
                          return <td key={index + row.original.item_no}>{cell.render('Cell')}</td>;
                        }
                      }
                    }
                    if ((component === 'level_2' || component === 'feature_owner') && cell.column.Header === 'Is Email Opt-Out?') {
                      if (row.original.notify_off === false) {
                        return <td key={index + row.original.item_no}>No</td>;
                      } else {
                        return <td key={index + row.original.item_no}>Yes</td>;
                      }
                    }
                    return <td>{cell.render('Cell')}</td>;
                  })}
                </tr>
              );
            })}
          </tbody>
        </table>
        {data.length === 0 && <div className="no-data-row ops-fs-4">No Data Available</div>}
      </div>
    </React.Fragment>
  );
};

export default DataTable;
