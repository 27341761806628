export const Level2_Table_Header = [
  {
    Header: 'Name',
    accessor: 'name',
  },
  {
    Header: 'Function',
    accessor: 'function',
  },
  {
    Header: 'Role',
    accessor: 'role',
  },
  {
    Header: 'Current Access',
    accessor: 'current_access',
  },
  {
    Header: 'Is Email Opt-Out?',
    accessor: 'notify_off',
  },
  {
    Header: 'Page',
    accessor: 'page_name',
  },
  {
    Header: 'Tile',
    accessor: 'tile_name',
  },
  {
    Header: 'Action',
    accessor: 'action',
  },
];
