import { useState } from 'react';

import { OpsButton, PlusIcon } from '@gitlab-rtsensing/component-library';
import ReleaseNotesList from '../release-notes-list';
import ReleaseNotesModal from '../release-notes-modal';
import StatusDropdown from '../status-filter-dropdown';
import ToggleButton from '../toggle-button';
import WorkstreamDropdown from '../workstream-filter-dropdown';

import { useReleaseNotesContext } from '../../../contexts/release-note-context';

import './index.scss';

export default function ReleaseNotesContainer() {
  const { setInitialForm, setIsPublicView, isPublicView } = useReleaseNotesContext();
  const [modalOpened, setModalOpened] = useState<boolean>(false);

  function viewNote() {
    setModalOpened(true);
  }

  function addNote() {
    setInitialForm(0);
    setModalOpened(true);
  }

  return (
    <div className="release-notes-container">
      <div className="release-notes-container__header">
        <div className="release-notes-container__view-options">
          <ToggleButton leftLabel={'Admin View'} rightLabel={'Public View'} onToggle={bool => setIsPublicView(bool)} />
          <WorkstreamDropdown />
          <StatusDropdown />
        </div>
        {!isPublicView && (
          <OpsButton
            label="Create Note"
            type="primary"
            className="add-release-note-button"
            onClick={addNote}
            rightIcon={<PlusIcon fill="var(--ops-white)" height="14px" width="14px" />}
          />
        )}
      </div>
      <ReleaseNotesList viewNote={viewNote} />
      <ReleaseNotesModal opened={modalOpened} setModalOpened={setModalOpened} />
    </div>
  );
}
