import { Loader } from '@gitlab-rtsensing/component-library';
import { Button, Modal } from '@opsdti-global-component-library/amgen-design-system';
import './index.scss';
import { useState } from 'react';
import { apiResponse } from '../../../utility/commonMethods';
import { toast } from 'react-toastify';
import { DescriptionModalProps, DescriptionObjectUpdate, DescriptionType } from '../user-audit-interface';

export default function DescriptionModal(props: DescriptionModalProps): JSX.Element {
  const { opened, descriptionModalClosed, descriptionData, setDescriptionData, currentToolTip } = props;
  const descriptionArray = Array.from(descriptionData.values());
  const [isLoading, setIsLoading] = useState(false);
  const [inputDescription, setInputDescription] = useState<{ [key: string]: string }>(() => {
    return Object.fromEntries(descriptionArray.map(data => [data.ad_group, data.description]));
  });
  const [inputValUpdate, setInputValUpdate] = useState<{ [key: string]: string }>();

  descriptionArray.sort((a, b) => {
    if (a.ad_group === currentToolTip) {
      return -1;
    }
    return 1;
  });

  const updateDescriptionEvent = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    let inputId: string = e.target.getAttribute('data-input-id') || '';
    let inputValue: string = e.target.value;

    setInputDescription(Object.assign({}, inputDescription, { [inputId]: inputValue }));
    setInputValUpdate(Object.assign({}, inputValUpdate, { [inputId]: inputValue }));
  };

  const submitAdGrpDescription = () => {
    let submitObj = [];
    for (let key in inputValUpdate) {
      submitObj.push({
        ad_group: key,
        description: window.btoa(inputValUpdate[key]),
      });
    }
    updateDescription(submitObj);
  };

  /*
   ** @description: API Call to update the description
   **/

  const updateDescription = async (descriptionObj: DescriptionObjectUpdate[]) => {
    const payload = {
      ad_des_list: descriptionObj,
    };
    try {
      setIsLoading(true);
      const res = await apiResponse('post', 'update-description-info', [], payload);
      if (res?.data.status === 'SUCCESS') {
        setIsLoading(false);
        toast.success('Description has been updated');
        descriptionModalClosed(false);
        setDescriptionData([]);
      } else {
        console.log('Description Update API Failed');
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <Modal
      className="description-modal"
      open={opened}
      maskCloseable={false}
      onCancel={() => descriptionModalClosed(false)}
      title={
        <span className="description-modal-header">
          <h3 className="description-modal-title">Update Description</h3>
        </span>
      }
      width={500}
    >
      <div className="description-modal-body">
        {isLoading ? (
          <div className="adm-loader-container">
            <Loader />
          </div>
        ) : (
          <div className="description-modal-table-container">
            <table className="usage-metric-table">
              <thead>
                <tr>
                  <th>AD Group</th>
                  <th>Description</th>
                </tr>
              </thead>
              <tbody>
                <>
                  {descriptionArray.map((data: DescriptionType, indx) => {
                    if (currentToolTip === data.ad_group) {
                      return (
                        <tr key={indx}>
                          <td>{data.ad_group}</td>
                          <td>
                            <textarea
                              className="description-textarea"
                              value={inputDescription[data.ad_group]}
                              placeholder="Enter Description"
                              autoFocus={true}
                              data-input-id={data.ad_group}
                              onChange={e => updateDescriptionEvent(e)}
                            />
                          </td>
                        </tr>
                      );
                    } else {
                      return (
                        <tr key={indx}>
                          <td>{data.ad_group}</td>
                          <td>
                            <textarea
                              className="description-textarea"
                              value={inputDescription[data.ad_group]}
                              placeholder="Enter Description"
                              data-input-id={data.ad_group}
                              onChange={e => updateDescriptionEvent(e)}
                            />
                          </td>
                        </tr>
                      );
                    }
                  })}
                </>
              </tbody>
            </table>
          </div>
        )}
        {isLoading ? <></> : <Button text="Save" type="secondary" className="save-btn" onClick={submitAdGrpDescription}></Button>}
      </div>
    </Modal>
  );
}
