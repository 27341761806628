import { useEffect, useState } from 'react';
import './index.scss';
import { OpsTypography } from '@gitlab-rtsensing/component-library';

interface IToggleButtonProps {
  leftLabel: string;
  rightLabel: string;
  onToggle: (arg0: boolean) => void;
}

export default function ToggleButton({ leftLabel, rightLabel, onToggle }: IToggleButtonProps) {
  const [isChecked, setIsChecked] = useState(false);

  function handleClick() {
    setIsChecked(prevState => !prevState);
  }

  useEffect(() => {
    onToggle(isChecked);
  }, [isChecked]);

  return (
    <label className="toggleSwitch nolabel">
      <input type="checkbox" defaultChecked={isChecked} onClick={handleClick} />
      <a></a>
      <span>
        <OpsTypography variant={'label'} elementTag={'span'} variantWeight={700} className="left-span" children={leftLabel.toUpperCase()} />
        <OpsTypography variant={'label'} elementTag={'span'} variantWeight={700} className="right-span" children={rightLabel.toUpperCase()} />
      </span>
    </label>
  );
}
