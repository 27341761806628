const WORKDAY = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48" width="30px" height="29px">
      <circle cx="24" cy="24" r="20" fill="#1565c0" />
      <path
        fill="#ffa726"
        d="M33.0166,18.50049a1.5,1.5,0,0,1-1.42334-1.02735,8.00389,8.00389,0,0,0-15.18652,0,1.49992,1.49992,0,1,1-2.84668-.94628,11.00366,11.00366,0,0,1,20.87988,0,1.50147,1.50147,0,0,1-1.42334,1.97363Z"
      />
      <path
        fill="#fff"
        d="M13.194,22.81423a.65874.65874,0,0,1,.51548-.305c.65616-.00629,1.31424-.02072,1.96992.00962a.68051.68051,0,0,1,.61088.61185c.64507,2.62324,1.2786,5.25032,1.92078,7.87442.18837.81032.41769,1.61246.54054,2.43674.1267.74048.15176,1.492.27219,2.23393a28.17225,28.17225,0,0,1,.59979-3.15891q1.25957-4.69792,2.51962-9.39432a.77214.77214,0,0,1,.75829-.61086c.73035-.01838,1.46214-.0058,2.1925-.0058a.87022.87022,0,0,1,.90521.61185q.94089,3.42465,1.87167,6.85547a41.46809,41.46809,0,0,1,1.09024,4.45337c.0592.41816.117.8373.20325,1.25258a62.65347,62.65347,0,0,1,1.3543-7.04286c.41674-1.8586.82422-3.71917,1.24577-5.57789a.75285.75285,0,0,1,.8051-.55067c.59063-.00333,1.18225-.02023,1.7719.00974a.513.513,0,0,1,.4282.70965Q32.992,29.78655,31.21815,36.34882a.76555.76555,0,0,1-.727.63014c-.91536.02987-1.83258.00722-2.74794.012a.66325.66325,0,0,1-.73283-.501c-.81941-3.00715-1.62253-6.01961-2.43429-9.02913a20.41253,20.41253,0,0,1-.53621-3.20907,26.76961,26.76961,0,0,1-.48079,2.87522q-1.24656,4.648-2.49987,9.29794c-.09394.502-.66772.60221-1.09456.56944-.85127-.01542-1.70254.01976-2.55286-.01542a.61637.61637,0,0,1-.59111-.52125q-1.79214-6.5334-3.58142-13.06776a3.4521,3.4521,0,0,1-.04528-.57571h0"
      />
    </svg>
  );
};
export default WORKDAY;
