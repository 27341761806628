import React from 'react';
import { useState, useEffect, useMemo } from 'react';
import './index.scss';
import { apiResponse, rowSpanning } from '../../utility/commonMethods';
import { toast } from 'react-toastify';
import { Loader } from '@gitlab-rtsensing/component-library';
import { Button } from '@opsdti-global-component-library/amgen-design-system';
import { remove_user_logs_table } from './accessLogTables';
import { RowSpanType } from '../usage-metric-charts/usage-metric-interface';
import { MultiSelect } from 'react-multi-select-component';
import { DateRangePicker } from 'react-date-range';
import { addDays, format, differenceInDays, endOfToday } from 'date-fns';

type DropDownValType = {
  id: number | string;
  value: string;
  label: string;
};

const RemoveUserLogs: React.FC = () => {
  const dateFormat = 'yyyy-MM-dd';
  const [dateVisibility, setdateVisibility] = useState(false);
  const removeUserLogsColumns = useMemo(() => remove_user_logs_table, []);
  const [removeUserLogsData, setRemoveUserLogsData] = useState(Array<RowSpanType>);
  const [isLoading, setLoading] = useState(true);
  const [userID, setuserID] = useState('');
  const minDate = '2022-12-15'; //This is as per discussion on 20Apr23, for the last date range
  const [allRange, setAllRange] = useState(false);
  const tableMsg = 'No Data Available';
  const [pageName, setPageName] = useState([]);
  const [selectedAdGrp, setSelectedAdGrp] = useState<DropDownValType[]>([]);
  const fromDate = format(addDays(new Date(), -30), dateFormat);
  const toDate = format(endOfToday(), dateFormat);
  const [dateValue, setDateValue] = useState({
    type: 'range',
    fromDate: fromDate,
    toDate: toDate,
    displayValue: 'From: ' + format(addDays(new Date(), -30), 'MM-dd-yyyy') + ' To: ' + format(endOfToday(), 'MM-dd-yyyy'),
  });
  const [state, setState] = useState({
    selection: {
      startDate: new Date(),
      endDate: addDays(new Date(), allRange ? differenceInDays(new Date(minDate), endOfToday()) : -30),
      key: 'selection',
    },
    compare: {
      startDate: new Date(),
      endDate: addDays(new Date(), 0),
      key: 'compare',
    },
  });

  const onDataInputClick = () => {
    setdateVisibility(!dateVisibility);
  };

  useEffect(() => {
    window.addEventListener('click', onWindowClick);
    return () => {
      window.removeEventListener('click', onWindowClick);
    };
  }, []);

  const onWindowClick = (e: any) => {
    if (e.target?.classList.contains('search-input') || e.target?.closest('.date-container')) {
    } else {
      setdateVisibility(false);
    }
  };

  const onDateChange = (item: any) => {
    let dateObj: any = {};
    if (item.selection === undefined) return;

    if (differenceInDays(item.selection.startDate, item.selection.endDate) === 0) {
      dateObj.displayValue = format(item.selection.startDate, 'MM-dd-yyyy');
      dateObj.fromDate = '';
      dateObj.type = 'singleDay';
      dateObj.toDate = format(item.selection.startDate, dateFormat);
    } else {
      dateObj.type = 'range';
      dateObj.displayValue = 'From: ' + format(item.selection.startDate, 'MM-dd-yyyy') + ' To: ' + format(item.selection.endDate, 'MM-dd-yyyy');
      dateObj.fromDate = format(item.selection.startDate, dateFormat);
      dateObj.toDate = format(item.selection.endDate, dateFormat);
    }
    setDateValue(dateObj);
    setState({ ...state, ...item });
  };

  const applyFilters = () => {
    setdateVisibility(false);
    getRemoveUserLogsData();
  };

  const onCheckAllRange = (e: React.ChangeEvent<HTMLInputElement>) => {
    let item = {
      selection: {
        startDate: new Date(),
        endDate: addDays(new Date(), differenceInDays(new Date(minDate), endOfToday())),
        key: 'selection',
      },
      compare: {
        startDate: new Date(),
        endDate: addDays(new Date(), 0),
        key: 'compare',
      },
    };

    setDateValue({
      type: 'range',
      fromDate: format(new Date(minDate), dateFormat),
      toDate: toDate,
      displayValue: 'From: ' + format(addDays(new Date(minDate), 0), 'MM-dd-yyyy') + ' To: ' + format(endOfToday(), 'MM-dd-yyyy'),
    });
    setAllRange(!allRange);

    setState({ ...state, ...item });
  };

  useEffect(() => {
    getAllADGrp();
  }, []);

  useEffect(() => {
    if (selectedAdGrp.length > 0) {
      getRemoveUserLogsData();
    }
  }, [pageName]);

  const getAllADGrp = async () => {
    try {
      const res = await apiResponse('post', 'get-all-adgroup', [], []);
      if (res?.data.data.allowed_ad) {
        let options: any = [];
        res?.data.data.allowed_ad.map((name: any, index: any) => {
          options.push({
            id: index,
            value: name,
            label: name,
          });
        });
        setPageName(options);
        setSelectedAdGrp(options);
      } else {
      }
    } catch (error) {
      console.log(error);
    }
  };

  const getRemoveUserLogsData = async () => {
    setLoading(true);
    let pageList = selectedAdGrp?.map((obj: any) => obj.value).join(',');

    const payload = {
      userid: userID,
      ad_group: pageList,
      dateType: dateValue.type,
      dateValue: dateValue.type === 'range' ? dateValue.fromDate + ',' + dateValue.toDate : dateValue.toDate,
    };
    try {
      const res = await apiResponse('post', 'get-remove-user-logs', [], payload);
      if (res?.data?.status === 'SUCCESS') {
        if (res.data.data.length === 0) {
          toast.error('Access Logs: Data not available!!');
          setRemoveUserLogsData([]);
        } else {
          setRemoveUserLogsData(rowSpanning(res.data.data));
        }
      } else {
        console.log(res);
        toast.error('Access Logs: Failed to load!!');
      }
    } catch (error) {
      console.log(error);
      toast.error('Access Logs: Something Went Wrong!!');
    }
    setLoading(false);
  };

  var currentUserId = '';
  let columnChecks = ['fullname', 'userid', 'function'];

  return (
    <div>
      <div className="access-logs-container adduserlogs">
        <div className="access-logs-header access-logs-filters">
          {pageName && pageName.length > 0 ? (
            <div className="filters page-user-filter">
              <MultiSelect
                options={pageName}
                className="multiselect search-input"
                value={selectedAdGrp}
                onChange={setSelectedAdGrp}
                labelledBy="Select"
                disableSearch={true}
                ClearSelectedIcon={null}
              />
            </div>
          ) : (
            <></>
          )}
          <div className="filters">
            <input type="text" onClick={onDataInputClick} value={dateValue.displayValue} className="search-input date-filter" readOnly></input>
            <span className="access-logs-checkbox-option">
              <input type="checkbox" id="allRange" checked={allRange} onChange={e => onCheckAllRange(e)} className="checkbox-field"></input>
              <label className="checkbox-label  ops-fs-5" htmlFor="allRange">
                All Range
              </label>
            </span>
            {dateVisibility && (
              <>
                <DateRangePicker
                  className="date-container"
                  maxDate={addDays(new Date(), 0)}
                  direction="vertical"
                  showMonthAndYearPickers={true}
                  showDateDisplay={false}
                  showPreview={true}
                  onChange={(item: any) => onDateChange(item)}
                  onShownDateChange={(item: any) => onDateChange(item)}
                  scroll={{ enabled: false }}
                  editableDateInputs={true}
                  ranges={[state.selection]}
                />
              </>
            )}
          </div>
          <div className="filters">
            <input
              type="text"
              className="search-input"
              value={userID}
              onChange={evt => {
                setuserID(evt.target.value);
              }}
              placeholder={'Search by User ID'}
            ></input>
            <Button text="Apply" className="apply-btn" onClick={() => applyFilters()} disabled={pageName.length === 0 ? true : false} type="secondary"></Button>
          </div>
        </div>
        {isLoading ? (
          <div className="adm-loader-container">
            <Loader />
          </div>
        ) : (
          <div>
            <div className="access-logs-table-container">
              {removeUserLogsData.length === 0 ? (
                <p className="table-msg">{tableMsg}</p>
              ) : (
                <table className="table-bordered access-logs-table add-user-logs-table">
                  <thead>
                    <tr>
                      {removeUserLogsColumns.map((data: any, indx: any) => {
                        return <th key={indx}>{data.Header}</th>;
                      })}
                    </tr>
                  </thead>
                  <tbody>
                    {removeUserLogsData.map((data: any, indx: any) => {
                      return (
                        <tr key={indx}>
                          {removeUserLogsColumns.map((colData: any, colIndx: any) => {
                            if (columnChecks.indexOf(colData.accessor) > -1) {
                              if (data.row_span_count > 1) {
                                currentUserId = data.userid;
                                return (
                                  <td key={indx + colIndx} rowSpan={data.row_span_count}>
                                    {data[colData.accessor]}
                                  </td>
                                );
                              } else if (data.row_span_count === 1 && currentUserId === data.userid) {
                                return <React.Fragment key={indx + colIndx}></React.Fragment>;
                              } else {
                                return <td key={indx + colIndx}>{data[colData.accessor]}</td>;
                              }
                            } else {
                              return <td key={indx + colIndx}>{data[colData.accessor]}</td>;
                            }
                          })}
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              )}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default RemoveUserLogs;
