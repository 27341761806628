import { useState, useMemo, useEffect } from 'react';
import { Loader, MetricCard, ExpandDownIcon, ExpandUpIcon } from '@gitlab-rtsensing/component-library';
import { brand_table } from './brandTableHeader';
import { apiResponse, pa_apiResponse } from '../../utility/commonMethods';
import { toast } from 'react-toastify';
import './index.scss';
import { Button, CloseIcon } from '@opsdti-global-component-library/amgen-design-system';
import { MultiSelect } from 'react-multi-select-component';
import RefreshBrandModal from '../../components/refresh-brand-modal';

const RefreshBrand = () => {
  const brandColumns = useMemo(() => brand_table, []);
  const [brandData, setBrandData] = useState<{ [key: string]: any }>({});
  const [brandDataOriginal, setBrandDataOrginal] = useState<any>([]);
  const [isLoading, setLoading] = useState(false);
  const [selectedSource, setSelectedSource] = useState('');
  const [selectedRefreshType, setSelectedRefreshType] = useState([]);
  const [source, setSource] = useState([]);
  const [refreshType, setRefreshType] = useState<[]>([]);
  // const [brandUpdateData, setBrandUpdateData] = useState<any>([]);
  const [submitDisabled, setSubmitDisabled] = useState<any>(true);
  const [sortCol, setSortCol] = useState<any>({});
  const [radioSelected, setRadioSelected] = useState<any>({});
  const [radioSelectedCount, setRadioSelectedCount] = useState<number>(0);
  const [selectedProduct, setSelectedProduct] = useState('');
  const [productList, setProductList] = useState([]);
  const [refreshBandModalOpen, setRefreshBandModalOpen] = useState(false);
  // const [finalBrandData, setFinalBrandData] = useState({});
  useEffect(() => {
    if (brandDataOriginal.length < 1) getBrandData();
  }, []);

  const getBrandData = async () => {
    setLoading(true);
    try {
      const res = await apiResponse('post', 'get-brand-data', [], []);
      if (res?.data?.status === 'SUCCESS') {
        if (res.data.data.length === 0) {
          toast.error('Refresh Brand: Data not available!!');
          setBrandDataOrginal([]);
        } else {
          let tableData = res?.data.data.map((row: any) => {
            return {
              ...row,
              ...{
                setClass: row.active_flag === 'Y' ? 'active' : row.submitted_to_refresh === 'Y' ? 'in-progress' : '',
              },
              tableUnique: row['table_header'] + row['table'] + row['product'],
            };
          });

          setBrandDataOrginal(tableData);
          const source_list = res.data.data.map((row: any) => row.source).filter((value: string, index: number, self: string) => self.indexOf(value) === index);
          if (source.length === 0) {
            setSource(source_list);
            setSelectedSource(source_list[0]);
          }
        }
      } else {
        console.log(res);
        toast.error('Refresh Brand: Failed to load!!');
      }
    } catch (error) {
      console.log(error);
      toast.error('Refresh Brand: Something Went Wrong!!');
    }
    setLoading(false);
  };

  const submitPARefresh = async () => {
    try {
      const res = await pa_apiResponse();
      console.log(res)
      if (res?.data) {
        toast.success("Refresh is in progress and should get completed within 3 minutes.")
      }
    } catch (error) {
      console.log(error);
      toast.error('Refresh Prioritized Agenda: Something Went Wrong!!');
    }
  };

  useEffect(() => {
    const product_list = brandDataOriginal
      .map((row: { source: string; refresh_type: never; product: string }) => {
        if (row.source === selectedSource) {
          return row.product;
        }
      })
      .filter((value: string, index: number, self: string) => self.indexOf(value) === index && value !== undefined);
    setProductList(product_list.sort());
    setSelectedProduct(product_list[0]);

    const refreshType_list = brandDataOriginal
      .map((row: any) => {
        if (row.source === selectedSource) {
          return row.refresh_type;
        }
      })
      .filter((value: string, index: number, self: string) => self.indexOf(value) === index && value !== undefined);
    setRefreshType(refreshType_list);
    setSelectedRefreshType(refreshType_list.map((option: string) => ({ label: option, value: option })));
  }, [selectedSource]);

  useEffect(() => {
    const refreshType_list = selectedRefreshType.map((row: any) => row.value);
    let brand_table_data: any = [];
    let allTableNameSet = new Set(brandDataOriginal.map((row: any) => `${row.table}: ${row.table_header}`));
    if (selectedRefreshType.length > 0 && selectedSource !== '')
      brand_table_data = brandDataOriginal.filter(
        (row: { source: string; refresh_type: never; product: string }) =>
          row.source === selectedSource && refreshType_list.includes(row.refresh_type) && row.product === selectedProduct,
      );
    const groupTableData = brand_table_data.reduce((tableData: any, row: any) => {
      const table_row: string = row.table;
      const header: string = row.table_header;
      (tableData[`${table_row}: ${header}`]  = tableData[`${table_row}: ${header}`] || []).push(row);
      return tableData;
    }, {});

    if (allTableNameSet.size > 0) {
      setSortCol(
        Object.fromEntries(
          Array.from(allTableNameSet).map((tableName: any) => {
            return [tableName, 'DESC'];
          }),
        ),
      );
    }

    for (let key in groupTableData) {
      groupTableData[key].sort((a: any, b: any) => {
        let aDate = new Date(a.table_load_dt.split(' ')[0]).getTime();
        let bDate = new Date(b.table_load_dt.split(' ')[0]).getTime();
        if (aDate < bDate) {
          return 1;
        }
        if (aDate > bDate) {
          return -1;
        }

        if (aDate === bDate) {
          let aRun = a.table_load_dt.split(' ')[1];
          let bRun = b.table_load_dt.split(' ')[1];
          if (Number(aRun.charAt(1)) < Number(bRun.charAt(1))) {
            return 1;
          }
          if (Number(aRun.charAt(1)) > Number(bRun.charAt(1))) {
            return -1;
          }
        }
        return 0;
      });
    }

    setBrandData(groupTableData);
  }, [selectedRefreshType, brandDataOriginal, selectedProduct]);

  const submitBrandRefresh = async () => {
    const idToken = JSON.parse(localStorage.getItem('sensing-okta-token') || '{}')?.idToken?.claims;

    const payload = {
      refreshList: Object.values(radioSelected)
        .filter(x => x)
        .map((row: any) => {
          return {
            source: row.source,
            refresh_type: row.refresh_type,
            table: row.table,
            process_uuid: row.process_uuid,
            table_load_dt: row.table_load_dt,
            metric_latest_refresh_dt: row.metric_latest_refresh_dt,
            product: row.product,
            submitted_by: idToken.email.split('@')[0],
            table_header: row.table_header
          };
        }),
    };

    setLoading(true);
    try {
      const res = await apiResponse('post', 'update-brand-data', [], payload);
      if (res?.data?.status === 'SUCCESS') {
        toast.success('Refresh Brand: Data successfully pushed!!');
        setSubmitDisabled(true);
        setRadioSelected({});
        setTimeout(() => {
          getBrandData();
        }, 100);
      } else {
        console.log(res);
        toast.error('Refresh Brand: Failed to load!!');
      }
    } catch (error) {
      console.log(error);
      toast.error('Refresh Brand: Something Went Wrong!!');
    }
    setLoading(false);
  };

  const brandRefreshHandler = (row: any) => {
    const filtered = [...Object.values(radioSelected)].filter((x : any) => x && x['tableUnique'] !== row['tableUnique']);
    filtered.push(row);
    const newData = Object();
    filtered.forEach((x: any) => {
      const uniqId = x['table_header'] + x['process_uuid'] + x['table'] + x['product']; 
      newData[uniqId] = x;
    });
    setRadioSelected(newData);
  };

  const handleCancel = (row: any) => {
    const uniqId = row['table_header'] + row['process_uuid'] + row['table'] + row['product'];
    setRadioSelected({ ...radioSelected, [uniqId]: undefined });
  };

  const sortColumn = (tableN: string) => {
    const sortDirection = sortCol[tableN] === 'ASC' ? -1 : 1;
    let tableData = [];
    for (let key in brandData) {
      if (key === tableN) {
        tableData = brandData[key];
        break;
      }
    }
    const sortedData = tableData.sort((a: any, b: any) => {
      const aDate = new Date(a.table_load_dt.split(' ')[0]).getTime();
      const bDate = new Date(b.table_load_dt.split(' ')[0]).getTime();
      if (aDate < bDate) {
        return -1 * sortDirection;
      }
      if (aDate > bDate) {
        return 1 * sortDirection;
      }

      if (aDate === bDate) {
        let aRun = a.table_load_dt.split(' ')[1];
        let bRun = b.table_load_dt.split(' ')[1];

        if (Number(aRun.charAt(1)) < Number(bRun.charAt(1))) {
          return -1 * sortDirection;
        }
        if (Number(aRun.charAt(1)) > Number(bRun.charAt(1))) {
          return 1 * sortDirection;
        }
      }
      return 0;
    });
    brandData[tableN] = sortedData;
    setBrandData(brandData);
    setSortCol(Object.assign({}, sortCol, { [tableN]: sortCol[tableN] === 'ASC' ? 'DESC' : 'ASC' }));
  };

  useEffect(() => {
    const count = Object.values(radioSelected).filter(x => x).length;
    setSubmitDisabled(count ? false : true);
    setRadioSelectedCount(count);
    if (count === 0) {
      //Glitch with page Y scroll When modal is opened
      document.getElementsByTagName('html')[0].style.overflowY = 'auto';
      setRefreshBandModalOpen(false);
    }
  }, [radioSelected]);

  return (
    <div className="refresh-brand-container">
      <div className="d-flex refresh-filters">
        <div className="d-flex filter-container" >
        <div className="refresh-filter">
          <label className="form-label">
            Source <sup className="sup-star">*</sup>
          </label>
          <select
            className="brand-select"
            value={selectedSource}
            onChange={e => {
              // setSubmitDisabled(true);
              setSelectedSource(e.target.value);
              // setRadioSelected({});
            }}
          >
            {source.map((option: string) => (
              <option key={option} value={option}>
                {option}
              </option>
            ))}
          </select>
        </div>
        <div className="refresh-filter">
          <label className="form-label">
            Product <sup className="sup-star">*</sup>
          </label>
          <select
            className="brand-select"
            value={selectedProduct}
            onChange={e => {
              // setSubmitDisabled(true);
              setSelectedProduct(e.target.value);
              // setRadioSelected({});
            }}
          >
            {productList.map((option: string) => (
              <option key={option} value={option}>
                {option}
              </option>
            ))}
          </select>
        </div>
        <div className="refresh-filter">
          <label className="form-label">
            Refresh Type <sup className="sup-star">*</sup>
          </label>
          <MultiSelect
            className="multiselect"
            value={selectedRefreshType}
            onChange={(e: any) => {
              // setRadioSelected({});
              // setSubmitDisabled(true);
              setSelectedRefreshType(e);
            }}
            options={refreshType.map((option: string) => ({ label: option, value: option }))}
            labelledBy="Refresh Type"
          />
        </div>
        <div className="Preview-submit-button">
          <Button
            type="primary"
            text={`Preview & Submit${radioSelectedCount ? ` (${radioSelectedCount})` : ''}`}
            className="Preview-submit-btn"
            onClick={() => setRefreshBandModalOpen(true)}
            disabled={submitDisabled}
          ></Button>
        </div>
        <div className="Preview-submit-button">
          <Button
            type="primary"
            text="PA Cache Refresh"
            className="Preview-submit-btn"
            onClick={submitPARefresh}
          ></Button>
        </div>
        </div>
        <div className="notes">
        <strong>* Refresh stages:</strong>
          <div className="brand-table-msg">
            <div><div className="active"></div>  : Snapshot/Refresh date is already ACTIVE in Brand UI</div>
            <div><div className="in-progress"></div> : Refresh request is submitted and should be COMPLETED in 10-15 minutes</div>
            <div><strong>Note:</strong> Multiple refreshes can't be submitted for the same table until it becomes ACTIVE.</div>
          </div>
        </div>
      </div>
      <div className="brand-table-container">
        {isLoading ? (
          <div className="adm-loader-container">
            <Loader />
          </div>
        ) : (
          <>
            {Object.values(brandData).map((tableData: any, indx: any) => {
              return (
                <div className="refresh-containers">
                  <MetricCard className="" key={`${tableData[0].table}: ${tableData[0].table_header}`}>
                    <>
                      <MetricCard.Header>
                        <MetricCard.Title title={ tableData[0].table_header.toUpperCase() } />
                      </MetricCard.Header>
                      <MetricCard.Content className="nav adm-section-name">
                        <div className="">
                          <table className="usage-metric-table brand-refresh">
                            <thead>
                              <tr>
                                {brandColumns.map((data: any, indx: any) => {
                                  if (data.accessor === 'submitted_to_refresh') {
                                    return (
                                      <th className="refresh-checkbox" key={indx}>
                                        {data.Header}
                                      </th>
                                    );
                                  } else if (data.accessor === 'table_load_dt') {
                                    return (
                                      <th key={indx} className="snapshot-date" onClick={() => sortColumn(`${tableData[0].table}: ${tableData[0].table_header}`)}>
                                        <span>{data.Header}</span>
                                        <span>
                                          {sortCol[`${tableData[0].table}: ${tableData[0].table_header}`] === 'ASC' ? (
                                            <ExpandDownIcon fill={'var(--ops-primary-blue)'} width={18} height={18} />
                                          ) : (
                                            <ExpandUpIcon fill={'var(--ops-primary-blue)'} width={18} height={18} />
                                          )}
                                        </span>
                                      </th>
                                    );
                                  } else {
                                    return <th key={indx}>{data.Header}</th>;
                                  }
                                })}
                              </tr>
                            </thead>
                            <tbody>
                              {tableData.map((data: any, indx: any) => {
                                let isBrandDisabled =
                                  data['active_flag'] === 'Y' || tableData.filter((row: any) => row.submitted_to_refresh === 'Y').length > 0 ? true : false;

                                return (
                                  <tr key={indx} className={data.setClass}>
                                    {brandColumns.map((colData: any, colIndx: any) => {
                                      if (colData.accessor === 'submitted_to_refresh') {
                                        const uniqId = data['table_header'] + data['process_uuid'] + data['table'] + data['product'];
                                        const isChecked = Boolean(radioSelected[uniqId]);
                                        return (
                                          <td key={uniqId}>
                                            <span className="refresh-checkbox-option">
                                              <input
                                                type={'radio'}
                                                name={`brand-radio-${uniqId}`}
                                                key={`${uniqId}`}
                                                checked={isChecked}
                                                onChange={() => brandRefreshHandler(data)}
                                                className={isBrandDisabled ? 'refresh-checkbox-field checkbox-disabled' : 'refresh-checkbox-field'}
                                                disabled={isBrandDisabled}
                                              ></input>
                                              {isChecked ? (
                                                <div className="icon-container">
                                                  <CloseIcon color="inherit" height={22} width={22} onClick={() => handleCancel(data)} />{' '}
                                                </div>
                                              ) : (
                                                <></>
                                              )}
                                            </span>
                                          </td>
                                        );
                                      } else {
                                        return <td key={indx + colIndx}>{data[colData.accessor]}</td>;
                                      }
                                    })}
                                  </tr>
                                );
                              })}
                            </tbody>
                          </table>
                        </div>
                      </MetricCard.Content>
                    </>
                  </MetricCard>
                </div>
              );
            })}
          </>
        )}
      </div>
      {/* {Object.keys(brandData).length !== 0 && (
        <Button type="primary" text="Submit" className="submit-refresh-btn" onClick={() => submitBrandRefresh()} disabled={submitDisabled}></Button>
      )} */}
      <RefreshBrandModal
        opened={refreshBandModalOpen}
        onRefreshBandClosed={() => setRefreshBandModalOpen(false)}
        radioSelected={radioSelected}
        handleCancel={handleCancel}
        handleSubmit={submitBrandRefresh}
      />
    </div>
  );
};

export default RefreshBrand;
