const MATOMO = () => {
  return (
    <svg width="25px" height="25px" viewBox="0 0 1024 1024" xmlns="http://www.w3.org/2000/svg">
      <circle cx="512" cy="512" r="512" fill="#3152a0" />
      <path
        d="m622.1 596.5 71.9-112-.3-.2.3.2c10.3-15.3 16.2-33.7 16.2-53.4 0-2.7-.1-5.3-.3-7.9l80.2 122.2c.3.4.4.7.7 1.1l1.5 2.3-.1.1c7.9 12.9 12.5 28 12.6 44.1 0 46.6-37.8 84.4-84.4 84.4-28.6 0-53.7-14.2-69-35.9l-.1.1-.5-.8c-.4-.6-.8-1.3-1.3-1.9m-34.1-52.8 46-71.7c-2.5 1.4-5.1 2.8-7.8 4-.2.1-.4.2-.5.2-2.5 1.1-5 2.1-7.6 3-.5.2-.9.3-1.4.5-.7.2-1.5.5-2.2.7-1 .3-2.1.6-3.2.9-.6.2-1.2.3-1.8.5l-2.4.6-1.5.3c-1.2.3-2.5.5-3.8.7-.4.1-.9.1-1.3.2-1.2.2-2.3.3-3.5.4-.2 0-.5.1-.7.1l-4.2.3c-.4 0-.7 0-1.1.1-1.5.1-2.9.1-4.4.1-1.6 0-3.2 0-4.8-.1-.5 0-1.1-.1-1.6-.1-1-.1-2.1-.1-3.1-.2-.6-.1-1.3-.1-1.9-.2-.9-.1-1.9-.2-2.8-.4-.7-.1-1.3-.2-2-.3-.9-.1-1.7-.3-2.6-.5-.7-.1-1.4-.3-2-.4-.8-.2-1.6-.4-2.5-.6-.7-.2-1.4-.4-2.1-.5-.8-.2-1.6-.4-2.4-.7l-2.1-.6c-.8-.2-1.5-.5-2.2-.8-.7-.2-1.4-.5-2.1-.8-.7-.3-1.4-.6-2.1-.8l-2.1-.9c-.7-.3-1.3-.6-2-.9-.7-.3-1.4-.7-2.1-1-.5-.3-1.1-.6-1.6-.8l-.1-.2c-.7-.4-1.4-.7-2.1-1.1-.5-.3-1.1-.6-1.6-.9-.8-.4-1.5-.9-2.3-1.3-.4-.2-.7-.5-1.1-.7-2.1-1.3-4.1-2.7-6-4.1-2.1-1.6-4.2-3.3-6.2-5-.2-.2-.4-.3-.6-.5-.9-.8-1.7-1.6-2.5-2.4-.2-.2-.4-.4-.7-.6l-2.4-2.4c-.2-.2-.4-.5-.7-.7-.8-.8-1.5-1.7-2.3-2.5-.2-.2-.4-.5-.6-.7-.7-.9-1.5-1.8-2.2-2.7-.2-.2-.4-.4-.5-.7-.7-.9-1.4-1.9-2.1-2.8-.1-.2-.3-.4-.4-.6-.7-1-1.4-2-2.1-3.1-.1-.1-.2-.3-.3-.4l-2.1-3.3-.1-.1c-6.5-11.1-10.8-23.6-12.4-36.9 0-.2 0-.4-.1-.6l-38.9-55h-.1c-14.9-24.1-41.4-40.2-71.9-40.2h-.4c-30.4 0-57 16.1-71.8 40.2h-.1L257 509.2c13.8-7.7 29.6-12 46.4-12 49 0 89.5 36.9 95.2 84.3l43.9 61.6h.1c15.3 20.8 40 34.4 67.9 34.4h.4c27.9 0 52.5-13.5 67.9-34.4h.1l.7-1.1c1.7-2.4 3.3-4.9 4.9-7.6l30.9-48.2.1.2-.1-.2v-.1zm-85.9-155.2c0 46.7 37.8 84.5 84.5 84.5s84.5-37.8 84.5-84.5-37.8-84.5-84.5-84.5-84.5 37.8-84.5 84.5zM219 593.1c0 46.6 37.8 84.4 84.4 84.4s84.4-37.8 84.4-84.4-37.8-84.4-84.4-84.4-84.4 37.8-84.4 84.4z"
        fill="#fff"
      />
    </svg>
  );
};
export default MATOMO;
