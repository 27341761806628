export const Config_Update_Table_Header = [
  {
    Header: 'Workstream ID',
    accessor: 'work_stream_id',
  },
  {
    Header: 'Workstream',
    accessor: 'work_stream',
  },
  {
    Header: 'Category ID',
    accessor: 'category_id',
  },
  {
    Header: 'Category',
    accessor: 'category',
  },
  {
    Header: 'System Congif ID',
    accessor: 'sys_config_id',
  },
  {
    Header: 'Config Key',
    accessor: 'config_key',
  },
  {
    Header: 'Config Value',
    accessor: 'config_value',
  },
];
