import { Loader, ExpandDownIcon, ExpandUpIcon } from '@gitlab-rtsensing/component-library';
import { Button } from '@opsdti-global-component-library/amgen-design-system';
import { Config_Update_Table_Header } from './configUpdateTable';
import { useMemo, useState, useContext } from 'react';
import '../index.scss';
import React from 'react';

interface TableProps {
  tableData: any;
  saveUpdateConfigApi: () => void;
  handleCancel: () => void;
  loader: boolean;
  enable: boolean;
  handleEdit: () => void;
  handleChangeConfigValue: (val1: any, val2: any) => void;
  setSortDirection: (val: string) => void;
  sortDirection: string;
}

const ConfigUpdateTable = (props: TableProps) => {
  const columns = useMemo(() => Config_Update_Table_Header, []);

  const handleSave = () => {
    props.saveUpdateConfigApi();
  };

  const handleCancel = () => {
    props.handleCancel();
  };

  return (
    <div>
      {props.loader ? (
        <div className="adm-loader-container">
          <Loader />
        </div>
      ) : (
        <div className="table-container config-api-container">
          <table className="table-bordered config-api-table">
            <thead>
              <tr>
                {columns.map((data: any, indx: any) => {
                  if (data.accessor === 'work_stream_id' || data.accessor === 'category_id' || data.accessor === 'sys_config_id') {
                    return <React.Fragment key={indx}></React.Fragment>;
                  } else if (data.accessor === 'config_key') {
                    return (
                      <React.Fragment key={indx}>
                        {props.sortDirection === 'ASC' ? (
                          <th key={indx} onClick={() => props.setSortDirection(props.sortDirection === 'ASC' ? 'DESC' : 'ASC')}>
                            {data.Header}
                            <ExpandDownIcon fill={'var(--ops-primary-blue)'} width={18} height={18} />
                          </th>
                        ) : (
                          <th key={indx} onClick={() => props.setSortDirection(props.sortDirection === 'ASC' ? 'DESC' : 'ASC')}>
                            {data.Header}
                            <ExpandUpIcon fill={'var(--ops-primary-blue)'} width={18} height={18} />
                          </th>
                        )}
                      </React.Fragment>
                    );
                  } else {
                    return <th key={indx}>{data.Header}</th>;
                  }
                })}
              </tr>
            </thead>
            <tbody>
              {props.tableData.map((data: any, indx: any) => {
                return (
                  <tr key={indx}>
                    {columns.map((colData: any, colIndx: any) => {
                      if (colData.accessor === 'work_stream_id' || colData.accessor === 'category_id' || colData.accessor === 'sys_config_id') {
                        return <React.Fragment key={indx + colIndx}></React.Fragment>;
                      } else if (colData.accessor === 'config_value') {
                        return (
                          <td key={indx + colIndx}>
                            <textarea
                              className="edit-textarea"
                              autoFocus={props.enable ? true : false}
                              data-input-id={data.config_value}
                              name="config_value"
                              disabled={props.enable ? true : false}
                              value={data.config_value}
                              onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) => props.handleChangeConfigValue(e, data)}
                            />
                          </td>
                        );
                      } else {
                        return <td key={indx + colIndx}>{data[colData.accessor].toLowerCase()}</td>;
                      }
                    })}
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      )}
      <div className="d-flex justify-content-end config-update-btn-block">
        {props.tableData.length > 0 ? (
          props.enable ? (
            <>
              <Button text="Edit" type="primary" className="config-btn" onClick={props.handleEdit} disabled={props.loader} />
            </>
          ) : (
            <>
              <Button text="Save" type="primary" className="config-btn" onClick={handleSave} disabled={props.loader} />
              <Button text="Cancel" type="primary" className="config-btn" onClick={handleCancel} disabled={props.loader} />
            </>
          )
        ) : null}
      </div>
    </div>
  );
};

export default ConfigUpdateTable;
