export const usageTrackingHeader = [
  {
    headerName: 'User Name',
    field: 'fullname',
  },
  {
    headerName: 'User ID',
    field: 'userid',
  },
  {
    headerName: 'Date',
    field: 'insert_date',
  },
  {
    headerName: 'Notification Name',
    field: 'notification_name',
  },
  {
    headerName: 'Visits',
    field: 'nb_visits',
  },
  {
    headerName: 'Description',
    field: 'notification_description',
  },
];
export const unique_notification = [
  {
    headerName: 'Notification Name',
    field: 'notification_name',
  },
  {
    headerName: 'Notification Sent Date',
    field: 'date_value',
  },
  {
    headerName: 'Total Notifications Sent',
    field: 'notification_count',
  },
  {
    headerName: 'Total Notifications Clicked',
    field: 'number_of_unique_visitors',
  },
];
