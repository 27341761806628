import React, { ReactNode } from 'react';
import { useState, useEffect, useMemo } from 'react';
import 'react-date-range/dist/styles.css'; // main css file
import 'react-date-range/dist/theme/default.css'; // theme css file
import '../index.scss';
import { unique_outlinks } from './outlinks';
import { apiResponse, camelToHyphen, filterMembers } from '../../../utility/commonMethods';
import { toast } from 'react-toastify';
import { Loader } from '@gitlab-rtsensing/component-library';
import { MetricProps } from '../usage-metric-interface';
import AgGridTable from '../../../utility/agGridTable';
import { FileDownloadIcon } from '@opsdti-global-component-library/amgen-design-system';
import { ExpandIcon, CloseIcon } from '@opsdti-global-component-library/amgen-design-system';

const OutlinksChart = (props: MetricProps) => {
  const count_columns = useMemo(() => unique_outlinks, []);
  const [isLoading, setLoading] = useState(true);
  const [tableMsg, setTableMsg] = useState('No Data Available');
  const [outlinkCountData, setOutlinkCountData] = useState([]);
  const [outlinkCountOriginalData, setOutlinkCountOriginalData] = useState([]);
  const [showExpandOrClose, setShowExpandOrClose] = useState(false);
  const [triggerDownload, setTriggerDownload] = useState(false);

  useEffect(() => {
    if (props.clearData) {
      setOutlinkCountData([]);
      setOutlinkCountOriginalData([]);
      getOutlinkCountData();
    }
  }, [props.clearData]);

  useEffect(() => {
    if (outlinkCountOriginalData.length === 0) return;
    filterGroupValue([]);
  }, [props.userGroupValue, props.includeCoreMember, outlinkCountOriginalData]);

  const filterGroupValue = (dataArray: []) => {
    const countData = filterMembers(outlinkCountOriginalData, props.userGroupValue, props.includeCoreMember);
    if (countData.length === 0) {
      setTableMsg(`No Data Available for  ${props.userGroupValue}`);
    }
    setOutlinkCountData(countData);
  };

  const getOutlinkCountData = async () => {
    setLoading(true);
    const payload = {
      metric_id: '15',
      date_type: props.date.type,
      to_date: props.date.toDate,
      fr_date: props.date.fromDate,
    };
    try {
      const res = await apiResponse('post', 'get-usage-metric-data', [], payload);
      if (res?.data?.status === 'SUCCESS') {
        if (res?.data?.data?.user_metric) {
          if (res.data.data.user_metric.length === 0) {
            toast.error('OutlinksChart: Data not available!!');
          } else {
            setOutlinkCountOriginalData(res.data.data.user_metric);
            filterGroupValue(res.data.data.user_metric);
          }
        }
      } else {
        console.log(res);
        toast.error('OutlinksChart Count: Failed to load!!');
      }
    } catch (error) {
      console.log(error);
      toast.error('OutlinksChart: Something Went Wrong!!');
    }
    setLoading(false);
  };

  // useEffect(() => {
  //   if (!showExpandOrClose) {
  //     document.getElementById(camelToHyphen('outgoingLinksFromSensing'))?.classList.add('container-width-100');
  //   } else {
  //     document.getElementById(camelToHyphen('outgoingLinksFromSensing'))?.classList.remove('container-width-100');
  //   }
  // }, [showExpandOrClose]);

  return (
    <>
      <div className="metric-header">
        <div>
          <label className="form-label">{props.date.displayValue}</label>
        </div>
        <div className="filters ">
        <FileDownloadIcon height={28} width={28} onClick={() => {
          setTriggerDownload(true);
        }} />
          {!showExpandOrClose ? (
            <span
              onClick={() => {
                setShowExpandOrClose(true);
              }}
              className="expand-collapse-icon"
            >
              <ExpandIcon className="expand-collapse-icon" /> {/*This classname somehow not rendering*/}
            </span>
          ) : (
            <span
              onClick={() => {
                setShowExpandOrClose(false);
              }}
            >
              <CloseIcon className="expand-collapse-icon" />
            </span>
          )}
        </div>
      </div>
      {isLoading ? (
        <div className="adm-loader-container">
          <Loader />
        </div>
      ) : (
        <div className="table-container usage-metric-container">
          {outlinkCountData.length === 0 ? (
            <p className="table-msg">{tableMsg}</p>
          ) : (
            <AgGridTable 
            rowData={outlinkCountData} 
            columnDefs={count_columns} 
            matamoDate={props.date.matamoDate} 
            matamoType={props.date.matamoType}
            tableName='Outlinks Count'
            triggerdownload={triggerDownload}
            setTriggerDownload={setTriggerDownload}
            onExpandableClosed={() => setShowExpandOrClose(false)}
            expandable={showExpandOrClose}
             />
          )}
        </div>
      )}
    </>
  );
};

export default OutlinksChart;
