import React, { useState } from 'react';
import { MultiSelect, Option } from 'react-multi-select-component';
import { useReleaseNotesContext } from '../../../contexts/release-note-context';

const WorkstreamDropdown: React.FC = () => {
  const { releaseWorkstreams, filters, setFilters } = useReleaseNotesContext();
  const [selectedItems, setSelectedItems] = useState<Option[]>([]);

  const options = releaseWorkstreams.map(workstream => ({
    label: workstream.workstream
      .split(' ')
      .map((word: string) => word.charAt(0).toUpperCase() + word.slice(1))
      .join(' '),
    value: workstream.releaseNoteWorkstreamId,
  }));

  const handleSelectedItemsChange = (newSelectedItems: { label: string; value: number }[]) => {
    setSelectedItems(newSelectedItems);
    setFilters({
      ...filters,
      workstreamFilters: newSelectedItems.map(item => item.label.toLowerCase()),
    });
  };

  const valueRenderer = (selected: typeof options) => {
    if (!selected.length) {
      return 'Filter by Workstream';
    }
  };

  return (
    <div className="dropdown-container">
      <MultiSelect
        className="ops-fs-5 workstream-dropdown"
        options={options}
        value={selectedItems}
        valueRenderer={valueRenderer}
        onChange={handleSelectedItemsChange}
        labelledBy="Filter by Workstream"
      />
    </div>
  );
};

export default WorkstreamDropdown;
