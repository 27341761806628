import axios from 'axios';
import { createContext, useCallback, useContext, useEffect, useState } from 'react';

import {
  IReleaseVersion,
  IReleaseWorkstream,
  IReleaseNoteItem,
  IReleaseNoteStatus,
  IReleaseNotesInitialFormData,
} from '../components/release-notes/release-note-interfaces';

import { useUsersNames } from '../utility/auth-config';

import { apiResponse, convertObjectKeysToCamelCase, sortReleaseNotes } from '../utility/commonMethods';
import { DateRange } from '../components/release-notes/utils';

type fetchIdentifier = 'get-release-versions' | 'get-release-notes' | 'get-release-workstreams' | 'get-release-notes-statuses';

interface Filters {
  dateRanges: DateRange[];
  workstreamFilters: string[];
  statusFilters: string[];
}

interface ReleaseNotesContextState {
  username: string;
  releaseVersions: IReleaseVersion[];
  releaseWorkstreams: IReleaseWorkstream[];
  releaseNotes: IReleaseNoteItem[];
  approvedReleaseNotes: IReleaseNoteItem[];
  pendingReleaseNotes: IReleaseNoteItem[];
  publishedReleaseNotes: IReleaseNoteItem[];
  deletedReleaseNotes: IReleaseNoteItem[];
  releaseStatuses: IReleaseNoteStatus[];
  isRefetching: boolean;
  isLoading: boolean;
  isError: boolean;
  setFilters: (filters: Filters) => void;
  filters: Filters;
  refetch: (apiIdentifier?: fetchIdentifier) => Promise<void>;
  releaseNoteInitialFormData: IReleaseNotesInitialFormData;
  setInitialForm: (releaseNoteId: number) => void;
  setIsPublicView: (arg0: boolean) => void;
  isPublicView: boolean;
}

const ReleaseNotesContext = createContext<ReleaseNotesContextState | undefined>(undefined);

export const useReleaseNotesContext = (): ReleaseNotesContextState => {
  const context = useContext(ReleaseNotesContext);
  if (!context) {
    throw new Error('useReleaseNotesContext must be used within ReleaseNotesContextProvider');
  }
  return context;
};

interface ReleaseNotesContextProviderProps {
  children: React.ReactNode;
}

interface State {
  releaseVersions: IReleaseVersion[];
  releaseWorkstreams: IReleaseWorkstream[];
  releaseNotes: IReleaseNoteItem[];
  approvedReleaseNotes: IReleaseNoteItem[];
  pendingReleaseNotes: IReleaseNoteItem[];
  publishedReleaseNotes: IReleaseNoteItem[];
  deletedReleaseNotes: IReleaseNoteItem[];
  releaseStatuses: IReleaseNoteStatus[];
}

export const ReleaseNotesContextProvider: React.FC<ReleaseNotesContextProviderProps> = ({ children }) => {
  const { emailId } = useUsersNames();
  const username = emailId.split('@')[0];
  const [state, setState] = useState<State>({
    releaseVersions: [],
    releaseWorkstreams: [],
    releaseNotes: [],
    approvedReleaseNotes: [],
    pendingReleaseNotes: [],
    publishedReleaseNotes: [],
    deletedReleaseNotes: [],
    releaseStatuses: [],
  });
  const [releaseNoteInitialFormData, setReleaseNoteInitialFormData] = useState<IReleaseNotesInitialFormData>(null);

  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [isRefetching, setIsRefetching] = useState<boolean>(false);
  const [isError, setIsError] = useState<boolean>(false);
  const [filters, setFilters] = useState<Filters>({
    dateRanges: [],
    workstreamFilters: [],
    statusFilters: [],
  });
  const [isPublicView, setIsPublicView] = useState(false);

  const setInitialForm = (releaseNoteId: number) => {
    if (releaseNoteId === 0) {
      setReleaseNoteInitialFormData(null);
      return;
    }
    const releaseNote = state.releaseNotes.find(rn => rn.releaseNoteId === releaseNoteId);
    if (!releaseNote) return;
    const release = state.releaseVersions.find(
      rv => rv.releaseVersionDate === releaseNote.releaseVersionDate && rv.releaseVersionNumber === releaseNote.releaseVersionNumber,
    );
    const workstream = state.releaseWorkstreams.find(ws => ws.workstream === releaseNote.workstream);
    const status = state.releaseStatuses.find(rs => rs.status === releaseNote.status);

    if (!release || !workstream || !status) return;

    setReleaseNoteInitialFormData({
      ...releaseNote,
      releaseVersionData: release,
      releaseWorkstreamData: workstream,
      releaseStatusData: status,
    });
  };

  const hasAccess = (): boolean => {
    return true;
  };

  const sortReleaseNotesByStatus = (releaseNotes: IReleaseNoteItem[]) => {
    const approvedReleaseNotes: IReleaseNoteItem[] = [];
    const pendingReleaseNotes: IReleaseNoteItem[] = [];
    const publishedReleaseNotes: IReleaseNoteItem[] = [];
    const deletedReleaseNotes: IReleaseNoteItem[] = [];

    releaseNotes.forEach(rn => {
      if (['pending', 'draft', 'deferred'].includes(rn.status)) {
        pendingReleaseNotes.push(rn);
      } else if (['approved', 'reverted'].includes(rn.status)) {
        approvedReleaseNotes.push(rn);
      } else if (rn.status === 'published') {
        publishedReleaseNotes.push(rn);
      } else if (rn.status === 'deleted') {
        deletedReleaseNotes.push(rn);
      }
    });

    return {
      approvedReleaseNotes,
      pendingReleaseNotes,
      publishedReleaseNotes,
      deletedReleaseNotes,
    };
  };

  const fetchData = async (apiIdentifier: string) => {
    if (!hasAccess()) {
      console.error('No access to this api: ', apiIdentifier);
      return [];
    }

    try {
      let { data } = await apiResponse('get', apiIdentifier, [], {});
      return data.map((rv: { [key: string]: any }) => convertObjectKeysToCamelCase(rv));
    } catch (error) {
      setIsError(true);
      if (axios.isAxiosError(error)) {
        if (error.response) {
          console.error('Server error:', error.response.status, error.response.data);
        } else if (error.request) {
          console.error('Network error:', error.request);
        } else {
          console.error('Error', error.message);
        }
      } else {
        console.error('Unknown error:', error);
      }
    }
  };

  const fetchAllData = useCallback(
    async (apiIdentifier?: string) => {
      const setLoadingState = apiIdentifier ? setIsRefetching : setIsLoading;
      setLoadingState(true);

      try {
        const dataToFetch = apiIdentifier
          ? [apiIdentifier]
          : ['get-release-versions', 'get-release-notes', 'get-release-workstreams', 'get-release-notes-statuses'];

        const [releaseVersionData, releaseNotesData, releaseWorkstreamsData, releaseStatusesData] = await Promise.all(
          dataToFetch.map(identifier => fetchData(identifier)),
        );

        const newState = { ...state };

        if (!apiIdentifier || apiIdentifier === 'get-release-versions') {
          newState.releaseVersions = releaseVersionData;
        }

        if (!apiIdentifier || apiIdentifier === 'get-release-notes') {
          let releaseNotesWithoutDeleted = releaseNotesData.filter((rn: IReleaseNoteItem) => rn.status !== 'deleted');
          newState.releaseNotes = sortReleaseNotes(releaseNotesWithoutDeleted);
          const { approvedReleaseNotes, pendingReleaseNotes, publishedReleaseNotes, deletedReleaseNotes } = sortReleaseNotesByStatus(releaseNotesData);
          newState.approvedReleaseNotes = approvedReleaseNotes;
          newState.pendingReleaseNotes = pendingReleaseNotes;
          newState.publishedReleaseNotes = publishedReleaseNotes;
          newState.deletedReleaseNotes = deletedReleaseNotes;
        }

        if (!apiIdentifier || apiIdentifier === 'get-release-workstreams') {
          newState.releaseWorkstreams = releaseWorkstreamsData;
        }

        if (!apiIdentifier || apiIdentifier === 'get-release-notes-statuses') {
          newState.releaseStatuses = releaseStatusesData;
        }

        setState(newState);
        setIsError(false);
      } catch (error) {
        setIsError(true);
        if (axios.isAxiosError(error)) {
          if (error.response) {
            console.error('Server error:', error.response.status, error.response.data);
          } else if (error.request) {
            console.error('Network error:', error.request);
          } else {
            console.error('Error', error.message);
          }
        } else {
          console.error('Unknown error:', error);
        }
      } finally {
        setLoadingState(false);
      }
    },
    [state],
  );

  useEffect(() => {
    fetchAllData();
  }, []);

  return (
    <ReleaseNotesContext.Provider
      value={{
        releaseNoteInitialFormData,
        setInitialForm,
        filters,
        setFilters,
        username,
        ...state,
        isRefetching,
        isLoading,
        isError,
        isPublicView,
        setIsPublicView,
        refetch: (apiIdentifier?: fetchIdentifier) => fetchAllData(apiIdentifier),
      }}
    >
      {children}
    </ReleaseNotesContext.Provider>
  );
};
