export const add_user_logs_table = [
  {
    Header: 'User Name',
    accessor: 'fullname',
  },
  {
    Header: 'User ID',
    accessor: 'userid',
  },
  {
    Header: 'Page',
    accessor: 'workstream',
  },
  {
    Header: 'Tile Name',
    accessor: 'tile_name',
  },
  {
    Header: 'Level One Approval Status',
    accessor: 'level1_approval_flag',
  },
  {
    Header: 'Level One Approval Date',
    accessor: 'level1_approval_date',
  },
  {
    Header: 'Level Two Approval Status',
    accessor: 'level2_approval_flag',
  },
  {
    Header: 'Level Two Approval Date',
    accessor: 'level2_approval_date',
  },
  {
    Header: 'Feature Owner Approval Status',
    accessor: 'feature_owner_approval_flag',
  },
  {
    Header: 'Feature Owner Approval Date',
    accessor: 'feature_owner_approval_date',
  },
  {
    Header: 'Allocated AD Groups',
    accessor: 'allocated_ad',
  },
  {
    Header: 'Allocated Date',
    accessor: 'allocated_date',
  },
];
export const remove_user_logs_table = [
  {
    Header: 'User Name',
    accessor: 'fullname',
  },
  {
    Header: 'User ID',
    accessor: 'userid',
  },
  {
    Header: 'Function',
    accessor: 'function',
  },
  {
    Header: 'Removed AD-Group',
    accessor: 'removed_ad',
  },
  {
    Header: 'Removed By',
    accessor: 'removed_by',
  },
  {
    Header: 'Date',
    accessor: 'removed_on',
  },
];
export const external_logs_table = [
  {
    Header: 'User ID',
    accessor: 'user_id',
  },
  {
    Header: 'Workstream',
    accessor: 'work_stream',
  },
  {
    Header: 'Mail Status',
    accessor: 'mail_sent_flag',
  },
  {
    Header: 'Service Request',
    accessor: 'sr_create_flag',
  },
  {
    Header: 'Comments',
    accessor: 'comments',
  },
  {
    Header: 'Created On',
    accessor: 'created_on',
  },
  {
    Header: 'Updated On',
    accessor: 'updated_on',
  },
];
